import {
  ACTION_SHOW,
  ACTION_HIDE,
  ACTION_SELECT,
  ACTION_DELETE,
  ACTION_MOVE_LEFT,
  ACTION_MOVE_RIGHT,
  ACTION_TOGGLE_CASE,
} from './constants'

const reducer = (state, { type }) => {
  switch (type) {
    case ACTION_SHOW:
      return {
        ...state,
        visible: true,
        charPosition: 0,
      }
    case ACTION_DELETE:
    case ACTION_SELECT:
    case ACTION_HIDE:
      return {
        ...state,
        visible: false,
        baseChar: undefined,
        charPosition: 0,
        upperCase: false,
      }
    case ACTION_MOVE_LEFT:
      return {
        ...state,
        charPosition: state.charPosition - 1,
      }
    case ACTION_MOVE_RIGHT:
      return {
        ...state,
        charPosition: state.charPosition + 1,
      }
    case ACTION_TOGGLE_CASE:
      return {
        ...state,
        upperCase: !state.upperCase,
      }
    default:
      return state
  }
}

export default reducer
