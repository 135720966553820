import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chatterbug/aaron'

import LanguageAvatar from 'src/ui/LanguageAvatar/LanguageAvatar'

import LanguageCardWithAvatarSkeleton from './LanguageCardWithAvatarSkeleton'

const LanguageCardWithAvatar = ({
  open = true,
  languageCode,
  title,
  description,
  onClick,
  children,
}) => {
  return (
    <LanguageCardWithAvatarSkeleton
      open={open}
      headerSection={
        <Box>
          <Box variant="text.h3">{title}</Box>
          {description && (
            <Box
              variant="text.paragraph"
              mt="1x"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}
        </Box>
      }
      avatarSection={
        <LanguageAvatar languageCode={languageCode} variant="regular" />
      }
      onClick={onClick}
    >
      {children}
    </LanguageCardWithAvatarSkeleton>
  )
}

LanguageCardWithAvatar.propTypes = {
  open: PropTypes.bool,
  languageCode: PropTypes.oneOf(['de', 'fr', 'es', 'en']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func,
}
export default LanguageCardWithAvatar
