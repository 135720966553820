import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'

export const splitCamelCase = (metric) =>
  metric.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()

// L1 / L2 / L3 / L4
// -------------------
// 20 / 80 / 140 / 200
//
// The space between each level graph point is linear. For example, in the case of
// Sessions, a current count of 10 sessions would place the graph half way between
// the 0 - point and L1, while 50 sessions would place the graph half way between
// L1 and L2.
//
export const calculatePercentage = ({ value, goals }) => {
  const percentagePerLevel = 100 / goals.length
  // We do not want to calculate the percentage beyond the closest goal
  const reached = goals.filter((g) => value > g).length + 1
  return goals.slice(0, reached).reduce((total, goal, index) => {
    // the `value` has reached this level goal so give the full `percentagePerLevel`
    if (value >= goal) {
      return total + percentagePerLevel
    }
    // calculate the `percentage` of the level based on this level's range.
    const levelBottom = goals[index - 1] || 0
    const levelTop = goals[index]
    const levelRange = levelTop - levelBottom
    const levelPercent = (value - levelBottom) / levelRange
    return total + percentagePerLevel * levelPercent
  }, 0)
}

const defaultPassColor = '#00E6C2'
const defaultFailColor = '#FF7070'

const Metrics = ({ selectedLevel, metrics, ...others }) => {
  return (
    <div {...others}>
      {metrics.map(
        (
          { goals, value, label, tooltip, statType, passColor, failColor },
          index
        ) => {
          const goal = goals[selectedLevel - 1]
          const isGoalReached = value >= goal

          return (
            <SC.Row key={`metric-${index}`} {...others}>
              <SC.Label data-toggle="tooltip" title={tooltip}>
                {label}
              </SC.Label>
              <SC.Bar
                percentage={calculatePercentage({ value, goals })}
                isGoalReached={isGoalReached}
                passColor={passColor || defaultPassColor}
                failColor={failColor || defaultFailColor}
              />
              <SC.Value
                isGoalReached={isGoalReached}
                passColor={passColor || defaultPassColor}
                failColor={failColor || defaultFailColor}
              >
                {value}
                {'/'}
                {goal}
                {statType === 'percent' && '%'}
              </SC.Value>
            </SC.Row>
          )
        }
      )}
    </div>
  )
}

const animation = {
  width: (percentage) => keyframes`
    0%   { width: 0; }
    60%  { width: ${percentage + 1}%; }
    80%  { width: ${percentage - 1}%; }
    100% { width: ${percentage}%; }
  `,
}

const SC = {}
SC.Row = styled.div`
  display: grid;
  grid-template-columns:
    [label] 1.2fr
    [levels] 4fr
    [value] 1.2fr;
  height: 43px;
  font-size: 12px;
  align-items: center;
`
SC.Label = styled.div`
  grid-column: label;
  color: #a6c0c6;
  text-align: right;
  margin-right: 20%;
  &:first-letter {
    text-transform: capitalize;
  }
`
SC.Bar = styled.div`
  position: relative;
  grid-column: levels;
  height: 3px;
  border-radius: 3px;
  width: 100%;
  background: #edeff0;

  &::before {
    content: ' ';
    display: block;
    width: 0; /* we animate the width */
    background: ${({ isGoalReached, passColor, failColor }) =>
      isGoalReached ? passColor : failColor};
    transition: 0.5s ease;
    height: 3px;
    border-radius: 3px;
    animation: ${({ percentage }) =>
      css`
        ${animation.width(percentage)} 0.5s 0.5s ease forwards
      `};
  }
`
SC.Value = styled.div`
  grid-column: value;
  color: ${({ isGoalReached, passColor, failColor }) =>
    isGoalReached ? passColor : failColor};
  animation: ${animation.opacity} 1 ease forwards;
  transition: 0.5s ease;
  margin-left: 20%;
  text-align: left;
`

Metrics.propTypes = {
  selectedLevel: PropTypes.number.isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      goals: PropTypes.array.isRequired,
      value: PropTypes.number.isRequired,
      statType: PropTypes.oneOf(['count', 'percent']),
      passColor: PropTypes.string,
      failColor: PropTypes.string,
    })
  ).isRequired,
}

export default Metrics
