import React from 'react'
import PropTypes from 'prop-types'

import LanguageCharacter from '../../LanguageCharacter/LanguageCharacter'

class LanguagePanel extends React.Component {
  state = {
    hover: false,
  }

  mouseEnter = () => {
    this.setState({ hover: true })
  }

  mouseLeave = () => {
    this.setState({ hover: false })
  }

  render() {
    const { hover } = this.state
    const { onClick, languageCode, localizedName } = this.props

    return (
      <div
        className="language-chooser__panel content-card"
        onClick={onClick}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        data-testid="language-panel"
      >
        {languageCode !== 'other' && (
          <div
            className={`language-chooser__panel-flag flag flag_small flag_${languageCode}`}
          />
        )}
        <div className="language-chooser__panel-character">
          <LanguageCharacter isPlaying={hover} languageCode={languageCode} />
        </div>
        <div className="language-chooser__panel-title">{localizedName}</div>
      </div>
    )
  }
}

LanguagePanel.propTypes = {
  onClick: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired,
  localizedName: PropTypes.string.isRequired,
}

export default LanguagePanel
