import React from 'react'
import PropTypes from 'prop-types'
import IBAN from 'iban'

export default class IbanField extends React.Component {
  static propTypes = {
    model: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
  }

  static defaultProps = {
    value: '',
  }

  constructor(props) {
    super(props)
    this.state = { value: '' }
  }

  onIbanChange(value) {
    this.setState({ value })
  }

  render() {
    const { model, field, className } = this.props
    const value = this.state.value
    let classNames = [className]

    if (!IBAN.isValid(value)) {
      classNames.push('field-error')
    }

    return (
      <input
        id={`${model}_${field}`}
        name={`${model}[${field}]`}
        className={classNames.join(' ')}
        type="text"
        placeholder="EE867700771000187087"
        value={value}
        onChange={(e) => this.onIbanChange(e.target.value)}
        required
      />
    )
  }
}
