import { Controller } from 'stimulus'
import Splide from '@splidejs/splide';

export default class ShortsViewController extends Controller {
  static values = { }

  connect() {
    let splide = new Splide( '.splide', {
      direction: 'ttb',
      height   : '100rem',
      arrows   : false,
      cover    : true,
      pagination: false,
      flickMaxPages: 1,
      preloadPages: 3,
      lazyLoad: true,
      perPage: 1,
    } )
    splide.mount()
    splide.on( 'visible', function (slide) {
      document.querySelectorAll("video").forEach(function(video) {
        video.pause()
      });
      slide.slide.querySelector('video').play()
    } )
  }

  changeLanguage(event) {
    let lang = event.target.value
    let url = window.location.href
    let newUrl = url + '/' + lang
    if(/\/[a-z]{2}\/?$/.test(url)) { // if it already ends in a lang code, replace it
      newUrl = url.replace(/\/[a-z]{2}\/?$/, '/' + lang + '/')
    }
     window.location.href = newUrl
  }
}