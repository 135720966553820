import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { createRoom } from 'src/lib/telco/telco'

const LiveLessonProvider = ({ telcoProvider, children }) => {
  const [providerOptions, setProviderOptions] = useState()

  useEffect(() => {
    const getProviderOptions = async () => {
      const data = await createRoom(telcoProvider)
      setProviderOptions(data)
    }
    getProviderOptions()
  }, [telcoProvider])

  if (!providerOptions) {
    return null
  }
  return React.cloneElement(children, {
    telcoProvider,
    providerOptions,
  })
}

LiveLessonProvider.propTypes = {
  telcoProvider: PropTypes.oneOf(['tokbox', 'openvidu']).isRequired,
}

export default LiveLessonProvider
