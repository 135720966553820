import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { t } from 'src/lib/i18n-react'

const themes = {
  view: {
    hoverBackground: '#AEB6BC',
  },
  rating: {
    hoverBackground: '#AEB6BC',
  },
  preview: {
    hoverBackground: '#60CBE4',
  },
  cancel: {
    hoverBackground: '#E85C68',
  },
  confirm: {
    leftBorder: '1px #F6F6F6 solid',
    hoverBackground: '#DFFDF8',
  },
  reject: {
    leftBorder: '1px #F6F6F6 solid',
    hoverBackground: '#FBE8E9',
  },
  book: {
    leftBorder: '1px #F6F6F6 solid',
    hoverBackground: '#DFFDF8',
  },
}

const tooltipMap = {
  view: t('View'),
  rating: t('Rating'),
  preview: t('Preview'),
  cancel: t('Cancel'),
  confirm: t('Confirm'),
  reject: t('Reject'),
  book: t('Book It'),
}

const Action = ({ type, ...others }) => {
  return (
    <SC.Container
      type={type}
      data-toggle="tooltip"
      title={tooltipMap[type]}
      {...others}
    >
      <img src={require(`./icons/${type}.svg`)} alt={tooltipMap[type]} />
    </SC.Container>
  )
}

const SC = {}
SC.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 45px;
  border-left: ${({ type }) => themes[type].leftBorder};
  cursor: pointer;

  &:hover {
    background: ${({ type }) => themes[type].hoverBackground};
  }
`

Action.propTypes = {
  type: PropTypes.oneOf([
    'view',
    'rating',
    'preview',
    'cancel',
    'confirm',
    'reject',
    'book',
  ]),
}

export default Action
