import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Box, Text } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import Pill from 'src/components/Pill/Pill'

const SubscriptionType = ({ type, date, label }) => {
  const isTrial = type === 'trial'

  return (
    <Box
      css={`
        text-transform: capitalize;
      `}
      textAlign="right"
      fontSize="xs"
    >
      <Pill color={isTrial ? 'green' : 'cadetBlues.1'}>{label}</Pill>
      <br />
      <Text pt="xs" color="cadetBlues.1">
        {isTrial
          ? t('expires in %{date} days', { date })
          : t('since %{since}', { since: moment(date).format('LL') })}
      </Text>
    </Box>
  )
}

SubscriptionType.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default SubscriptionType
