/* eslint-disable react/jsx-no-literals */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'

class GrammarPage extends React.Component {
  constructor(props) {
    super(props)

    const { labels } = this.props
    const initialLabel = window.location.hash.toLowerCase().replace('#', '')
    let shownLabels = {}

    labels.forEach((label) => {
      if (initialLabel) {
        shownLabels[label] = _.kebabCase(label) === initialLabel.toLowerCase()
      } else {
        shownLabels[label] = true
      }
    })

    this.state = { shownLabels, shownTopicIds: {}, search: '' }
  }

  handleHeaderClick = (label) => {
    this.setState({
      shownLabels: {
        ...this.state.shownLabels,
        [label]: !this.state.shownLabels[label],
      },
    })
  }

  handleSearchChange = (event) => {
    let search = _.deburr((event.target.value || '').toLowerCase())
    let shownLabels = {}
    let shownTopicIds = {}
    let topicsByLabel = this.props.topicsByLabel

    for (let key in topicsByLabel) {
      topicsByLabel[key].forEach(({ id, name, label, level }) => {
        let words = _.union(_.words(name), _.words(label), [
          level,
        ]).map((word) => _.deburr(word.toLowerCase()))

        words.forEach((word) => {
          if (_.includes(word, search)) {
            shownLabels[key] = true
            shownTopicIds[id] = true
          }
        })
      })
    }

    this.setState({ shownLabels, shownTopicIds, search })
  }

  render() {
    const { labels, topicsByLabel } = this.props

    const labelList = labels.map((label, index) => {
      const topicsList = (topicsByLabel[label] || []).map(
        (topic, topicIndex) => {
          const showTopic =
            this.state.search === '' || this.state.shownTopicIds[topic.id]
          if (showTopic) {
            return (
              <SC.Topic key={topicIndex}>
                <a href={topic.slug}>{topic.label}</a>
                <p>
                  {topic.name} - {topic.level}
                </p>
              </SC.Topic>
            )
          } else {
            return null
          }
        }
      )

      const shouldShowLabel = this.state.shownLabels[label]

      return (
        <div key={index} id={_.kebabCase(label)}>
          <SC.Header onClick={() => this.handleHeaderClick(label)}>
            <h2>{label}</h2>
            <div className="push" />
            <i className={`fa fa-${shouldShowLabel ? 'minus' : 'plus'}`} />
          </SC.Header>
          <SC.Topics>{shouldShowLabel && topicsList}</SC.Topics>
        </div>
      )
    })

    return (
      <div>
        <SC.Search
          type="text"
          onChange={this.handleSearchChange}
          placeholder="Type a word or expression to look for"
        />
        {labelList}
      </div>
    )
  }
}

const SC = {}
SC.Search = styled.input`
  margin-top: 10px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 21px;
  border: 2px solid #ddd;
  box-shadow: none;
  outline: 0;

  ::placeholder {
    color: #ccc;
  }
`

SC.Header = styled.div`
  margin-top: 20px;
  display: flex;
  cursor: pointer;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: #303030;
  }

  i {
    font-size: 20px;
    margin-top: 22px;
    color: #25cced;
  }

  .push {
    margin-left: auto;
  }
`

SC.Topic = styled.div`
  padding-bottom: 10px;
`

SC.Topics = styled.div`
  font-size: 16px;

  p {
    font-size: 14px;
    color: #ccc;
  }
`

SC.Container = styled.div`
  color: #9fc5ce;
`

GrammarPage.propTypes = {
  labels: PropTypes.array.isRequired,
  topicsByLabel: PropTypes.object.isRequired,
}

export default GrammarPage
