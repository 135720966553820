import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import zxcvbn from '@chatterbug/zxcvbn'

import { t } from 'src/lib/i18n-react'

const getScoreLabel = (score) =>
  [t('Worst'), t('Bad'), t('Weak'), t('Good'), t('Strong')][score]

class PasswordStrength extends React.Component {
  componentWillUnmount() {
    if (this.passwordEl) {
      this.passwordEl.removeEventListener('input')
    }
  }

  renderStrengthLabel = (result) => {
    return (
      <div className="password-strength__text">
        <span>
          {t('Strength: %{score}', { score: getScoreLabel(result.score) })}
        </span>
      </div>
    )
  }

  render() {
    const { password } = this.props

    if (!password) {
      return null
    }

    const result = zxcvbn(password)

    return (
      <div className="password-strength">
        <SC.PasswordMeter min="0" max="4" value={result.score} />
        {this.renderStrengthLabel(result)}
      </div>
    )
  }
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
}

const SC = {}
SC.PasswordMeter = styled.meter`
  border-radius: 3px;
  overflow: hidden;
`

export default PasswordStrength
