import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@chatterbug/aaron'
import { get } from 'lib/http'

const OnboardingButton = ({ link, text }) => {
  const [buttonText, setButtonText] = useState(text)
  const [buttonLink, setButtonLink] = useState(link)

  useEffect(() => {
    const checkCourseStatus = async () => {
      const {
        data: { apiResponseOnCourseCompleted },
      } = await get('/check_lms_course_completion')

      if (apiResponseOnCourseCompleted) {
        setButtonText('Set your availability')
        setButtonLink('/schedule/setup')
      }
    }
    text === 'Start course' && checkCourseStatus()
  }, [text])

  return (
    <Button
      type="cta"
      as="a"
      style={{
        color: 'white',
        textDecoration: 'none',
      }}
      href={buttonLink}
      disabled={!buttonLink}
    >
      {buttonText}
    </Button>
  )
}

OnboardingButton.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default OnboardingButton
