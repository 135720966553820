import React, { useState } from 'react'
import { Box } from '@chatterbug/aaron'

import { Language } from 'src/types'

import LanguageSetter from '../LanguageSetter/LanguageSetter'

export type Props = {
  languages: Language[]
  isMissingL1: boolean
  role: 'student' | 'tutor'
  nativeLanguageCodes: string
}

const LanguageSelectorPage: React.FC<Props> = ({
  languages,
  isMissingL1,
  role,
  nativeLanguageCodes: initialNativeLanguageCodes,
}) => {
  const [languageCode, setLanguageCode] = useState<string>()
  const [localizedName, setLocalizedName] = useState<string>()
  const [nativeLanguageCodes, setNativeLanguageCodes] = useState(
    initialNativeLanguageCodes
  )

  const handleSelectLanguage = (
    languageCode: string,
    localizedName: string,
    nativeLanguageCodes: string
  ) => {
    setLanguageCode(languageCode)
    setLocalizedName(localizedName)
    setNativeLanguageCodes(nativeLanguageCodes)
  }

  return (
    <Box width="100%" m="0 auto">
      <LanguageSetter
        languages={languages}
        localizedName={localizedName}
        nativeLanguageCodes={nativeLanguageCodes}
        isMissingL1={isMissingL1}
        role={role}
        onSelectLanguage={handleSelectLanguage}
        selectedLanguageCode={languageCode}
      />
    </Box>
  )
}

export default LanguageSelectorPage
