import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '@chatterbug/theme'
import { t, DateTime } from 'src/lib/i18n-react'
import { redirectToHREF } from 'src/lib/util'
import routes from 'src/lib/rails-routes/rails-routes'
import ContentTitle from 'src/components/ContentTitle/ContentTitle'
import Avatar from 'src/components/Avatar/Avatar'
import LiveDate from 'src/components/LiveDate'

const onPreview = (languageCode, chatSessionID) => {
  redirectToHREF(routes.chat_show_preview('tutor', chatSessionID))
}

const onJoin = (languageCode, chatSessionID) => {
  redirectToHREF(routes.chat_wait())
}

const NextLiveLesson = ({
  avatarURL,
  languageCode,
  login,
  userLevels,
  lessonImageURL,
  lessonName,
  startTime,
  endTime,
  chatSessionID,
}) => {
  const isCurrent = () => {
    const now = DateTime.local()
    const before = now < DateTime.fromISO(startTime)
    const after = now > DateTime.fromISO(endTime)
    return !before && !after
  }

  const isPast = () => {
    return DateTime.local() > DateTime.fromISO(startTime)
  }

  return (
    <>
      <ContentTitle
        title={isCurrent() ? t('Current Live Lesson') : t('Next Live Lesson')}
      />
      <SC.Container>
        <SC.UserProfile>
          <Avatar
            size="l"
            url={avatarURL}
            languageCode={languageCode}
            style={{ marginRight: 10 }}
          />
          {login}
          <br />
          {t('Level %{userLevels}', { userLevels })}
        </SC.UserProfile>
        <SC.Lesson>
          {Boolean(lessonImageURL) && <img src={lessonImageURL} />}
          <span>{lessonName}</span>
          {chatSessionID && !isCurrent() && (
            <Button
              as="submit"
              bg="dark"
              style={{ marginTop: 25 }}
              presetColor="white"
              onClick={() => onPreview(languageCode, chatSessionID)}
            >
              {t('Preview Lesson')}
            </Button>
          )}
          {chatSessionID && isCurrent() && (
            <Button
              as="submit"
              bg="dark"
              presetColor="white"
              style={{ marginTop: 25 }}
              onClick={() => onJoin(languageCode, chatSessionID)}
            >
              {t('JOIN LESSON')}
            </Button>
          )}
        </SC.Lesson>
        <SC.Time>
          {isPast() ? t('Lesson started:') : t('Lesson starts:')}
          <br />
          <LiveDate date={startTime} format="fromNow" />
        </SC.Time>
      </SC.Container>
    </>
  )
}

const SC = {}
SC.Container = styled.div`
  display: grid;
  grid-auto-columns: 3fr;
  background: #18d9f3;
  border-radius: 10px;
  padding: 25px;
  color: white;
  font-size: 14px;
`
SC.UserProfile = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: row;
`
SC.Lesson = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 999px;
    width: 79px;
    height: 79px;
    margin-bottom: 5px;
  }
`
SC.Time = styled.div`
  grid-column: 3;
  text-align: right;
`

NextLiveLesson.propTypes = {
  avatarURL: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  userLevels: PropTypes.string.isRequired,
  lessonImageURL: PropTypes.string.isRequired,
  lessonName: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  chatSessionID: PropTypes.string,
}

export default NextLiveLesson
