import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '@chatterbug/aaron'

import de from './assets/character-front-basic-de@2x.png'
import es from './assets/character-front-basic-es@2x.png'
import en from './assets/character-front-basic-en@2x.png'
import fr from './assets/character-front-basic-fr@2x.png'

const Character = ({ languageCode, ...rest }) => {
  const getCharacter = (languageCode) => {
    return {
      de,
      es,
      en,
      fr,
    }[languageCode]
  }
  return <Image width={124} src={getCharacter(languageCode)} {...rest} />
}

Character.propTypes = {
  languageCode: PropTypes.oneOf(['en', 'de', 'es', 'fr']).isRequired,
}

export default Character
