/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import { bindMethods, classString } from 'lib/util'

// A range with a pointer to the value

export default class MarkedRange extends React.Component {
  static className = 'marked-range'
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    wide: PropTypes.bool,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(element, options) {
    super()
    bindMethods(this)
  }

  handleChangeRange(e) {
    const { onChange } = this.props
    const value = parseFloat(e.target.value)
    onChange(value)
  }

  render(state) {
    const { id, name, min, max, step, value, children, wide } = this.props
    const markerLeft = `${((value - min) * 100) / (max - min)}%`
    const classes = classString([
      'marked-range',
      wide && 'marked-range_wide-marker',
    ])

    return (
      <div className={classes} data-name={name}>
        <input
          className="marked-range__range"
          type="range"
          id={id}
          name={name}
          value={value}
          step={step}
          min={min}
          max={max}
          onInput={this.handleChangeRange}
          onChange={this.handleChangeRange}
        />
        <div className="marked-range__marker-track">
          <Marker left={markerLeft}>{children}</Marker>
        </div>
      </div>
    )
  }
}

export const Marker = (props) => {
  const { left, children } = props
  return (
    <div className="marked-range__marker" style={{ left }}>
      {children}
    </div>
  )
}

MarkedRange.Marker = Marker
