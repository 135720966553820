import { ceil } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Box, Image } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import ottoJumping from './ottoJumping.png'

/*
The progress meters work like this:

Max height of a box is 100 pixels.
Min height of a box is 4 pixels.
If zero study hours or zero Live Lesson, the box will be gray.
Box height = 4 + ceil(value in hours) where value <= 96.0.
A few examples to illustrate:

If study hours is 0, that box will be 4px tall and gray.
If Live Lessons is 1, that box will be 5px tall and purple (4 + ceil(0.75)).
Now, when hour values are greater than 96.0:

Take the higher of the two hour values and scale that to 100px height.
Then take the other value and scale it the same and round.
An example scenario:

study_hours = 120.1 : box height will be 100px (4 + 96).
lesson_hours = 33 : box height will be 30px (4 + 26)
*/

const MAX_HEIGHT = 100
const MIN_HEIGHT = 4

const roundHours = ({ studyMinutes, lessonMinutes }) => {
  return {
    studyHours: ceil(studyMinutes / 60, 1),
    lessonHours: ceil(lessonMinutes / 60, 1),
  }
}

const pixelHours = ({ studyHours, lessonHours }) => {
  const mostHours = Math.max(studyHours, lessonHours)
  let ratio = 1
  if (mostHours > MAX_HEIGHT - MIN_HEIGHT) {
    ratio = (MAX_HEIGHT - MIN_HEIGHT) / mostHours
  }
  return {
    pixelStudyHours: MIN_HEIGHT + Math.round(studyHours * ratio),
    pixelLessonHours: MIN_HEIGHT + Math.round(lessonHours * ratio),
  }
}

const ProgressMeters = ({
  studyMinutes,
  lessonMinutes,
  lessons,
  jointLessons,
}) => {
  const { studyHours, lessonHours } = roundHours({
    studyMinutes,
    lessonMinutes,
  })

  const { pixelStudyHours, pixelLessonHours } = pixelHours({
    studyHours,
    lessonHours,
  })

  return (
    <Box>
      <Flex justifyContent="center" alignItems="flex-end">
        <Box
          width="135px"
          textAlign="center"
          fontSize="sm"
          color="cadetBlues.1"
        >
          <Box
            height={pixelStudyHours + 'px'}
            minHeight="4px"
            m="sm"
            mt={MAX_HEIGHT - pixelStudyHours + 'px'}
            bg={studyHours === 0 ? 'gray' : '#0DDCF7'}
          />
          {t('%{studyHours}h Self Study', { studyHours })}
        </Box>
        <Flex
          width="135px"
          textAlign="center"
          fontSize="sm"
          color="cadetBlues.1"
          justifyContent="flex-end"
          flexDirection="column"
        >
          {lessons === 0 && (
            <Image src={ottoJumping} height="100px" alignSelf="center" />
          )}
          <Box
            height={pixelLessonHours + 'px'}
            minHeight="4px"
            m="sm"
            mt={MAX_HEIGHT - pixelLessonHours + 'px'}
            bg={lessonHours === 0 ? 'gray' : '#CBB8F5'}
          />
          {t('%{lessons} Live Lessons', { lessons })}
        </Flex>
      </Flex>
      {lessons > 0 && (
        <Flex justifyContent="center" fontSize="sm">
          {`${t(
            '%{smart_count} Lesson with you |||| %{smart_count} Lessons with you',
            {
              smart_count: jointLessons,
            }
          )}!`}
        </Flex>
      )}
    </Box>
  )
}

ProgressMeters.propTypes = {
  studyMinutes: PropTypes.number.isRequired,
  lessons: PropTypes.number.isRequired,
  jointLessons: PropTypes.number.isRequired,
  lessonMinutes: PropTypes.number.isRequired,
}

export default ProgressMeters
