import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Box } from '@chatterbug/aaron'
import LanguageChooser from '../SignupPage/subcomponents/LanguageChooser/LanguageChooser'
import UnknownL1Form from '../SignupPage/subcomponents/UnknownL1Form/UnknownL1Form'
import { t } from 'src/lib/i18n-react'
import { Language } from 'src/types'
import { handleSelectLanguage, selectValidPair } from './util'

export type Props = {
  languages: Language[]
  nativeLanguageCodes: string
  localizedName: string
  onSelectLanguage: (
    selectedLanguageCode: string,
    localizedName: string,
    finalNativeLanguageCodes: string[] | string,
    index?: string
  ) => void
  withOther?: boolean
  selectedLanguageCode?: string | undefined
  isMissingL1?: boolean
  initialSelectedLanguageCode?: string | undefined
  role?: 'student' | 'tutor'
  onGoBack?: () => void
  withSignup?: boolean
}

const LanguageSetter: React.FC<Props> = ({
  initialSelectedLanguageCode,
  selectedLanguageCode,
  onGoBack,
  withOther,
  localizedName,
  languages,
  nativeLanguageCodes,
  role,
  isMissingL1,
  withSignup,
  onSelectLanguage,
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  // manages what happens on pressing the browser back button --
  // resetting state to default in parent and going to the L2 Selection Page in this component
  useEffect(() => {
    window.onpopstate = (e: Event) => {
      setCurrentStep(0)
      onGoBack?.()
    }
  }, [onGoBack])

  // set a selected language immediately if it's passed from backend
  useLayoutEffect(() => {
    if (initialSelectedLanguageCode !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleSelectLanguage(
        initialSelectedLanguageCode,
        languages,
        nativeLanguageCodes,
        isMissingL1,
        role,
        withSignup,
        onSelectLanguage,
        setCurrentStep
      )
    }
  }, [
    initialSelectedLanguageCode,
    isMissingL1,
    languages,
    nativeLanguageCodes,
    onSelectLanguage,
    role,
    withSignup,
  ])

  if (currentStep === 0) {
    return (
      <Box>
        <Box as="h2" variant="text.h2" color="white" mb={40} fontSize={32}>
          {role === 'tutor'
            ? t('What language do you want to teach?')
            : t('What language do you want to learn?')}
        </Box>
        <LanguageChooser
          withOther={withOther}
          languages={languages}
          onSelectLanguage={(code: string) =>
            handleSelectLanguage(
              code,
              languages,
              nativeLanguageCodes,
              isMissingL1,
              role,
              withSignup,
              onSelectLanguage,
              setCurrentStep
            )
          }
        />
      </Box>
    )
  }

  if (currentStep === 1) {
    return (
      <UnknownL1Form
        withOther={withOther}
        languages={languages}
        languageCode={initialSelectedLanguageCode || selectedLanguageCode}
        onResumeSignup={(code: string, languageCode: string) =>
          selectValidPair(
            code,
            languageCode,
            languages,
            withSignup,
            onSelectLanguage
          )
        }
        localizedName={localizedName}
      />
    )
  }
}

export default LanguageSetter
