import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { t, DateTime } from 'src/lib/i18n-react'

import Item from './subcomponents/Item/Item'

class AppointmentList extends React.Component {
  componentDidUpdate() {
    try {
      $('[data-toggle="tooltip"]').tooltip()
    } catch (e) {
      // No jQuery in Storybook
    }
  }

  render() {
    const { timezone, appointments, actions, showPast } = this.props

    if (appointments.length > 0) {
      return (
        <SC.Table>
          <tbody>
            {appointments.map(({ start, finish, status, ...others }, index) => {
              const isPast =
                DateTime.local().toMillis() >
                DateTime.fromISO(finish).toMillis()
              const computedStatus = isPast ? 'past' : status
              if (isPast && !showPast) {
                return null
              } else {
                return (
                  <Item
                    key={`event-${index}-${start}-${finish}`}
                    timezone={timezone}
                    start={start}
                    finish={finish}
                    status={computedStatus}
                    actions={actions}
                    {...others}
                  />
                )
              }
            })}
          </tbody>
        </SC.Table>
      )
    } else {
      return <p>{t('None')}</p>
    }
  }
}

const SC = {}
SC.Table = styled.table`
  margin: 10px 0;
  width: 100%;

  td {
    padding-bottom: 20px;
  }

  tr:last-child td {
    padding-bottom: 0;
  }
`

AppointmentList.defaultProps = {
  showPast: false,
}

AppointmentList.propTypes = {
  timezone: PropTypes.string.isRequired,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start: PropTypes.string.isRequired,
      finish: PropTypes.string.isRequired,
      status: PropTypes.oneOf([
        'pending_me',
        'pending_partner',
        'confirmed',
        'canceled',
        'past',
        'bookable',
        'other',
      ]).isRequired,
    })
  ).isRequired,
  actions: PropTypes.object.isRequired,
  showPast: PropTypes.bool,
}

export default AppointmentList
