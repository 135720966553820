/* eslint-disable react/prop-types */
import React from 'react'

import Loader from 'components/Loader'

// A spinner that shows next to form buttons and can display a message

export default class FormLoader extends React.Component {
  static className = 'form-loader'
  static defaultProps = {
    messageType: 'error',
    message: null,
    loading: false,
  }

  render() {
    const { loading, message, messageType } = this.props
    const messageClass = message
      ? `${FormLoader.className}_message-${messageType}`
      : ''

    return (
      <span className={`form-loader ${messageClass}`}>
        <span className="form-loader__message">{message}</span>
        {loading ? <Loader /> : null}
      </span>
    )
  }
}
