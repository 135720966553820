/* eslint-disable react/jsx-no-literals */
import React from 'react'
import PropTypes from 'prop-types'

import { Flex, Box, Text, Image } from '@chatterbug/aaron'
import { Modal } from '@chatterbug/aaron/modal'
import CEFRSeal from 'src/components/CEFRSeal/CEFRSeal'
import Flag from 'src/components/Flag/Flag'

import ProgressMeters from './subcomponents/ProgressMeters/ProgressMeters'
import SubscriptionType from './subcomponents/SubscriptionType/SubscriptionType'
import FirstLiveLesson from './subcomponents/FirstLiveLesson/FirstLiveLesson'
class StudentProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.showModal }
  }

  render() {
    const {
      levelLabel,
      cefrLevel,
      levelProgress,
      profileImageURL,
      login,
      name,
      timezone,
      subscription,
      progress,
      showButton,
      languageCode,
      nativeLanguageLabel,
    } = this.props

    return (
      <>
        {showButton && (
          <a href="#" onClick={() => this.setState({ isOpen: true })}>
            <i className="fa fa-eye" />
          </a>
        )}

        <Modal
          isOpen={this.state.isOpen}
          title="Student Profile"
          onRequestClose={() => this.setState({ isOpen: false })}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Flex fontWeight="thin" fontSize="xs" width={1}>
              <Box flex="1">
                <Box width="64px">
                  <CEFRSeal progress={levelProgress} cefrLevel={cefrLevel} />
                  <br />
                  <Text textAlign="center" color="cadetBlues.1">
                    Level {levelLabel}
                  </Text>
                </Box>
              </Box>

              <Flex flex="1" flexDirection="column" alignItems="center">
                <Image
                  src={profileImageURL}
                  alt="Gravatar"
                  width="72"
                  height="72"
                  borderRadius="full"
                />
                <Text
                  color="black"
                  fontSize="lg"
                  fontWeight="semiBold"
                  textAlign="center"
                >
                  {login}
                </Text>
                {name && (
                  <Text color="black" fontWeight="semiBold" textAlign="center">
                    {name}
                  </Text>
                )}
                <Text color="cadetBlues.1" textAlign="center">
                  {timezone}
                </Text>
              </Flex>

              <Box textAlign="center" flex="1">
                <SubscriptionType {...subscription} />
              </Box>
            </Flex>

            {languageCode && (
              <Box width={1} textAlign="center">
                <Flag
                  countryCode={languageCode}
                  size="s"
                />
                {nativeLanguageLabel && (
                  <Text
                    color="black"
                    fontSize="s"
                    fontWeight="thin"
                    textAlign="center"
                  >
                    {nativeLanguageLabel}
                  </Text>
                )}
              </Box>
            )}

            <Box width={1}>
              {progress.lessonMinutes === 0 && <FirstLiveLesson />}
              <ProgressMeters {...progress} />
            </Box>
          </Flex>
        </Modal>
      </>
    )
  }
}

StudentProfile.propTypes = {
  levelLabel: PropTypes.string.isRequired,
  cefrLevel: PropTypes.oneOf(['a1', 'a2', 'b1', 'b2', 'c1', 'c2', '-']),
  levelProgress: PropTypes.number.isRequired,
  profileImageURL: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  name: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  progress: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  showButton: PropTypes.bool.isRequired,
  languageCode: PropTypes.string,
  nativeLanguageLabel: PropTypes.string,
}

export default StudentProfile
