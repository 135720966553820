import React from 'react'
import PropTypes from 'prop-types'

export default class Panel extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
  }

  render() {
    let { isActive, className, children } = this.props
    if (!isActive) return null

    return <div className={className}>{children}</div>
  }
}
