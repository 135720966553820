import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Cookies from 'js-cookie'

import { t, DateTime } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import ContentTitle from 'src/components/ContentTitle/ContentTitle'
import ContentCard from 'src/components/ContentCard/ContentCard'
import AppointmentCalendar from 'src/components/AppointmentCalendar/AppointmentCalendar'
import AppointmentList from 'src/components/AppointmentList/AppointmentList'
import Markdown from 'src/components/Markdown/Markdown'

const TAB_COOKIE = 'DashboardTutorPage.Appointments.selectedTab'
const TAB_CALENDAR = 'calendar'
const TAB_LIST = 'list'

class Appointments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: props.selectedTab,
    }
  }

  renderTitleActions = () => {
    const { selectedTab } = this.state
    return (
      <div>
        <SC.HeaderTab
          selected={selectedTab === TAB_LIST}
          onClick={() => {
            this.setState({ selectedTab: TAB_LIST })
            Cookies.set(TAB_COOKIE, TAB_LIST)
          }}
        >
          <i className="fa fa-icon fa-th-list" /> {t('List')}
        </SC.HeaderTab>
        <SC.HeaderTab
          selected={selectedTab === TAB_CALENDAR}
          onClick={() => {
            this.setState({ selectedTab: TAB_CALENDAR })
            Cookies.set(TAB_COOKIE, TAB_CALENDAR)
          }}
        >
          <i className="fa fa-icon fa-th-large" /> {t('Calendar')}
        </SC.HeaderTab>
      </div>
    )
  }

  renderCalendar = () => {
    const { upcomingAppointments, actions } = this.props

    const apps = upcomingAppointments.appointments
    const numAppointments = apps.length
    let numOfDays = 2
    if (numAppointments > 0) {
      const lastAppointmentStart = DateTime.fromISO(
        apps[numAppointments - 1].start
      ).endOf('day')
      numOfDays = Math.ceil(
        lastAppointmentStart.diff(DateTime.local(), 'days').days
      )
    }

    return (
      <AppointmentCalendar
        startDate={DateTime.local().toISO()}
        numOfDays={numOfDays}
        appointments={upcomingAppointments.appointments}
        actions={actions}
        scrollToTime="now"
      />
    )
  }

  renderList = () => {
    const { upcomingAppointments, actions } = this.props

    return (
      <ContentCard>
        <AppointmentList
          timezone={upcomingAppointments.timezone}
          appointments={upcomingAppointments.appointments}
          actions={actions}
        />
      </ContentCard>
    )
  }

  render() {
    const { selectedTab } = this.state
    const { upcomingAppointments } = this.props
    const { compactTimezone } = upcomingAppointments
    const numofAppointments = upcomingAppointments.appointments.length

    if (numofAppointments === 0) {
      return (
        <SC.NoAppointments>
          {'🙉'}
          {t("You don't have any upcoming appointments")}
        </SC.NoAppointments>
      )
    }

    const extraAppointments =
      numofAppointments - upcomingAppointments.appointments.length
    return (
      <React.Fragment>
        <ContentTitle
          title={t('Upcoming Lessons')}
          subtitle={
            <Markdown>
              {t('All times in **%{timezone}** timezone', {
                timezone: compactTimezone,
              })}
            </Markdown>
          }
          actionsContent={this.renderTitleActions}
        />
        {selectedTab === TAB_CALENDAR
          ? this.renderCalendar()
          : this.renderList()}
        <SC.AppointmentCalendarFooter>
          <a href={routes.schedule_index()}>
            {extraAppointments > 0 && (
              <span>
                {t('+%{extraAppointments} more. ', { extraAppointments })}
              </span>
            )}
            {t('manage schedule')}
          </a>
          <SC.Separator>{'|'}</SC.Separator>
          <a href={routes.chat_check()}>
            {t('check your internet connection')}
          </a>
        </SC.AppointmentCalendarFooter>
      </React.Fragment>
    )
  }
}

const SC = {}
SC.HeaderTab = styled.a`
  margin-left: 20px;
  font-size: ${(props) => props.theme.fontSizes[1]};
  color: ${(props) => (props.selected ? 'black' : 'gray')};
  cursor: pointer;
  position: relative;
  top: -4px;

  i {
    font-size: 70%;
    position: relative;
    top: -1px;
    padding-right: 4px;
  }
`
SC.AppointmentCalendarFooter = styled.div`
  margin-top: 10px;
  text-align: right;
  a {
    font-size: ${(props) => props.theme.fontSizes[1]};
    padding: 0 10px;
  }
`
SC.Separator = styled.span`
  padding: 0 10px;
  color: ${(props) => props.theme.colors.grays[2]};
`
SC.NoAppointments = styled.div`
  background: #f4f7f9;
  border-radius: 4px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`

Appointments.defaultProps = {
  selectedTab: Cookies.get(TAB_COOKIE) || TAB_LIST,
}

Appointments.propTypes = {
  selectedTab: PropTypes.oneOf([TAB_CALENDAR, TAB_LIST]),
  upcomingAppointments: PropTypes.shape({
    compactTimezone: PropTypes.string.isRequired,
    appointments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        start: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  actions: PropTypes.object.isRequired,
}

export default Appointments
