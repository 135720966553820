import React from 'react'
import PropTypes from 'prop-types'

import Confetti from 'src/lib/react-rewards'

class Reward extends React.Component {
  componentDidMount() {
    this.props.fireAutomatically &&
      window.setTimeout(() => this.reward.rewardMe(), 1000)
  }

  setInnerHTML = () =>
    this.props.allowHTML ? { __html: this.props.children } : null

  render() {
    return (
      <Confetti
        type="emoji"
        config={{ spread: 100 }}
        ref={(ref) => (this.reward = ref)}
      >
        <div
          onClick={() => this.reward.rewardMe()}
          dangerouslySetInnerHTML={this.setInnerHTML()}
        >
          {this.props.allowHTML ? null : this.props.children}
        </div>
      </Confetti>
    )
  }
}

Reward.defaultProps = {
  allowHTML: false,
  fireAutomatically: true,
}

Reward.propTypes = {
  allowHTML: PropTypes.bool,
  fireAutomatically: PropTypes.bool,
}

export default Reward
