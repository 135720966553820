import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Creatable } from 'react-select'

import { Button } from '@chatterbug/aaron'
import { post, serializeForm } from 'src/lib/util'
import { t } from 'src/lib/i18n-react'

class InterestFormPage extends React.Component {
  state = { name: '', email: '', nativeLanguage: '' }

  handleSubmit = (event) => {
    event.preventDefault()
    const { onNext } = this.props
    post('/interest/new', serializeForm(event.target), 'json')
    onNext()
  }

  languageOptions = (exclusions) => {
    return [
      { value: 'Chinese', label: t('Chinese') },
      { value: 'English', label: t('English') },
      { value: 'French', label: t('French') },
      { value: 'Italian', label: t('Italian') },
      { value: 'Japanese', label: t('Japanese') },
      { value: 'Russian', label: t('Russian') },
    ].filter((lang) => !exclusions.includes(lang.value))
  }

  render() {
    const { nativeLanguage, name, email } = this.state
    const { targetLanguage } = this.props

    const isValid = !!(nativeLanguage && name && email)
    const availableNativeLanguages = targetLanguage.pairedNativeLanguages.map(
      (lang) => lang.localizedName
    )

    return (
      <SC.Container>
        <SC.Title>{t("We're sorry")}</SC.Title>
        <SC.Explanation primary>
          {t(
            'Currently, our %{targetLocalizedName} course is for %{availableNativeLanguages} speakers only.',
            {
              targetLocalizedName: targetLanguage.localizedName,
              availableNativeLanguages: availableNativeLanguages.join(' / '),
            }
          )}
        </SC.Explanation>
        <SC.Explanation>
          {t(
            'But you can join our waiting list for %{targetLocalizedName} in your native language:',
            {
              targetLocalizedName: targetLanguage.localizedName,
            }
          )}
        </SC.Explanation>

        <SC.Form onSubmit={this.handleSubmit}>
          <input
            type="hidden"
            name="target_language"
            value={targetLanguage.localizedName}
          />
          <div className="form-group">
            <label htmlFor="native_language">
              {t('What is your native language?')}
            </label>
            <Creatable
              joinValues
              isSearchable
              name="native_language"
              value={nativeLanguage}
              onChange={(val) => this.setState({ nativeLanguage: val })}
              placeholder={t('Select or type...')}
              options={this.languageOptions([
                targetLanguage.localizedName,
                ...availableNativeLanguages,
              ])}
            />
          </div>
          <div className="form-group">
            <label htmlFor="full_name">{t('Name')}</label>
            <input
              name="full_name"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              type="text"
              className="form-control"
              placeholder={t('Your Name')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email_address">{t('Email Address')}</label>
            <input
              name="email_address"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
              type="email"
              className="form-control"
              placeholder={t('your@email.com')}
            />
          </div>

          <SC.ButtonContainer>
            <Button fill="solid" size="large" type="cta" disabled={!isValid}>
              {t('Submit')}
            </Button>
            <SC.Explanation>
              {t("We'll email you when it's available!")}
            </SC.Explanation>
          </SC.ButtonContainer>
        </SC.Form>
      </SC.Container>
    )
  }
}

const SC = {}

SC.Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
SC.ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`
SC.Form = styled.form`
  display: flex;
  text-align: left;
  width: 100%;
  flex-direction: column;
  align-items: fill;
  margin: 30px 0 20px 0;
`
SC.Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
`
SC.Explanation = styled.div`
  font-weight: ${(props) => (props.primary ? 'bold' : 'normal')};
  margin-top: 20px;
`

InterestFormPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  targetLanguage: PropTypes.object.isRequired,
}

export default InterestFormPage
