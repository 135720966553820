import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Flex, Box, Text } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import Avatar from 'src/components/Avatar/Avatar'

import Globe from '../Globe/Globe'

const TranslationText = ({ children }) => {
  return (
    <Text color="grays.1" aria-label="Translated text" fontStyle="italic">
      {children}
    </Text>
  )
}

const Message = ({ avatarUrl, message, onTranslateClick, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const [translation, setTranslation] = useState()

  const handleTranslateClick = async () => {
    try {
      setLoading(true)
      const text = await onTranslateClick(message)
      setTranslation(text || t('No translation found'))
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <Flex flexDirection="column" {...rest}>
      <Flex
        mb="sm"
        aria-label="Text chat message"
        css={`
          word-break: break-word;
          :hover {
            background-color: #f8f8f8;
          }
        `}
      >
        <Box mr="sm" minWidth={28}>
          {avatarUrl && <Avatar size="m" url={avatarUrl} />}
        </Box>
        <Box flex={1}>
          {message}
          {translation && <TranslationText>{translation}</TranslationText>}
        </Box>
        <Box width={28} mr="sm" textAlign="right">
          <Globe onClick={handleTranslateClick} loading={loading} />
        </Box>
      </Flex>
    </Flex>
  )
}

Message.propTypes = {
  avatarUrl: PropTypes.string,
  message: PropTypes.string.isRequired,
  onTranslateClick: PropTypes.func.isRequired,
}

export default Message
