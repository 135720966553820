/* global Intercom */

import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'src/lib/i18n-react'

import { classString } from 'lib/util'
import { isUserMediaAccessGranted } from 'src/lib/telco/telco'

import PageAlert from 'src/components/PageAlert'

export default class WaitChecklist extends React.Component {
  static defaultProps = {
    checkBandwidth: true,
    video: null,
    audio: null,
    bandwidth: null,
  }

  static propTypes = {
    checkBandwidth: PropTypes.bool,
    bandwidth: PropTypes.bool,
  }

  async componentDidMount() {
    const userMediaGranted = await isUserMediaAccessGranted()
    // if permission already granted, do nothing
    if (userMediaGranted) {
      return null
    }
    try {
      // if there are are no errors with asking user for media permissions, do nothing, browser handles it
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { facingMode: 'user' },
      })
      return null
      // if they have previously blocked camera and/or mic usage on our website, show them an alert
    } catch (e) {
      if (e.name === 'NotAllowedError' || e.name === 'PermissionDeniedError') {
        PageAlert.showDanger(
          <div>
            <span>
              {t(
                'Your browser is blocking your camera and microphone. Click the camera icon in your address bar, always allow our website to access your camera and microphone for Live Lessons and reload the page'
              )}
            </span>
            <i className="fa fa-video-camera" style={{ marginLeft: 8 }} />
          </div>
        )
      }
    }
  }

  classesFor(check) {
    return classString([
      `l-chat-waiting-room__${check}-list-item`,
      'todo-list__item',
      this.props[check]
        ? 'todo-list__item_complete'
        : 'todo-list__item_incomplete',
    ])
  }

  renderBandwidth(bandwidthState) {
    const { checkBandwidth } = this.props
    if (!checkBandwidth) return null

    const classes = classString([
      'l-chat-waiting-room__bandwidth-list-item',
      'todo-list__item',
      bandwidthState.liClassName,
    ])
    return (
      <li className={classes}>
        <i className={bandwidthState.iClassNames} />
        <span>{bandwidthState.taskText}</span>
      </li>
    )
  }

  render() {
    const { bandwidth } = this.props
    const bandwidthState = BandwidthStates[bandwidth]
    return (
      <div>
        <ul className="todo-list">
          <li className={this.classesFor('video')}>
            <i className="fa fa-check" />
            <span>{t('Your video is connected')}</span>
          </li>
          <li className={this.classesFor('audio')}>
            <i className="fa fa-check" />
            <span>
              {t('Your audio is working')}
              <br />
              {t('(Say "hello" out loud!)')}
            </span>
          </li>
          {this.renderBandwidth(bandwidthState)}
        </ul>
        <p className="text_smaller text_subtle">
          {bandwidthState.helpComponent}
        </p>
        <ul className="todo-list">
          <li className="todo-list__item todo-list__item_complete todo-list__item_secondary">
            <i className="fa fa-check" />
            <span>{t('You are easy to see in the video')}</span>
          </li>
          <li className="todo-list__item todo-list__item_complete todo-list__item_secondary">
            <i className="fa fa-check" />
            <span>{t('You are in a quiet place')}</span>
          </li>
          <li className="todo-list__item todo-list__item_complete todo-list__item_secondary">
            <i className="fa fa-check" />
            <span>{t('You are wearing headphones')}</span>
          </li>
        </ul>
      </div>
    )
  }
}

const BestHelp = () => (
  <span>
    {t(`To make sure both people have the best experience, please try to
    make your video look good and sound clear with no background noise.`)}
  </span>
)

const UnreliableHelp = () => {
  const helpStr = t("I'm having trouble connecting to my Live Lesson - help!")
  return (
    <span>
      {t(`Please consider closing other programs using your network or moving closer
      to your router. For more help please`)}{' '}
      <a
        onClick={() => {
          Intercom('showNewMessage', helpStr)
        }}
        href="javascript:void(0)"
      >
        {t('contact support')}
      </a>
    </span>
  )
}

const BandwidthStates = {
  true: {
    liClassName: 'todo-list__item_complete',
    iClassNames: 'fa fa-check',
    taskText: t('Your internet is fast and reliable'),
    helpComponent: <BestHelp />,
  },
  false: {
    liClassName: 'todo-list__item_warning',
    iClassNames: 'fa fa-check',
    taskText: t(
      'Your connection seems unreliable, but you can still continue!'
    ),
    helpComponent: <UnreliableHelp />,
  },
  null: {
    liClassName: 'todo-list__item_incomplete_alt',
    iClassNames: 'fa fa-circle-o-notch fa-spin fa-3x fa-fw',
    taskText: t('Your internet is fast and reliable'),
    helpComponent: <BestHelp />,
  },
}
