import React from 'react'
import PropTypes from 'prop-types'

import { bindMethods } from 'lib/util'

// So you have a box with scrollable content on a scrollable page. You scroll to
// the end of the scrollable content in the box and it scrolls the whole page.
// You facepalm. Your heart breaks. Suddenly the page feels like ice and you are
// disoriented. Unhappy. Generally discontent.
//
// The ParentScrollPreventer is your saviour.

export default class ParentScrollPreventer extends React.Component {
  static propTypes = {
    preventedSelector: PropTypes.string,
  }

  static defaultProps = {
    preventedSelector: 'body',
  }

  constructor() {
    super()
    bindMethods(this)
  }

  getPreventedElement() {
    return document.querySelector(this.props.preventedSelector)
  }

  handleMouseEnter() {
    this.getPreventedElement().classList.add('no-scroll')
  }

  handleMouseLeave() {
    this.getPreventedElement().classList.remove('no-scroll')
  }

  getElement() {
    return this.element
  }

  render() {
    const { children, className } = this.props
    return (
      <div
        ref={(el) => {
          this.element = el
        }}
        className={className}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children}
      </div>
    )
  }
}
