/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'

import ChatChannel from 'lib/actioncable/chat-channel'
import { translate } from 'src/lib/translate'
import TextChat from 'src/pages/LiveLesson/components/TextChat/TextChat'

export default class TextChatCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: this.convertMessages(this.props.initialMessages),
    }
  }

  // unsubscribe from events
  componentWillUnmount() {
    if (this.chatChannell) {
      this.chatChannel.off('speak', this.handleNewMessage)
    }
  }

  // connect to ChatChannel and set converted messages passed from props
  // on mount
  componentDidMount() {
    this.chatChannel = ChatChannel.get(this.props.sessionId)
    this.chatChannel.on('speak', this.handleNewMessage)
  }

  // convert messages to a form readable by TextChat
  convertMessages = (events) => {
    if (!events) {
      return []
    }
    return events.map(({ event: { avatarUrl, speak } }) => {
      return {
        avatarUrl: avatarUrl,
        message: speak,
      }
    })
  }

  // add the new message to state
  handleNewMessage = (event) => {
    const { speak: message, avatarUrl } = event
    this.setState({
      messages: [...this.state.messages, { message, avatarUrl }],
    })
  }

  // user sends new message in the input field. this sends it to the server and then it
  // is handled by handleNewMessage
  handleSubmitMessage = (message) => {
    this.chatChannel.send({
      type: 'speak',
      speak: message,
    })
  }

  // handle translate
  handleTextChatTranslateClick = (phrase) => {
    const { sessionId } = this.props
    return translate({ phrase, sessionId })
  }

  render() {
    return (
      <TextChat
        messages={this.state.messages}
        onSubmit={this.handleSubmitMessage}
        onTranslateClick={this.handleTextChatTranslateClick}
      />
    )
  }
}

TextChatCell.propTypes = {
  initialMessages: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.object.isRequired,
      timestamp: PropTypes.string.isRequired,
    })
  ),
  sessionId: PropTypes.string.isRequired,
}
