import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import { Box, Flex, Image } from '@chatterbug/aaron'

import speaker from './images/speaker.png'

// gets youtube ID from the link
export const getYoutubeID = (mediaUrl) => {
  let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  let match = mediaUrl.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  }
}

/**
 * This component is used by the React Native application via a Webview. It
 * communicates with the the React Native application by posting and receiving
 * messages.
 */
class MediaPlayback extends React.Component {
  state = {
    playing: false,
    mediaHasLoaded: false,
  }

  videoPlayer = React.createRef()

  componentDidMount() {
    window.addEventListener('message', this.handleMessageFromReactNative)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageFromReactNative)
  }

  // We emulate standard flux style messages over the WebView bridge.
  handleMessageFromReactNative = (event) => {
    try {
      const { type, payload } = JSON.parse(event.data)

      switch (type) {
        case 'SET_PLAYING':
          this.setState({ playing: payload })
          break
        case 'SEEK_VIDEO':
          this.videoPlayer.current.seekTo(payload, 'seconds')
          break
        case undefined:
          break
        default:
          console.error(`${type} is not an action that we support.`)
      }
    } catch (e) {
      console.log('Event.data could not be JSON-parsed.')
    }
  }

  sendMessageToReactNative = (type, payload = null) => {
    try {
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }))
    } catch (e) {
      console.error(
        'window.ReactNativeWebView is not valid, cannot send message',
        e
      )
    }
  }

  // creates a link with the video id and cropped times
  createUrl = () => {
    const {
      media_url: mediaUrl,
      media_kind: mediaKind,
      start_time: startTime,
      end_time: endTime,
    } = this.props.media
    if (mediaKind === 'youtube') {
      return `https://www.youtube.com/v/${getYoutubeID(
        mediaUrl
      )}?start=${startTime}&end=${endTime}`
    }
    return mediaUrl
  }

  determineBackgroundColor = () => {
    if (this.props.media.media_kind === 'audio-file') {
      return this.state.mediaHasLoaded ? 'rgba(166, 192, 198, 0.08)' : 'white'
    }
    return 'black'
  }

  render() {
    // Hide the video player when playing an audio file.
    const hideVideoPlayer = this.props.media.media_kind === 'audio-file'

    const backgroundColor = this.determineBackgroundColor()
    return (
      // Peculiar padding is needed so that the video is scaled properly
      // (https://www.npmjs.com/package/react-player#responsive-player)
      <Box
        css={`
          position: relative;
          padding-top: 56.25%;
          background-color: ${backgroundColor};
        `}
        onClick={() => {
          this.setState({ playing: !this.state.playing }, () => {
            this.sendMessageToReactNative('SET_PLAYING', this.state.playing)
          })
        }}
      >
        {hideVideoPlayer && (
          <Flex
            alignItems="center"
            justifyContent="center"
            css={`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 99;
            `}
          >
            <Image src={speaker} />
          </Flex>
        )}
        <ReactPlayer
          ref={this.videoPlayer}
          url={this.createUrl()}
          width="100%"
          height="100%"
          controls={false}
          progressInterval={300}
          playing={this.state.playing}
          playsinline
          onReady={() => this.setState({ mediaHasLoaded: true })}
          onDuration={(durationSeconds) =>
            this.sendMessageToReactNative('SET_DURATION', durationSeconds)
          }
          onProgress={({ playedSeconds }) =>
            this.sendMessageToReactNative('SET_TIME_PLAYED', playedSeconds)
          }
          onEnded={() => this.sendMessageToReactNative('HAS_ENDED')}
          onError={() => this.sendMessageToReactNative('ERROR')}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
          }}
        />
      </Box>
    )
  }
}

MediaPlayback.propTypes = {
  media: PropTypes.object.isRequired,
}

export default MediaPlayback
