import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import tutorImage from './images/tutor@2x.png'

const TutorBanner = ({ onClose }) => {
  return (
    <SC.Container>
      <SC.Header>
        <SC.Image src={tutorImage} />
        <SC.Close onClick={onClose}>
          {'hide'} <i className="fa fa-times" />
        </SC.Close>
      </SC.Header>
      <SC.Title>{'Please fill out your Tutor Profile!'}</SC.Title>
      <SC.Content>
        {
          "Check out the new Community Forum and don't forget to fill out your Tutor Profile, which can be done in the ‘Tutor Introductions’ Topic! You have until August 30th to fill out your profile before your level will be held at Level 1."
        }
        <br />
        <a href="https://chatterbug.com/community/t/tutor-chatter-news-july-2019/141">
          {'More details here!'}
        </a>
      </SC.Content>
    </SC.Container>
  )
}

const SC = {}
SC.Container = styled.div`
  position: relative;
  margin: 50px auto;
  width: 470px;
  text-align: center;
`
SC.Header = styled.div``
SC.Close = styled.a`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`
SC.Image = styled.img`
  width: 80px;
  height: 80px;
  border: 4px solid black;
  border-radius: 999px;
`
SC.Title = styled.h2`
  font-size: 26px;
`
SC.Content = styled.div``
SC.Arrow = styled.img`
  position: absolute;
  left: 20px;
  bottom: -76px;
`

TutorBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default TutorBanner
