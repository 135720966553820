import { Controller } from 'stimulus'

// wrap this around something with a video element in it for some contolling helpers
// - seeking and playing clips
export default class VideoMediaController extends Controller {
  static targets = ['start', 'end', 'video']

  mmssToSeconds(mmss) {
    let parts = mmss.split(':');
    return parseInt(parts[0]) * 60 + parseInt(parts[1]);
  }

  playClip(e) {
    e.preventDefault()
    let startAt = this.mmssToSeconds(this.startTarget.value)
    let endAt = this.mmssToSeconds(this.endTarget.value)
    let duration = endAt - startAt
    this.videoTarget.currentTime = startAt
    this.videoTarget.play()
    setTimeout(this.pauseVideo.bind(this), duration * 1000)
  }

  pauseVideo() {
    this.videoTarget.pause()
  }

  seekTo(e) {
    let time = e.target.dataset.time
    this.videoTarget.currentTime = time
    this.videoTarget.play()
  }
}
