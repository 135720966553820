import React from 'react'
import Cookie from 'js-cookie'

import { Flex, Box, Card, Text, Image } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import Bear from './bear_pc.svg'

// Display a mobile warning to users doing live lessons on mobile using the webapp
export default class MobileWarning extends React.Component {
  state = {
    show: true,
  }

  UNSAFE_componentWillMount() {
    const show = Cookie.getJSON('mobile-message') !== false
    this.setState({ show })
  }

  handleClick = (e) => {
    e.preventDefault()
    Cookie.set('mobile-message', false)
    this.setState({ show: false })
  }

  render() {
    const { show } = this.state
    if (!show) return null

    return (
      <Card
        bg="backgrounds.yellow"
        p="md"
        minHeight="70px"
        borderBottom="2px solid rgba(206,170,0,0.3)"
        display={{ sm: 'block', md: 'none', lg: 'none' }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Image src={Bear} />

          <Box p="md" fontSize="md" textAlign="center">
            {t(
              'Login on your laptop or desktop to use all chatterbug.com features!'
            )}
          </Box>

          <Box>
            <a href="#" onClick={this.handleClick}>
              <Text color="yellows.1">
                <i className="fa fa-close" />
              </Text>
            </a>
          </Box>
        </Flex>
      </Card>
    )
  }
}
