import React, { useState } from 'react'
import PropTypes from 'prop-types'

import routes from 'src/lib/rails-routes/rails-routes'
import { t } from 'src/lib/i18n-react'
import { Box, Flex, Button } from '@chatterbug/aaron'
import { post } from 'lib/util'

import PrivacySelection from './PrivacySelection/PrivacySelection'

const StudentPrivacySetting = ({ redirectUrl }) => {
  const [privacy, setPrivacy] = useState('research_use_recording')

  const handlePrivacyChange = (value) => {
    setPrivacy(value)
  }

  const handleSubmit = () => {
    post('/account/privacy', {
      recording_privacy_preference: privacy,
    }).then(({ _, status }) => {
      if (status === 'success') {
        if (redirectUrl) {
          window.location.href = redirectUrl
        } else {
          window.location.href = routes.schedule_index()
        }
      }
    })
  }

  return (
    <Flex
      flexDirection="column"
      css={`
        padding-bottom: 40px;
      `}
    >
      <Box
        flex={1}
        height="100%"
        css={`
          background-position: left bottom;
          background-size: 400px;
          background-repeat: no-repeat;
        `}
      >
        <Box maxWidth="960px" m="0 auto" height="100%">
          <PrivacySelection onChange={handlePrivacyChange} value={privacy} />
          <Flex justifyContent="center">
            <Button
              type="cta"
              fill="solid"
              onClick={handleSubmit}
              disableWith={t('Saving privacy setting')}
            >
              {'Save Privacy Setting'}
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

StudentPrivacySetting.propTypes = {
  redirectUrl: PropTypes.string,
}

export default StudentPrivacySetting
