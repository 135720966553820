import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SVG from 'svg.js'

const SIZE = 175
const CENTER = SIZE / 2
const NUM_DOTS = 27
const NUM_STATS = 3
const DOT_RADIUS = 12
const DOTS_PER_STAT = NUM_DOTS / NUM_STATS

class StarPower extends React.Component {
  componentDidMount = () => {
    const draw = SVG(this.starRef).size(SIZE, SIZE)
    const stats = this.props.stats.split(',')
    const statDots = stats.map((x) => Math.floor(parseFloat(x) * DOTS_PER_STAT))

    for (var i = 0; i < NUM_DOTS; i++) {
      var statIndex = Math.floor(i / DOTS_PER_STAT)

      var state =
        i - statIndex * DOTS_PER_STAT < statDots[statIndex] ? 'on' : 'off'

      var c = draw.circle(DOT_RADIUS).attr({
        fill: 'gray',
        class: 'stat' + statIndex + ' ' + state,
      })

      c.cx(CENTER + 80.7 * Math.cos((i / NUM_DOTS) * 2 * Math.PI - Math.PI / 2))
      c.cy(CENTER + 80.7 * Math.sin((i / NUM_DOTS) * 2 * Math.PI - Math.PI / 2))
    }

    if (statDots[0] + statDots[1] + statDots[2] === NUM_DOTS) {
      this.starRef.querySelectorAll('i').forEach((el) => el.classList.add('on'))
    }
  }

  render() {
    return (
      <SC.Star
        ref={(el) => {
          this.starRef = el
        }}
      >
        <i className="fa fa-star" />
      </SC.Star>
    )
  }
}

const SC = {}
SC.Star = styled.div`
  width: 175px;
  height: 175px;
  position: relative;
  circle {
    fill: #f0f0f0;
  }
  i {
    font-size: 100px;
    position: absolute;
    left: 41px;
    top: 36px;
    color: #d8d8d8;
  }
  .on {
    color: #feda32;
    fill: #feda32;
  }
`

StarPower.propTypes = {
  stats: PropTypes.string.isRequired,
}

export default StarPower
