import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const CharacterPicker = ({ characters, onSelect, selectedIndex, onRef }) => {
  return (
    <div className="special-character-picker" ref={onRef}>
      <ol>
        {characters.map((character, index) => (
          <li
            key={`special-character-${index}`}
            className={`special-character ${selectedIndex === index &&
              'special-character--selected'}`}
            onClick={(e) => {
              onSelect(e, character, index)
            }}
          >
            {character}
          </li>
        ))}
      </ol>
      <div className="arrow" />
    </div>
  )
}

CharacterPicker.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRef: PropTypes.any,
}

export default CharacterPicker
