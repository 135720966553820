import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import ChooseLanguagePage from './subcomponents/ChooseLanguagePage/ChooseLanguagePage'
import InterestFormPage from './subcomponents/InterestFormPage/InterestFormPage'
import ThanksPage from './subcomponents/ThanksPage/ThanksPage'

class UnknownL1Form extends React.Component {
  state = { pageIndex: 0 }

  handleNextPage = () => {
    const { pageIndex } = this.state
    this.setState({ pageIndex: pageIndex + 1 })
  }

  handleLanguageSelect = (code) => {
    const { onResumeSignup, languageCode } = this.props

    if (code === 'other') {
      this.handleNextPage()
    } else {
      onResumeSignup(code, languageCode)
    }
  }

  getPage = (pageIndex, props) =>
    ({
      0: (
        <ChooseLanguagePage
          {...props}
          onLanguageSelect={this.handleLanguageSelect}
        />
      ),
      1: <InterestFormPage {...props} onNext={this.handleNextPage} />,
      2: <ThanksPage {...props} />,
    }[pageIndex])

  render() {
    const { languages, languageCode } = this.props
    const { pageIndex } = this.state

    const targetLanguage = languages.find((lang) => lang.code === languageCode)
    const content = this.getPage(pageIndex, { ...this.props, targetLanguage })

    if (pageIndex === 0) {
      return content
    }

    return (
      <SC.CharactersBehind>
        <div className="signup-form">
          <div className="signup-form__container">
            <SC.WaitListContainer>{content}</SC.WaitListContainer>
          </div>
        </div>
      </SC.CharactersBehind>
    )
  }
}

const SC = {}

SC.CharactersBehind = styled.div`
  .signup-form {
    margin-top: 96px;
    ::before {
      content: '';
      position: absolute;
      z-index: 0;
      display: block;
      height: 200px;
      width: 400px;
      top: 72px;
      left: 50%;
      margin-left: -200px;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      background-image: url(${require(`./images/all-characters-with-flags.svg`)});
    }
  }
  @media screen and (max-width: 375px) {
    .signup-form {
      margin-top: 0px;
      ::before {
        display: none;
      }
    }
  }
`
SC.WaitListContainer = styled.div`
  margin: 20px 30px 20px 30px;
  text-align: center;
`

UnknownL1Form.propTypes = {
  withOther: PropTypes.bool,
  languages: PropTypes.array.isRequired,
  languageCode: PropTypes.string,
  onResumeSignup: PropTypes.func.isRequired,
}

export default UnknownL1Form
