import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button as AButton } from '@chatterbug/aaron'
import { Button } from '@chatterbug/theme'

import { t, DateTime } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import Avatar from 'src/components/Avatar/Avatar'
import Flag from 'src/components/Flag/Flag'
import Pill from 'src/components/Pill/Pill'
import LiveDate from 'src/components/LiveDate'

import Icon from './subcomponents/Icon/Icon'

const Item = (props) => {
  const {
    status,
    avatarURL,
    languageCode,
    nativeLanguageCode,
    login,
    timezone,
    start,
    finish,
    isPreviewable,
    isTraining,
    isMine,
    actions,
  } = props

  const renderActions = () => {
    if (isMine) return null

    if (status === 'pending_me') {
      return (
        <div style={{ display: 'flex' }}>
          <AButton
            type="success"
            size="small"
            disableWith="Confirming..."
            onClick={() => actions.confirm(props)}
          >
            {t('Confirm')}
          </AButton>
          <AButton
            type="danger"
            size="small"
            onClick={() => actions.reject(props)}
          >
            {t('Reject')}
          </AButton>
        </div>
      )
    }

    if (status === 'confirmed' && isPreviewable) {
      return (
        <AButton
          type="info"
          size="small"
          disableWith={t('Previewing...')}
          onClick={() => actions.preview(props)}
        >
          {t('Preview')}
        </AButton>
      )
    }

    if (status === 'past') {
      return (
        <AButton
          type="info"
          size="small"
          disableWith={t('Viewing...')}
          onClick={() => actions.view(props)}
        >
          {t('View')}
        </AButton>
      )
    }

    if (status === 'bookable') {
      return (
        <AButton
          type="info"
          size="small"
          disableWith={t('Booking...')}
          onClick={() => actions.book(props)}
        >
          {t('Book It')}
        </AButton>
      )
    }

    if (status === 'confirmed' || status === 'pending_partner') {
      return (
        <Button
          as="submit"
          kind="tertiary"
          onClick={() => actions.cancel(props)}
        >
          {t('cancel')}
        </Button>
      )
    }
  }

  return (
    <tr>
      <SC.Left>
        <Icon status={status} isMine={isMine} />
        {languageCode && (
          <Flag
            countryCode={languageCode}
            size="xxxs"
            style={{ position: 'absolute', top: -2, right: -4 }}
          />
        )}
      </SC.Left>
      <SC.Date>
        <LiveDate date={start} format="cb_dddMMMD" timezone={timezone} />
      </SC.Date>
      <SC.Time>
        <LiveDate date={start} format="cb_hmma" timezone={timezone} />
      </SC.Time>
      <SC.Time>
        <span>
          {(DateTime.fromISO(finish) - DateTime.fromISO(start)) / (1000 * 60)}{' '}
          {t('min')}
        </span>
        {isTraining && <Pill color="purple">{t('Training')}</Pill>}
      </SC.Time>
      <SC.User>
        {isMine && (
          <div>
            <Avatar url={avatarURL} size="s" />
            <SC.MyLogin>{t('You')}</SC.MyLogin>
          </div>
        )}
        {!isMine && login && (
          <div>
            <Avatar
              url={avatarURL}
              size="s"
              languageCode={nativeLanguageCode}
            />
            <SC.Login>
              <a href={routes.profile(login)}>
                <SC.LoginLink>{login}</SC.LoginLink>
              </a>
            </SC.Login>
          </div>
        )}
      </SC.User>
      <SC.Right>{renderActions()}</SC.Right>
    </tr>
  )
}

const SC = {}
SC.Cell = styled.td`
  font-size: ${(props) => props.theme.fontSizes[1]};
`
SC.Left = styled(SC.Cell)`
  position: relative;
  white-space: nowrap;
`
SC.Date = styled(SC.Cell)`
  padding-left: 20px;
  white-space: nowrap;
`
SC.Time = styled(SC.Cell)`
  padding-left: 20px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.cadetBlues[1]};

  div {
    margin-left: 8px;
  }
`
SC.User = styled(SC.Cell)`
  padding-left: 50px;
  width: 100%;
  div {
    display: flex;
  }
  div div {
    position: relative;
    top: 2px;
  }
`
SC.Login = styled.span`
  padding-left: 6px;
`
SC.LoginLink = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 124px;
`
SC.MyLogin = styled(SC.Login)`
  color: ${(props) => props.theme.colors.blacks[0]};
`
SC.Right = styled(SC.Cell)`
  text-align: right;
  white-space: nowrap;

  button,
  a {
    margin-left: 10px;
  }
`

Item.defaultProps = {
  isPreviewable: false,
  isTraining: false,
  isMine: false,
}

Item.propTypes = {
  timezone: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  finish: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    'pending_me',
    'pending_partner',
    'confirmed',
    'canceled',
    'bookable',
    'past',
    'other',
  ]).isRequired,
  actions: PropTypes.shape({
    view: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    preview: PropTypes.func.isRequired,
    book: PropTypes.func.isRequired,
  }).isRequired,
  login: PropTypes.string,
  avatarURL: PropTypes.string,
  languageCode: PropTypes.string,
  nativeLanguageCode: PropTypes.string,
  isPreviewable: PropTypes.bool,
  isTraining: PropTypes.bool,
  isMine: PropTypes.bool,
}

export default Item
