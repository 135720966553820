import React from 'react'
import PropTypes from 'prop-types'
import { injectStripe } from 'react-stripe-elements'

class CanMakePaymentRequest extends React.Component {
  state = { canMakePaymentRequest: null }

  async componentDidMount() {
    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const { paymentRequest, stripe } = this.props
    // only returns true on Safari 10.1+ (desktop and mobile), Chrome 61+
    // (desktop and mobile), and Microsoft Edge 16.16299+ when the customer has a
    // card already saved with Apple Pay, Microsoft Pay, or their browser.
    const canMakePaymentRequest = await stripe
      .paymentRequest(paymentRequest)
      .canMakePayment()

    this.setState({ canMakePaymentRequest })
  }

  render() {
    if (this.state.canMakePaymentRequest === null) {
      return this.props.no()
    } else {
      return this.props.yes()
    }
  }
}

CanMakePaymentRequest.propTypes = {
  yes: PropTypes.func.isRequired,
  no: PropTypes.func.isRequired,
}

export default injectStripe(CanMakePaymentRequest)
