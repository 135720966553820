import React from 'react'
import PropTypes from 'prop-types'

function round(n, places) {
  return +(Math.round(n + 'e+' + places) + 'e-' + places)
}

export default class AudioMeter extends React.Component {
  static defaultProps = {
    audioLevel: 0,
  }

  static propTypes = {
    audioLevel: PropTypes.number.isRequired,
  }

  // Use Tokbox's volume analyzer to determine height
  // via https://tokbox.com/developer/sdks/js/reference/Subscriber.html#.event:audioLevelUpdated
  calculateVolume(audioLevel) {
    if (this.movingAvg == null || this.movingAvg <= audioLevel) {
      this.movingAvg = audioLevel
    } else {
      this.movingAvg = 0.7 * this.movingAvg + 0.3 * audioLevel
    }

    // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
    const logLevel = Math.log(this.movingAvg) / Math.LN10 / 1.5 + 1
    const level = Math.min(Math.max(logLevel, 0), 1)

    // Round to the nearest 0.1, then multiply by 100. This has the effect of
    // "bucketing" the volume level to 10%, 20%, 30%, etc so that there are only
    // 11 possible levels.
    //
    // We do this so that when the volume changes a very small amount, we will end
    // up rendering a 100% identical component, which will make React ignore the
    // render
    return round(level, 1) * 100
  }

  render() {
    const { audioLevel } = this.props
    const volume = this.calculateVolume(audioLevel)
    return (
      <div className="audiometer">
        <div
          className="audiometer__bar"
          style={{
            transition: 'clip-path 50ms ease-in-out 50ms',
            clipPath: `inset(${100 - volume}% 0 0 0)`,
            height: '100%',
          }}
        />
      </div>
    )
  }
}
