import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Cookies from 'js-cookie'

import routes from 'src/lib/rails-routes/rails-routes'
import TutorLevel from 'src/components/TutorLevel/TutorLevel'
import AppointmentModifyConfirmation from 'src/components/AppointmentModifyConfirmation/AppointmentModifyConfirmation'
import { DateTime } from 'src/lib/i18n-react'
import { get, post, redirectToHREF } from 'src/lib/util'
import PageAlert from 'src/components/PageAlert'

import TutorBanner from './subcomponents/TutorBanner/TutorBanner'
import Appointments from './subcomponents/Appointments/Appointments'
import NextLiveLesson from './subcomponents/NextLiveLesson/NextLiveLesson'
import PaymentMethodWarning from './subcomponents/PaymentMethodWarning/PaymentMethodWarning'
import RecordPermissionWarning from './subcomponents/RecordPermissionWarning/RecordPermissionWarning'

const UPDATE_RATE = 60000 // 60 seconds
const BANNER_COOKIE = 'user.tutor.banner.1'

class DashboardTutorPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      modalProps: undefined,
    }
    this.calendarActions = this.calendarActions()
  }

  UNSAFE_componentWillMount() {
    this.timeinterval = window.setInterval(() => this.updateData(), UPDATE_RATE)
  }

  componentWillUnmount() {
    clearInterval(this.timeinterval)
  }

  updateData = () => {
    get(routes.home_tutor(), 'json').then(({ data }) => {
      this.setState(data.data)
    })
  }

  // Appointment actions

  calendarActions = () => {
    const reject = (appointment) =>
      this.setState({
        modalProps: {
          status: 'reject',
          appointment,
        },
      })

    const cancel = (appointment) =>
      this.setState({
        modalProps: {
          status: 'cancel',
          appointment,
          isStudent: false,
          tutorConfirmed: false,
          onCancelAppointment: (reason, appointment) => {
            post(
              routes.appointment_modify(appointment.id, 'cancel'),
              {
                reason: reason,
              },
              'json'
            ).then(({ data }) => {
              this.updateAppointment(
                { ...appointment, ...data.appointment },
                { text: data.notice }
              )
            })
          },
        },
      })

    const confirm = (appointment) => {
      post(
        routes.appointment_modify(appointment.id, 'confirm'),
        {},
        'json'
      ).then(({ data }) => {
        this.updateAppointment(
          { ...appointment, ...data.appointment },
          { text: data.notice }
        )
      })
    }

    const book = (appointment) => {
      const time = DateTime.fromISO(appointment.start).toMillis() / 1000
      post(
        routes.appointment_book(),
        {
          appointment_id: appointment.id,
          time: time,
          minutes: 45,
          booking_type: 'tutor',
        },
        'json'
      ).then(({ data }) => {
        if (data.status === 'ok') {
          this.updateAppointment(
            { ...appointment, ...data.appointment },
            { text: data.notice }
          )
        } else {
          PageAlert.showAlert({
            type: 'danger',
            fixed: true,
            content: <p>{data.notice}</p>,
          })
        }
      })
    }

    const view = ({ languageCode, sessionId }) =>
      redirectToHREF(routes.chat_show(languageCode, sessionId))

    const preview = ({ languageCode, sessionId }) =>
      redirectToHREF(routes.chat_show_preview(languageCode, sessionId))

    return {
      view,
      confirm,
      reject,
      cancel,
      preview,
      book,
    }
  }

  updateAppointment = (appointment, notice) => {
    this.hideModal()

    const { upcomingAppointments } = this.state
    const { appointments, compactTimezone } = upcomingAppointments

    const newAppointments = appointments.slice()

    const index = _.findIndex(appointments, (x) => x.id === appointment.id)
    if (index !== -1) {
      newAppointments[index] = appointment
    }

    this.setState({
      upcomingAppointments: {
        appointments: newAppointments,
        compactTimezone: compactTimezone,
      },
    })

    PageAlert.showAlert({
      type: 'info',
      fixed: true,
      content: <p>{notice.text}</p>,
    })
  }

  // Actions modal

  hideModal = () => this.setState({ modalProps: undefined })

  showModal = () => {
    if (typeof this.state.modalProps === 'undefined') {
      return null
    }

    return (
      <AppointmentModifyConfirmation
        isOpen
        onRequestClose={this.hideModal}
        onSubmit={this.updateAppointment}
        {...this.state.modalProps}
      />
    )
  }

  // Banner actions

  hideBanner = () => {
    this.setState({ showBanner: false })
    Cookies.set(BANNER_COOKIE, false)
  }

  // Render

  render() {
    const {
      tutorLevel,
      upcomingAppointments,
      nextLiveLesson,
      paymentMethod,
      hasRecordPermission,
    } = this.props

    return (
      <SC.Container>
        {typeof this.state.modalProps !== 'undefined' && this.showModal()}

        {this.state.showBanner && <TutorBanner onClose={this.hideBanner} />}

        <SC.Panel>
          <TutorLevel {...tutorLevel} />
        </SC.Panel>

        {!paymentMethod && (
          <SC.Panel>
            <PaymentMethodWarning />
          </SC.Panel>
        )}

        {!hasRecordPermission && (
          <SC.Panel>
            <RecordPermissionWarning />
          </SC.Panel>
        )}

        {nextLiveLesson && (
          <SC.Panel>
            <NextLiveLesson {...nextLiveLesson} />
          </SC.Panel>
        )}

        <SC.Panel>
          <Appointments
            upcomingAppointments={upcomingAppointments}
            actions={this.calendarActions}
          />
        </SC.Panel>
      </SC.Container>
    )
  }
}

const SC = {}
SC.Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`
SC.Panel = styled.div`
  margin: 50px 0 100px 0;
`

// Bring this back when we have a new banner. For now just hide!
DashboardTutorPage.defaultProps = {
  showBanner: false, // Cookies.get(BANNER_COOKIE) !== 'false',
}

// NOTE: All props set initial state only and are then updated on an interval.
DashboardTutorPage.propTypes = {
  showBanner: PropTypes.bool,
  tutorLevel: PropTypes.object.isRequired,
  upcomingAppointments: PropTypes.object.isRequired,
  nextLiveLesson: PropTypes.object,
  paymentMethod: PropTypes.string,
  hasRecordPermission: PropTypes.bool.isRequired,
}

export default DashboardTutorPage
