import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SC = {}
SC.Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

export default class MaxLines extends React.Component {
  static propTypes = {
    lines: PropTypes.number.isRequired,
    children: PropTypes.node,
  }

  state = {
    maxHeight: 'unset',
  }

  componentDidMount() {
    if (this.ref) {
      const lineHeight = parseFloat(
        window.getComputedStyle(this.ref)['line-height']
      )
      this.setState({
        maxHeight: lineHeight * this.props.lines,
      })
    }
  }

  render() {
    const { maxHeight } = this.state
    return (
      <SC.Container ref={(el) => (this.ref = el)} style={{ maxHeight }}>
        {this.props.children}
      </SC.Container>
    )
  }
}
