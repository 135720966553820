import React from 'react'

export default class LoadPageNewSubscription extends React.Component {
  state = {
    loading: true,
  }

  async componentDidMount() {
    const { default: Page } = await import(
      /* webpackChunkName: "NewSubscriptionPage" */ 'src/pages/NewSubscription/NewSubscription'
    )
    this.Page = Page
    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) {
      return null
    }
    return <this.Page {...this.props} />
  }
}
