import { Controller } from 'stimulus'
import * as UpChunk from '@mux/upchunk'

export default class DirectUploadsController extends Controller {
  static targets = ['uploadIdField', 'fileField', 'submitButton', 'loader']
  static values = { url: String, id: String }

  upload(event) {
    let url = this.urlValue
    const picker = event.target.files[0]
    const upload = UpChunk.createUpload({
      endpoint: url,
      file: picker,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    })
    upload.on('error', (err) => {
      this.loaderTarget.innerHTML = `${err.detail}`
    })

    upload.on('progress', (progress) => {
      if (progress.detail < 100) {
        this.submitButtonTarget.disabled = true
      }
      this.loaderTarget.innerHTML = `${Math.floor(progress.detail)}%`
    })

    upload.on('success', (err) => {
      this.uploadIdFieldTarget.disabled = false
      this.fileFieldTarget.disabled = true
      this.submitButtonTarget.disabled = false
    })
  }
}
