import { Controller } from 'stimulus'
import OT from '@opentok/client'

export default class StudyGroupController extends Controller {
  static values = { apiKey: String, sessionId: String, token: String, state: String }
  static targets = [ "showings", "audioList", "videoList", "chatArea", "chatInput", "deviceLists" ]

  connect() {
    if(this.sessionIdValue && this.stateValue == 'active') {
      this.setupSession()
    }
  }

  setupSession() {
    let session = OT.initSession(this.apiKeyValue, this.sessionIdValue);

    // Create a publisher
    let publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '200px',
      height: '200px'
    }, this.handleError);
    publisher.setStyle('audioLevelDisplayMode', 'on');

    // Connect to the session
    session.connect(this.tokenValue, function(error) {
      // If the connection is successful, publish to the session
      if (error) {
        console.log(error)
      } else {
        session.publish(publisher, console.log);
      }
    })

    session.on('streamCreated', function(event) {
      let userData = JSON.parse(event.stream.connection.data)
      let stream = event.stream
      let sub = session.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '150px',
        height: '150px'
      }, console.log);

      /* add the user name to the subscriber div */
      let node = document.createElement("div");
      node.classList.add("club-user-name")
      node.innerText = userData.identity;
      document.getElementById(sub.id).appendChild(node);

      /* Mute Button, Kick Ban */
      let mnode = document.createElement("div");
      mnode.classList.add("moderator-controls", "hidden")

      /* force mute */
      var knode = document.createElement("div");
      knode.classList.add("mute-button")
      knode.innerText = "force mute"
      knode.addEventListener("click", function() { session.forceMuteStream(stream) });

      /* kickban */
      var kbnode = document.createElement("div");
      kbnode.classList.add("kick-ban")
      kbnode.innerText = "kick"
      kbnode.addEventListener("click", function() {
        session.forceUnpublish(stream, function (error) {
          if (error) {
              console.log(error);
            } else {
              console.log("Connection forced to disconnect");
              mnode.remove()
              // we should probably enforce this on the server too
            }
        })
      });

      mnode.appendChild(knode);
      mnode.appendChild(kbnode);
      document.getElementById(sub.id).insertAdjacentElement("afterend", mnode);
    })

    this.session = session
    this.publisher = publisher

    this.setDevices()
  }

  setDevices() {
    OT.getDevices((err, devices) => {
      let audioInputs = devices.filter((device) => device.kind === 'audioInput')
      let videoInputs = devices.filter((device) => device.kind === 'videoInput')

      /* set the audio inputs */
      const audioBox = this.audioListTarget
      audioBox.innerHTML = '<option>-- change audio--</option>'
      audioInputs.forEach((device, idx) => {
        const opt = document.createElement('option');
        opt.value = device.deviceId;
        opt.innerHTML = device.label
        audioBox.appendChild(opt);
      })

      /* set the video inputs */
      const videoBox = this.videoListTarget
      videoBox.innerHTML = '<option>-- change video--</option>'
      videoInputs.forEach((device, idx) => {
        const opt = document.createElement('option');
        opt.value = device.deviceId;
        opt.innerHTML = device.label
        videoBox.appendChild(opt);
      })
    })
  }

  changeDevices() {
    this.deviceListsTarget.classList.toggle('hidden')
  }

  modTools() {
    let controls = document.getElementsByClassName("moderator-controls")
    controls.forEach(element => element.classList.toggle('hidden'))
  }

  changeAudio() {
    let deviceId = this.audioListTarget.value
    this.publisher.setAudioSource(deviceId)
  }

  changeVideo() {
    let deviceId = this.videoListTarget.value
    this.publisher.setVideoSource(deviceId)
  }

  chatSend() {
    console.log("WHY WONT THIS FIRE")
  }

  clearMessage() {
    // this is insanely stupid, but for whatever reason, I can't get the
    // ajax:complete DOM event to fire, but if I clear this too fast on click
    // then the message is cleared before the form is submitted.
    setTimeout(() => {
      this.chatInputTarget.value = ''
    }, 100);
  }

  endSession() {
    this.session.disconnect()
  }

  handleError(error) {
    if (error) {
      alert(error.message)
    }
  }
}
