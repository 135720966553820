import React from 'react'
import PropTypes from 'prop-types'
import { t } from 'src/lib/i18n-react'

import SignupForm from './subcomponents/SignupForm/SignupForm'
import LanguageSetter from '../LanguageSetter/LanguageSetter'

const LANGUAGE_SETTER_PAGE = 'LANGUAGE_SETTER_PAGE'
const SIGNUP_FORM_PAGE = 'SIGNUP_FORM_PAGE'

class SignupPage extends React.Component {
  constructor(props) {
    super()
    this.state = {
      pageIndex: LANGUAGE_SETTER_PAGE,
      nativeLanguageCodes: props.nativeLanguageCodes,
    }
    window.onpopstate = (_event) => {
      this.handleGoBack()
    }
  }

  handleGoBack = () => {
    window.history.pushState({}, '', `/signup/`)
    this.setState({
      pageIndex: LANGUAGE_SETTER_PAGE,
      selectedLanguageCode: undefined,
      nativeLanguageCodes: this.props.nativeLanguageCodes,
    })
  }

  handleSelectLanguage = (
    languageCode,
    localizedName,
    nativeLanguageCodes,
    index
  ) => {
    this.setState({
      languageCode: languageCode,
      localizedName: localizedName,
      nativeLanguageCodes: nativeLanguageCodes,
    })
    if (index) {
      this.setState({ pageIndex: index })
    }
  }

  render() {
    const { languages, isMissingL1, role, selectedLanguageCode } = this.props

    const {
      languageCode,
      localizedName,
      pageIndex,
      nativeLanguageCodes,
    } = this.state

    return (
      <div className="l-signup-page">
        {pageIndex === LANGUAGE_SETTER_PAGE && (
          <LanguageSetter
            languages={languages}
            nativeLanguageCodes={nativeLanguageCodes}
            isMissingL1={isMissingL1}
            role={role}
            onSelectLanguage={this.handleSelectLanguage}
            // the optional passed L2 from backend, used to set L2 without selecting and skip the first step
            initialSelectedLanguageCode={selectedLanguageCode}
            // the one user selects in the UI
            selectedLanguageCode={languageCode}
            onGoBack={this.handleGoBack}
            withOther
            withSignup
          />
        )}
        {pageIndex === SIGNUP_FORM_PAGE && (
          <div>
            {languageCode !== 'other' && (
              <h2 className="l-signup-page__title">
                {t('Create your account')}
              </h2>
            )}
            <SignupForm
              {...this.props}
              languageCode={languageCode}
              nativeLanguageCode={nativeLanguageCodes}
              localizedName={localizedName}
            />
          </div>
        )}
      </div>
    )
  }
}

SignupPage.defaultProps = {
  buttonText: t('Sign Up'),
}

SignupPage.propTypes = {
  buttonText: PropTypes.string,
  offerCode: PropTypes.string,
  hideLogin: PropTypes.bool,
  email: PropTypes.string,
  inviteCode: PropTypes.string,
  isMissingL1: PropTypes.bool,
  nativeLanguageCodes: PropTypes.string,
  selectedLanguageCode: PropTypes.string,
  role: PropTypes.string,
  orgId: PropTypes.string,
  orgCode: PropTypes.string,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      localizedName: PropTypes.string.isRequired,
      pairedNativeLanguages: PropTypes.array,
    })
  ).isRequired,
}

export default SignupPage
