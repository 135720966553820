import React, { useState } from 'react'

import { Link } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import AddPaymentSourceModal from './AddPaymentSourceModal'

export type AddPaymentSourceProps = {
  allowSEPA: boolean
  currency: string
  organization?: number
}

const AddPaymentSource: React.FC<AddPaymentSourceProps> = ({
  allowSEPA,
  currency,
  organization,
}) => {
  const onClick = () => {
    setModalOpen(true)
  }

  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <Link disableTime={1} onClick={onClick}>
        {t('add new payment method')}
      </Link>
      <AddPaymentSourceModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        allowSEPA={allowSEPA}
        currency={currency}
        organization={organization}
      />
    </>
  )
}
export default AddPaymentSource
