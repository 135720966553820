import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chatterbug/aaron'

import Flag from 'src/components/Flag/Flag'
import { getLanguageName } from 'src/lib/util'

const LanguageButton = ({ code, onClick, children }) => {
  const handleClick = () => {
    onClick?.(code)
  }

  return (
    <Box
      as="button"
      onClick={handleClick}
      sx={{
        padding: '0 24px 0 6px',
        borderRadius: '40px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'gray88',
        backgroundColor: 'white100',
        transition: 'background 0.3s ease',
        ':hover, :active, :focus': {
          backgroundColor: 'gray99',
        },
      }}
    >
      <Flex alignItems="center" height={36} width={196}>
        <Box flexShrink={0} fontSize={0}>
          <Flag size="middle" countryCode={code} />
        </Box>
        <Box
          flexGrow={1}
          variant="text.paragraph"
          textAlign="center"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {children || getLanguageName(code)}
        </Box>
      </Flex>
    </Box>
  )
}

LanguageButton.propTypes = {
  code: PropTypes.oneOf(['de', 'fr', 'es', 'en', 'other']),
  onClick: PropTypes.func,
}

export default LanguageButton
