import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DateTime } from 'luxon'

export const convertTimeToMinutes = (time) => {
  const { hour, minute } = DateTime.fromISO(time)
  return hour * 60 + minute
}

export const calculateEventDimensionsFromTime = (start, finish) => {
  const top = convertTimeToMinutes(start)
  const bottom = convertTimeToMinutes(finish)

  return {
    top,
    height: bottom - top,
  }
}

class DayView extends React.Component {
  render() {
    const {
      hourHeight,
      appointments,
      renderAppointment,
      ...others
    } = this.props

    return (
      <SC.Container hourHeight={hourHeight} {...others}>
        {appointments.map(({ start, finish, ...others }) => {
          const dimensions = calculateEventDimensionsFromTime(start, finish)

          return (
            <SC.Appointment key={`event-${start}-${finish}`} style={dimensions}>
              {renderAppointment({
                start,
                finish,
                inline: dimensions.height <= 30,
                ...others,
              })}
            </SC.Appointment>
          )
        })}
      </SC.Container>
    )
  }
}

const SC = {}
SC.Container = styled.div`
  height: ${({ hourHeight }) => hourHeight * 24}px;
  position: relative;
  background: repeating-linear-gradient(
    transparent,
    transparent ${({ hourHeight }) => hourHeight - 1}px,
    rgba(0, 0, 0, 0.1) ${({ hourHeight }) => hourHeight - 1}px,
    rgba(0, 0, 0, 0.1) ${({ hourHeight }) => hourHeight}px
  );
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
`
SC.Appointment = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 15px;
`

DayView.defaultProps = {
  hourHeight: 60,
}

DayView.propTypes = {
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      finish: PropTypes.string.isRequired,
    })
  ).isRequired,
  renderAppointment: PropTypes.func.isRequired,
}

export default DayView
