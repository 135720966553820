import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'src/lib/i18n-react'

import LanguagePanel from './subcomponents/LanguagePanel'

class LanguageChooser extends React.Component {
  renderLanguagePanels = () => {
    const { onSelectLanguage, languages, withOther } = this.props

    let panels = languages.map((language) => {
      return (
        <LanguagePanel
          key={language.code}
          onClick={() => {
            onSelectLanguage(language.code)
          }}
          languageCode={language.code}
          localizedName={language.localizedName}
        />
      )
    })

    if (withOther) {
      panels.push(
        <LanguagePanel
          key={'other'}
          onClick={() => {
            onSelectLanguage('other')
          }}
          languageCode="other"
          localizedName={t('Other')}
        />
      )
    }

    return panels
  }

  render() {
    return <div className="language-chooser">{this.renderLanguagePanels()}</div>
  }
}

LanguageChooser.propTypes = {
  withOther: PropTypes.bool,
  onSelectLanguage: PropTypes.func,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      localizedName: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default LanguageChooser
