import React from 'react'

import routes from 'src/lib/rails-routes/rails-routes'
import PaymentForm from 'src/components/PaymentForm/PaymentForm'
import { Box } from '@chatterbug/aaron'
import { Modal } from '@chatterbug/aaron/modal'
import { post } from 'src/lib/util'
import { stripePublicKey } from 'src/config/globals'

export type AddPaymentSourceModalProps = {
  allowSEPA: boolean
  currency: string
  organization?: number
  isOpen: boolean
  onClose: () => void
}

const AddPaymentSourceModal: React.FC<AddPaymentSourceModalProps> = ({
  allowSEPA,
  currency,
  organization,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Box width={{ _: 'auto', tablet: '700px' }} textAlign="center" m={'4x'}>
        <PaymentForm
          paymentMethods={allowSEPA ? ['card', 'iban'] : ['card']}
          currency={currency}
          stripePublishedKey={stripePublicKey}
          organization={organization}
          onStripeResponse={async (paymentId: string, response: Record<string, unknown>, paymentType: string, paymentMethodId: string) => {
            let redirectPath = organization ? routes.account_organizations() : routes.payments_subscription()
            window.location = redirectPath
          }}
        />
      </Box>
    </Modal>
  )
}

export default AddPaymentSourceModal
