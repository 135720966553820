/* eslint-disable react/jsx-no-literals */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'lib/http'
import { Box, Text } from '@chatterbug/aaron'

const TutorOnboardingLms = ({ shouldCheckCourseStatus }) => {
  // shouldCheckCourseStatus is an invert of course status, so we need to invert
  // it again to set initial course status
  const [courseCompleted, setCourseCompleted] = useState(
    !shouldCheckCourseStatus
  )

  useEffect(() => {
    const checkCourseStatus = async () => {
      const {
        data: { apiResponseOnCourseCompleted },
      } = await get('/check_lms_course_completion')

      if (apiResponseOnCourseCompleted) {
        setCourseCompleted(apiResponseOnCourseCompleted)
      }
    }
    shouldCheckCourseStatus && checkCourseStatus()
  }, [shouldCheckCourseStatus])

  return (
    <Box
      data-testid={courseCompleted ? 'complete' : 'incomplete'}
      className={`todo-list__item todo-list__item_${
        courseCompleted ? 'complete' : 'incomplete'
      }`}
    >
      <i className="fa fa-check" />
      <Text>Do the onboarding course</Text>
      <Text
        sx={{
          fontSize: 'sm',
          color: 'rgba(99, 153, 163, 0.6)',
          fontWeight: 'thin',
        }}
      >
        Understand how Chatterbug works for tutors
      </Text>
    </Box>
  )
}

TutorOnboardingLms.propTypes = {
  shouldCheckCourseStatus: PropTypes.bool,
}

export default TutorOnboardingLms
