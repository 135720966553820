import { Controller } from 'stimulus'
import $ from 'jquery';
import Select2 from 'select2'

// Select2 accepts ajax requests to fetch results from the server and
// formating the response to add to the dropdown. To use fetching results from the server,
// add a remoteUrl value and ensure the response matches the json format Select2
// requires. If you would like to have the response formated you can add a itemTemplate
// value as in JS string literal format for the variables with ${variable_name}.
// Example template:
//    "My name is <strong>${name}</strong>"
// Example response:
//    {
//      results: [{name: "Big Bird", text: ... }]
//    }
// Result
//    My name is <strong>Big Bird</strong>
// The response will be parsed in the callback and populate the dropdown
export default class Select2Controller extends Controller {
  static values = {
    remoteUrl: String,
    tags: Boolean,
    placeholder: String,
    itemTemplate: String,
    allowClear: Boolean,
    templateVariables: Array
  }
  connect() {
    let attrs = {
      tags: this.tagsValue,
      placeholder: this.placeholderValue,
      allowClear: this.allowClearValue,
    }
    if(this.remoteUrlValue) {
      attrs.ajax = {
        url: this.remoteUrlValue,
        dataType: "json",
        data: function (params) {
          return {
            q: params.term, // search term
            page: params.page
          };
        },
        delay: 250
      }
    }
    if(this.itemTemplateValue) {
      // Get the variables from a string with ${some_variable_name}
      this.templateVariables = this.itemTemplateValue
        .match(/\$\{(\w+)\}/g)
        .map((variable) => variable.replace(/\$|\{|\}/g, ""))
      this.templateFn = this.constructTemplateFn(this.itemTemplateValue, this.templateVariables)
      attrs.templateResult = (response) => {
        if(!response.id) {
          return response.text
        }
        let templateArgs = []
        this.templateVariables.map(variableName => {
          const value = response[variableName]
          if (value) {
            templateArgs.push(value)
          }
        })
        // Use a template node so we can include html elements in the template string
        const templateEl = document.createElement('template');
        // Trim so we never return a text node of whitespace as the result
        const html = this.templateFn(...templateArgs).trim();
        templateEl.innerHTML = html;
        return templateEl.content.firstChild;
      }
    }

    $(this.element).select2(attrs)
  }

  constructTemplateFn(literal, variableNames) {
    return new Function(variableNames, "return `"+literal+"`;")
  }
}

