import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal } from '@chatterbug/aaron/modal'
import { t } from 'src/lib/i18n-react'

import CharacterPicker from './subcomponents/CharacterPicker'

const charMaps = {
  de: {
    a: 'ä',
    o: 'ö',
    u: 'ü',
    s: 'ß',
  },
  es: {
    a: 'á',
    e: 'é',
    i: 'í',
    o: 'ó',
    u: 'ú ü',
    n: 'ñ',
    '?': '¿',
    '!': '¡',
  },
  fr: {
    a: 'à â',
    c: "ç c'",
    d: "d'",
    j: "j'",
    l: "l'",
    m: "m'",
    n: "n'",
    s: "s'",
    t: "t'",
    e: 'è é ê ë œ',
    i: 'î ï',
    o: 'ô œ',
    u: "ù û u'",
  },
  en: {},
}

// get all values from the specific language in the charMap object in
// an array to have a reference for the filter function later on
// (only show the keyboardShortcuts characters included in this array)
const getSpecialCharactersForCertainLanguage = (language) => {
  let specialCharactersForLanguage = []
  Object.values(charMaps[language]).map((el) => {
    specialCharactersForLanguage.push(el.split(' '))
  })
  return [].concat(...specialCharactersForLanguage)
}

const charMapToolTip = {
  de: 'Äß',
  es: 'Éñ',
  fr: 'Âç',
}

const keyboardShortcuts = {
  macintosh: {
    ä: 'option-u a',
    á: 'option-e a',
    ö: 'option-u o',
    ó: 'option-e o',
    ß: 'option-s',
    à: 'option-` a',
    â: 'option-i a',
    ç: 'option-c',
    è: 'option-` e',
    é: 'option-e e',
    ê: 'option-i e',
    ë: 'option-u e',
    í: 'option-e i',
    î: 'option-i i',
    ï: 'option-u i',
    ô: 'option-i o',
    ú: 'option-e u',
    ù: 'option-` u',
    û: 'option-i u',
    ü: 'option-u u',
    œ: 'option-q',
    '¿': 'shift + option-?',
    '¡': 'option-1',
    ñ: 'option-n n',
    "c'": 'c shift-#',
    "d'": 'd shift-#',
    "j'": 'j shift-#',
    "l'": 'l shift-#',
    "m'": 'm shift-#',
    "n'": 'n shift-#',
    "s'": 's shift-#',
    "t'": 't shift-#',
    "u'": 'u shift-#',
  },
  windows: {
    á: 'alt + 0225',
    ó: 'alt + 0243',
    ú: 'alt + 0250',
    ü: 'alt + 0252',
    í: 'alt + 0237',
    é: 'alt + 0233',
    ñ: 'alt + 0241',
    ä: 'alt + 0228',
    ö: 'alt + 0246',
    '¿': 'alt + 0191',
    '¡': 'alt + 0161',
    ß: 'alt + 0223',
    ë: 'alt + 0235',
    ï: 'alt + 0239',
    "c'": 'c alt + 0146',
    "d'": 'd alt + 0146',
    "j'": 'j alt + 0146',
    "l'": 'l alt + 0146',
    "m'": 'm alt + 0146',
    "n'": 'n alt + 0146',
    "s'": 's alt + 0146',
    "t'": 't alt + 0146',
    "u'": 'u alt + 0146',
  },
}

const isMobile = () =>
  typeof window.orientation !== 'undefined' ||
  navigator.userAgent.indexOf('IEMobile') !== -1

const ToolTip = ({ platform, children, onClick, innerRef, ...others }) => {
  return (
    <SC.ToolTip
      ref={innerRef}
      onMouseDown={(e) => e.preventDefault()}
      onClick={onClick}
      {...others}
    >
      {children}
    </SC.ToolTip>
  )
}

const DONT_SHOW_AUTO_KEY =
  'components.specialCharacterChooser.doNotPopupAutomatically'

class SpecialCharacterChooser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      doNotPopupAutomatically:
        this.props.doNotPopupAutomatically ||
        window.localStorage.getItem(DONT_SHOW_AUTO_KEY) === 'do-not-show',
    }
  }

  toggleDontShowAutomatically = () => {
    const doNotPopupAutomatically = !this.state.doNotPopupAutomatically
    window.localStorage.setItem(
      DONT_SHOW_AUTO_KEY,
      doNotPopupAutomatically ? 'do-not-show' : 'show'
    )
    this.setState({ doNotPopupAutomatically })
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  renderSettingsModal = () => {
    const charactersForLanguage = getSpecialCharactersForCertainLanguage(
      this.props.language
    )
    if (Object.keys(charactersForLanguage).length === 0) {
      return null
    }

    const shortcuts = keyboardShortcuts[this.props.platform.toLowerCase()] || {}
    const keys = Object.keys(shortcuts).filter((el) => {
      return charactersForLanguage.includes(el)
    })

    return (
      <Modal
        isOpen
        title={t('Input help')}
        onRequestClose={this.toggleModal}
        renderFooter={() => (
          <button onClick={this.toggleModal}>{t('Ok')}</button>
        )}
        onAfterOpen={() => {
          $('[data-toggle="tooltip"]').tooltip()
        }}
      >
        <p>
          {t(`A diacritic is a mark added to a letter that affects the way the word
          is said. We automatically pop-up options that relate to the letter
          you've just typed.`)}
        </p>
        <p>
          <input
            id="specialCharacterChooser.doNotPopupAutomatically"
            type="checkbox"
            checked={!this.state.doNotPopupAutomatically}
            onChange={this.toggleDontShowAutomatically}
          />{' '}
          <label htmlFor="specialCharacterChooser.doNotPopupAutomatically">
            {t('Pop-up automatically')}
          </label>
        </p>
        <p>
          {t(
            'You can also use the %{keyCombos} keys to make the options pop-up.',
            {
              keyCombos: [t('↑ arrow'), 'cmd+k', t('and ctrl+k')].join(', '),
            }
          )}
        </p>
        {keys.length > 0 && (
          <ul>
            {keys.map((key, index) => {
              return (
                <li
                  key={`special-key-${index}`}
                  className="keyboard__button"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={shortcuts[key]}
                >
                  {key}
                </li>
              )
            })}
          </ul>
        )}
      </Modal>
    )
  }

  render() {
    if (isMobile()) {
      return null
    }

    const { selector, language, platform } = this.props
    const { showModal, doNotPopupAutomatically } = this.state

    if (!charMaps[language]) {
      console.warn(`Language ${language} is not supported.`)
      return null
    } else if (Object.keys(charMaps[language]).length === 0) {
      // no diacritics for this language
      return null
    }

    return (
      <div>
        {showModal && this.renderSettingsModal()}
        {Array.from(document.querySelectorAll(selector)).map(
          (element, index) => (
            <CharacterPicker
              showAutomatically={!doNotPopupAutomatically}
              key={`special-character-picker-${index}`}
              element={element}
              charMap={charMaps[language]}
              willShow={() => {
                this.toolTipRef.style['background'] = 'rgba(0, 0, 0, 0.6)'
              }}
              willHide={() => {
                this.toolTipRef &&
                  (this.toolTipRef.style['background'] = 'rgba(0, 0, 0, 0.2)')
              }}
            />
          )
        )}
        <ToolTip
          platform={platform}
          onClick={this.toggleModal}
          innerRef={(el) => (this.toolTipRef = el)}
        >
          {charMapToolTip[language]}
        </ToolTip>
      </div>
    )
  }
}

const SC = {}
SC.ToolTip = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-size: 20px;
  font-weight: 300;
  color: white;
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: background-color 0.5s ease;
`
SC.KeyCombo = styled.span`
  border: 1px #eee solid;
  border-radius: 4px;
  padding: 4px;
  color: #666;
`

SpecialCharacterChooser.propTypes = {
  platform: PropTypes.oneOf(['Macintosh', 'Windows']),
  doNotPopupAutomatically: PropTypes.bool,
  selector: PropTypes.string.isRequired,
  language: PropTypes.oneOf(['de', 'es', 'fr', 'en']).isRequired,
}

export default SpecialCharacterChooser
