import React from 'react'
import PropTypes from 'prop-types'

import { Image, Flex, Box } from '@chatterbug/aaron'

import keyboard from './assets/keyboard.svg'
import Message from './subcomponents/Message/Message'
import NewMessageForm from './subcomponents/NewMessageForm/NewMessageForm'

const Placeholder = () => {
  return (
    <Flex
      flexDirection="column"
      flex="1"
      height="100%"
      justifyContent="center"
      aria-label="Type a message to start chatting"
    >
      <Image src={keyboard} mb="md" maxHeight={120} height="100%" />
    </Flex>
  )
}

export default class TextChat extends React.Component {
  // in order to scroll to the bottom in Replay, doesn't work in real LL
  componentDidMount() {
    if (this.scrollContainer) {
      this.scrollToBottom()
    }
  }
  // scroll to bottom when there is a new message
  componentDidUpdate(prevProps) {
    if (
      this.scrollContainer &&
      this.props.messages.length !== prevProps.messages.length
    ) {
      this.scrollToBottom()
    }
  }

  scrollToBottom = () => {
    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight
  }

  render() {
    const {
      messages = [],
      readonly,
      onSubmit,
      onTranslateClick,
      ...rest
    } = this.props
    return (
      <Flex
        textAlign="left"
        width="100%"
        flexDirection="column"
        aria-label="chat"
        height="100%"
        {...rest}
      >
        {messages.length === 0 ? (
          <Placeholder />
        ) : (
          <Box
            height="100%"
            ref={(el) => {
              this.scrollContainer = el
            }}
            css={`
              overflow: auto;
            `}
          >
            {messages.map((message, index) => {
              return (
                <Message
                  {...message}
                  key={`text-chat-message-${index}`}
                  onTranslateClick={onTranslateClick}
                />
              )
            })}
          </Box>
        )}
        {!readonly && <NewMessageForm onSubmit={onSubmit} />}
      </Flex>
    )
  }
}

TextChat.propTypes = {
  messages: PropTypes.array,
  readonly: PropTypes.bool,
  onSubmit: PropTypes.func,
  onTranslateClick: PropTypes.func.isRequired,
}
