import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const GraphPaper = ({
  numOfSegments,
  numOfNotches,
  selectedSegment,
  ...others
}) => {
  return (
    <SC.Container {...others}>
      {Array.from({ length: numOfSegments }).map((_, segmentIdx) => {
        const VerticalLine =
          segmentIdx !== selectedSegment - 1
            ? SC.VerticalLine
            : SC.VerticalLineActive
        return (
          <VerticalLine key={`segment-${segmentIdx}`}>
            {Array.from({ length: numOfNotches + 1 }).map((_, notchIdx) => (
              <SC.SmallNotch key={`notch-${notchIdx}`} />
            ))}
          </VerticalLine>
        )
      })}
    </SC.Container>
  )
}

const SC = {}
SC.Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;

  &:after {
    position: absolute;
    top: 60px;
    left: 0;
    content: ' ';
    width: 1px;
    height: calc(100% - 60px);
    background: rgba(160, 198, 206, 0.42);
  }
`
SC.VerticalLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  border-right: 1px #d4e5e9 solid;
`
SC.VerticalLineActive = styled(SC.VerticalLine)`
  border-right-color: #000;
  border-right-width: 1px;
`
SC.SmallNotch = styled.div`
  flex: 1;
  height: 11px;
  border-right: 0.5px #a0c6ce solid;
  margin-top: 60px;

  &:last-child {
    border: 0;
  }
`

GraphPaper.propTypes = {
  selectedSegment: PropTypes.number.isRequired,
  numOfNotches: PropTypes.number.isRequired,
  numOfSegments: PropTypes.number.isRequired,
}

export default GraphPaper
