import React from 'react'
import styled from 'styled-components'

import { t } from 'src/lib/i18n-react'
class ThanksPage extends React.Component {
  render() {
    return (
      <div>
        <SC.Title>{t('Perfect!')}</SC.Title>
        <SC.Explanation primary>
          {t('You will be the first to know when it’s ready.')}
        </SC.Explanation>
        <div>{t('Thanks for your interest.')}</div>
        <SC.StarImage />
        <div>
          <a href="/" className="button button_cta">
            {t('Back to Homepage')}
          </a>
        </div>
      </div>
    )
  }
}

const SC = {}

SC.Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 30px;
`
SC.Explanation = styled.div`
  font-weight: ${(props) => (props.primary ? 'bold' : 'normal')};
  margin-top: 20px;
`
SC.StarImage = styled.div`
  background-image: url(${require('./images/happy-star-large.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  width: 100%;
  height: 150px;
  margin: 30px 0 40px 0;
`

export default ThanksPage
