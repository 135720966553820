import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { t, tWithEscape } from 'src/lib/i18n-react'
import SignupForm from 'src/components/SignupPage/subcomponents/SignupForm/SignupForm'
import UnknownL1Form from 'src/components/SignupPage/subcomponents/UnknownL1Form/UnknownL1Form'
import LanguageChooser from 'src/components/SignupPage/subcomponents/LanguageChooser/LanguageChooser'

const LANGUAGE_CHOOSER_PAGE = 0
const SIGNUP_FORM_PAGE = 1
const MISSING_L1_PAGE = 2

export default class ReferralSignupPage extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    login: PropTypes.string,
    avatarUrl: PropTypes.string,
    buttonText: PropTypes.string,
    hideLogin: PropTypes.bool,
    hideTutor: PropTypes.bool,
    email: PropTypes.string,
    inviteCode: PropTypes.string,
    role: PropTypes.string,
    isMissingL1: PropTypes.bool,
    nativeLanguageCodes: PropTypes.string,
    selectedLanguageCode: PropTypes.string,
    languages: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        localizedName: PropTypes.string.isRequired,
      })
    ).isRequired,
  }

  static defaultProps = {
    buttonText: t('Sign Up'),
    hideTutor: true,
    hideLogin: false,
  }

  constructor() {
    super()
    this.state = {
      pageIndex: LANGUAGE_CHOOSER_PAGE,
      languageCode: 'de',
    }
    window.onpopstate = () => {
      this.setState({ pageIndex: LANGUAGE_CHOOSER_PAGE })
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { selectedLanguageCode, languages } = this.props
    const selectedTabIndex = _.findIndex(
      languages,
      (l) => l.code === selectedLanguageCode
    )
    if (selectedTabIndex > -1) this.setState({ selectedTabIndex })
  }

  renderDiscount() {
    const { name, login } = this.props
    const showName = _.escape(name === '' ? login : name)
    return (
      <div className="l-referral-signup-page__scene__speech">
        <p
          dangerouslySetInnerHTML={{
            __html: tWithEscape(
              `Thanks to <strong>%{showName}</strong>, you get
          a <strong>25% discount</strong> off
          of your first month of learning with Chatterbug!
          Earn free classes by referring your friends!`,
              { showName }
            ),
          }}
        />
      </div>
    )
  }

  renderHeader = () => {
    return t('What language do you want to learn?')
  }

  selectLanguage = (selectedLanguageCode) => {
    const { role, languages, isMissingL1, nativeLanguageCodes } = this.props
    let finalNativeLanguageCodes = nativeLanguageCodes

    if (selectedLanguageCode === 'other') {
      this.setState({
        pageIndex: SIGNUP_FORM_PAGE,
        languageCode: 'other',
        localizedName: t('Other'),
        nativeLanguageCodes: finalNativeLanguageCodes,
      })
    } else {
      const langDef = _.find(languages, (lang) => {
        return lang.code === selectedLanguageCode
      })

      // Determine if we need to ask for L1.
      const l1Codes = langDef.pairedNativeLanguages.map((lang) => lang.code)
      const nativeCodes = nativeLanguageCodes.split(',')
      const sameCodes = _.intersection(l1Codes, nativeCodes)

      if (role !== 'tutor' && (isMissingL1 || sameCodes.length !== 1)) {
        this.setState({
          pageIndex: MISSING_L1_PAGE,
          languageCode: selectedLanguageCode,
          localizedName: langDef.localizedName,
          nativeLanguageCodes: finalNativeLanguageCodes,
        })
      } else {
        finalNativeLanguageCodes = sameCodes[0]
        this.setState({
          pageIndex: SIGNUP_FORM_PAGE,
          languageCode: selectedLanguageCode,
          localizedName: langDef.localizedName,
          nativeLanguageCodes: finalNativeLanguageCodes,
        })
      }
    }
  }

  render() {
    const {
      languageCode,
      nativeLanguageCodes,
      localizedName,
      pageIndex,
    } = this.state
    const { languages, avatarUrl } = this.props

    return (
      <div className="pure-g l-referral-signup-page">
        <div className="pure-1-1 pure-md-1-2 l-referral-signup-page__left">
          <h1>{t('Learn a New Language with Chatterbug')}</h1>
          <h3
            className="l-referral-signup-page__subtitle"
            dangerouslySetInnerHTML={{
              __html: t(`Chatterbug is the best way to learn to <strong>speak</strong> a
            language for real. We give you courses that respond to the way
            you learn, and one-on-one video sessions with real speakers from
            around the world.`),
            }}
          />
          <div className="l-referral-signup-page__avatar">
            <img src={avatarUrl} alt="" />
          </div>
          <div
            className={`l-referral-signup-page__scene l-referral-signup-page__scene_${languageCode}`}
          >
            {this.renderDiscount()}
            <div className="l-referral-signup-page__scene__character" />
            <div className="l-referral-signup-page__scene__bg" />
          </div>
        </div>
        <div className="pure-1-1 pure-md-1-2 l-referral-signup-page__right">
          <div className="l-signup-referral-page">
            {pageIndex === LANGUAGE_CHOOSER_PAGE && (
              <div>
                <h2>{this.renderHeader()}</h2>
                <LanguageChooser
                  withOther={false}
                  languages={languages}
                  onSelectLanguage={this.selectLanguage}
                />
              </div>
            )}
            {pageIndex === MISSING_L1_PAGE && (
              <UnknownL1Form
                {...this.props}
                languageCode={languageCode}
                nativeLanguageCode={nativeLanguageCodes}
                localizedName={localizedName}
                onResumeSignup={this.selectValidPair}
              />
            )}
            {pageIndex === SIGNUP_FORM_PAGE && (
              <div>
                {languageCode !== 'other' && (
                  <h2 className="l-signup-page__title">
                    {t('Create your account')}
                  </h2>
                )}
                <SignupForm
                  {...this.props}
                  languageCode={languageCode}
                  nativeLanguageCode={nativeLanguageCodes}
                  localizedName={localizedName}
                  hideLogin={1}
                  hideTutorCTA={1}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
