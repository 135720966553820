import React from 'react'
import PropTypes from 'prop-types'

class FormGroup extends React.Component {
  renderWithoutErrors = () => {
    const { children } = this.props
    return <div className="form-group">{children}</div>
  }

  renderWithErrors = () => {
    const { name, errors, children } = this.props
    return (
      <div className="form-group">
        <div className="has-error">{children}</div>
        <p className="field-error">
          {name} {errors}
        </p>
      </div>
    )
  }

  render() {
    const { errors } = this.props

    return errors ? this.renderWithErrors() : this.renderWithoutErrors()
  }
}

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.string,
}

export default FormGroup
