import React, { Suspense, lazy } from 'react'

import Loader from 'src/components/Loader'

const Page = lazy(() =>
  import(
    /* webpackChunkName: "LoadPageStudentGoals" */ 'src/pages/StudentGoals/StudentGoals'
  )
)

function LoadPageStudentGoals(props) {
  return (
    <Suspense fallback={() => <Loader />}>
      <Page {...props} />
    </Suspense>
  )
}

export default LoadPageStudentGoals
