/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Flex, Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

const NewMessageForm = ({ onSubmit }) => {
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()

    if (message === '') {
      return
    }

    onSubmit(message)
    setMessage('')
  }

  return (
    <Flex
      as="form"
      width={1}
      autoComplete="off"
      onSubmit={handleSubmit}
      alignItems="center"
      mt="sm"
    >
      <Box
        as="input"
        type="text"
        width={1}
        mr="sm"
        placeholder={t('Type something to chat')}
        autoComplete="off"
        fontSize="sm"
        p="md"
        py="sm"
        border="2px solid #ddd"
        aria-label="New chat message input"
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        css={`
          border-radius: 3px;
          ::placeholder {
            color: #cccccc;
          }
          :focus {
            outline: none;
            border-color: #43ddf2;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
              0 0 8px rgba(37, 204, 237, 0.6);
          }
        `}
      />

      <Button
        type="info"
        onClick={handleSubmit}
        aria-label="Send chat message"
        width="90px"
      >
        {t('Send')}
      </Button>
    </Flex>
  )
}

NewMessageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default NewMessageForm
