import React, { Suspense, lazy } from 'react'
import PageLayout, { PAGE_GAP } from 'src/ui/PageLayout/PageLayout'

import { ProviderName } from 'telco'
import LiveLessonProvider from 'src/pages/LiveLesson/components/LiveLessonProvider/LiveLessonProvider'
const Page = lazy(() =>
  import(
    /* webpackChunkName: 'LiveLessonCheckRoom' */ 'src/pages/LiveLesson/subpages/CheckRoom/CheckRoom'
  )
)

const LoadPageLiveLessonCheckRoom = (props) => {
  return (
    <PageLayout>
      <Suspense fallback={null}>
        <LiveLessonProvider telcoProvider={ProviderName}>
          <Page {...props} />
        </LiveLessonProvider>
      </Suspense>
    </PageLayout>
  )
}

export default LoadPageLiveLessonCheckRoom
