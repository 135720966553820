import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

const Globe = ({ loading, onClick }) => {
  if (loading) {
    return (
      <Box>
        <i className="fa fa-spinner fa-spin" />
      </Box>
    )
  }

  return (
    <Box
      as="a"
      title={t('Translate this message')}
      onClick={onClick}
      aria-label="Translate this message"
      css={`
        cursor: pointer;
      `}
    >
      <i className="fa fa-globe" />
    </Box>
  )
}

Globe.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default Globe
