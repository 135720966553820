import { getClient, gql } from 'src/lib/graphql'

/**
 * Check if the user has granted permission to the media devices
 *
 * This works because devices do not have labels if the user has not granted
 * permission
 */
export const isUserMediaAccessGranted = async () => {
  return (
    typeof (await window.navigator.mediaDevices.enumerateDevices()).find(
      ({ label }) => label.length > 0
    ) !== 'undefined'
  )
}

export const EVENTS = {
  LOCAL_AUDIO_PLAYBACK: 'LOCAL_AUDIO_PLAYBACK',
  LOCAL_VIDEO_PLAYBACK: 'LOCAL_VIDEO_PLAYBACK',
  REMOTE_AUDIO_PLAYBACK: 'REMOTE_AUDIO_PLAYBACK',
}

/**
 * Use this function to map the errors from different telco providers to those that
 * we handle.
 */
export const ERRORS = {
  BROWSER_NOT_SUPPORTED: 'ERROR_BROWSER_NOT_SUPPORTED',
  DEVICE_IN_USE: 'ERROR_DEVICE_IN_USE',
  NETWORK: 'ERROR_NETWORK',
  GENERIC: 'ERROR_GENERIC',
  DEVICE_NOT_FOUND: 'ERROR_DEVICE_NOT_FOUND',
  INCORRECT_CONFIG: 'ERROR_INCORRECT_CONFIG',
}

export const normalizeError = (errorName) =>
  ({
    // OpenVidu: https://openvidu.io/api/openvidu-browser/enums/openviduerrorname.html
    BROWSER_NOT_SUPPORTED: ERRORS.BROWSER_NOT_SUPPORTED,
    DEVICE_ALREADY_IN_USE: ERRORS.DEVICE_IN_USE,
    INPUT_VIDEO_DEVICE_NOT_FOUND: ERRORS.DEVICE_NOT_FOUND,
    // TokBox: https://static.opentok.com/developer/sdks/js/reference/Error.html
    // Tokbox initPublisher:
    OT_HARDWARE_UNAVAILABLE: ERRORS.DEVICE_IN_USE,
    OT_DISCONNECTED: ERRORS.NETWORK,
    // Tokbox Session.connect
    ERROR_NETWORK: ERRORS.NETWORK,
    OT_AUTHENTICATION_ERROR: ERRORS.INCORRECT_CONFIG,
    OT_CONNECT_FAILED: ERRORS.NETWORK,
    OT_NOT_CONNECTED: ERRORS.NETWORK,
    // Tobox Session.publish
    OT_CREATE_PEER_CONNECTION_FAILED: ERRORS.NETWORK,
    OT_MEDIA_ERR_NETWORK: ERRORS.NETWORK,
    OT_TIMEOUT: ERRORS.NETWORK,
    OT_PERMISSION_DENIED: ERRORS.INCORRECT_CONFIG,
    OT_INVALID_PARAMETER: ERRORS.INCORRECT_CONFIG,
    // Tokbox Session.subscribe
  }[errorName] || ERRORS.GENERIC)

/**
 * Creates a room for the provided telco.
 * @param {"tokbox"|"openvidu"} provider
 * @returns {object}
 */
export const createRoom = async (provider) => {
  try {
    const {
      data: { createRoom },
    } = await getClient().mutate({
      mutation: gql`
        mutation CREATE_ROOM($telco: RoomTelco!) {
          createRoom(telco: $telco) {
            id
            token
            userToken
          }
        }
      `,
      variables: { telco: provider },
    })

    return createRoom
  } catch (e) {
    return {}
  }
}
