import React from 'react'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import ContentCard from 'src/components/ContentCard/ContentCard'
import ContentTitle from 'src/components/ContentTitle/ContentTitle'

const PaymentMethodWarning = () => {
  return (
    <ContentCard>
      <ContentTitle title="Payment Method" />
      <div>
        <p>{t('Setup a payment method to get paid for your services.')}</p>
        <Button
          fill="outline"
          onClick={() => {
            window.location = routes.new_payments_payout()
          }}
          disableWith="..."
        >
          {t('Setup Payments')}
        </Button>
      </div>
    </ContentCard>
  )
}

export default PaymentMethodWarning
