import React from 'react'
import routes from 'src/lib/rails-routes/rails-routes'
import { post } from 'src/lib/util'
import PageAlert from '../PageAlert'
import { Box } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import { Language } from 'src/types'
import _ from 'lodash'
import { setLocale } from 'src/config/globals'

// called atfer seleting the final language pair;
// forwards to signup or homepage depending on where this is called from, saves selected languages on existing user
export const handleActionAfterLanguageSelection = async (
  l1Code: string,
  l2Code: string,
  withSignup?: boolean,
  url?: string
) => {
  // the case with going to signup url after selecting L1
  if (withSignup && url) {
    window.history.pushState({}, '', url)
    // the case with selecting languages without signup
  } else {
    const response = await post(routes.user_languages(), {
      l1Code: l1Code,
      l2Code: l2Code,
    })

    if (response?.status === 'error') {
      PageAlert.showDanger(
        <Box>
          {t(
            'An error has occurred while saving your languages. Please contact us so we can address the issue.'
          )}
        </Box>
      )
    } else {
      window.location = routes.home_student({ code: l2Code })
    }
  }
}

export const handleSelectLanguage = async (
  selectedLanguageCode: string,
  languages: Language[],
  nativeLanguageCodes: string,
  isMissingL1: boolean,
  role: string,
  withSignup: boolean,
  onSelectLanguage: (
    selectedLanguageCode: string,
    localizedName: string,
    finalNativeLanguageCodes: string[] | string,
    index?: string
  ) => void,
  setCurrentStep: (index: number) => void
) => {
  let finalNativeLanguageCodes = nativeLanguageCodes

  if (selectedLanguageCode === 'other') {
    onSelectLanguage(
      'other',
      t('Other'),
      finalNativeLanguageCodes,
      'SIGNUP_FORM_PAGE'
    )
  } else {
    const langDef = _.find(languages, (lang) => {
      return lang.code === selectedLanguageCode
    })

    // Determine if we need to ask for L1.
    const l1Codes = langDef.pairedNativeLanguages.map(
      (lang: Language) => lang.code
    )
    const nativeCodes = nativeLanguageCodes.split(',')
    const sameCodes = _.intersection(l1Codes, nativeCodes)

    if (role === 'tutor') {
      onSelectLanguage(
        selectedLanguageCode,
        langDef.localizedName,
        finalNativeLanguageCodes,
        'SIGNUP_FORM_PAGE'
      )
      // L1 ambigous, select it
    } else if (isMissingL1 || sameCodes.length !== 1) {
      onSelectLanguage(
        selectedLanguageCode,
        langDef.localizedName,
        finalNativeLanguageCodes
      )
      setCurrentStep(1)
      // if there is only one intersecting L1 between user native langs and L2's native langs
      // we go straight to signup/homepage, no need to select
    } else {
      finalNativeLanguageCodes = sameCodes[0]
      onSelectLanguage(
        selectedLanguageCode,
        langDef.localizedName,
        finalNativeLanguageCodes,
        withSignup ? 'SIGNUP_FORM_PAGE' : null
      )
      // for cases with signup we don't need to save the languages yet and the url changes automatically
      !withSignup &&
        (await handleActionAfterLanguageSelection(
          finalNativeLanguageCodes,
          selectedLanguageCode
        ))
    }
  }
  if (role !== 'tutor') {
    const languagePair =
      selectedLanguageCode === 'other'
        ? 'other'
        : `${selectedLanguageCode}-${finalNativeLanguageCodes}`
    setLocale(finalNativeLanguageCodes)
    // go to the page selecting L1
    window.history.pushState(
      {},
      '',
      withSignup ? `/signup/${languagePair}` : `/user_languages/${languagePair}`
    )
  }
}

// after selecting L1 we set it to state and forward either to signup or to home page
export const selectValidPair = async (
  l1Code: string,
  l2Code: string,
  languages: Language[],
  withSignup: boolean,
  onSelectLanguage: (
    selectedLanguageCode: string,
    localizedName: string,
    finalNativeLanguageCodes: string[] | string,
    index?: string
  ) => void
) => {
  const targetLanguage = languages.find((lang) => lang.code === l2Code)

  onSelectLanguage(
    targetLanguage.code,
    targetLanguage.localizedName,
    l1Code,
    withSignup ? 'SIGNUP_FORM_PAGE' : null
  )
  setLocale(l1Code)
  await handleActionAfterLanguageSelection(
    l1Code,
    l2Code,
    withSignup,
    `/signup/${l2Code}-${l1Code}`
  )
}
