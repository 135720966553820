import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const hourToAmPM = (hour) => {
  const ampm = hour >= 12 ? 'pm' : 'am'
  return ((hour + 11) % 12) + 1 + ampm
}

class HourLabels extends React.Component {
  render() {
    const { hourHeight, ...others } = this.props
    return (
      <SC.Container {...others}>
        {[...Array(24).keys()].map((hour) => (
          <SC.Hour
            hourHeight={hourHeight}
            key={`hour-${hour}`}
            className={`appointment-list-hour-labels-${hourToAmPM(hour)}`}
          >
            {hourToAmPM(hour)}
          </SC.Hour>
        ))}
      </SC.Container>
    )
  }
}

const SC = {}
SC.Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px rgba(0, 0, 0, 0.1) solid;
`
SC.Hour = styled.div`
  height: ${({ hourHeight }) => hourHeight}px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  color: #a6c0c6;
  font-size: 12px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`

HourLabels.defaultProps = {
  hourHeight: 60,
}

HourLabels.propTypes = {
  hourHeight: PropTypes.number,
}

export default HourLabels
