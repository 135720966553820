import React from 'react'

import { Box, Image } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import firstLiveLesson from './first.png'

const FirstLiveLesson = () => (
  <Box textAlign="center" mt="xl" mb="xxl">
    <Image src={firstLiveLesson} alt={t('First Live Lesson!')} height="60px" />
  </Box>
)

export default FirstLiveLesson
