import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { post, redirectToHREF } from 'lib/util'
import { t } from 'src/lib/i18n-react'
import PasswordStrength from 'src/components/PasswordStrength'

import FormGroup from './FormGroup'

const URL = '/signup'

class LanguageSignupForm extends React.Component {
  constructor(props) {
    super()

    this.state = {
      username: '',
      email: props.email,
      password: '',
      orgName: '',
      orgBillingEmail: '',
      inviteCode: props.inviteCode,
      usernameErrors: '',
      emailErrors: '',
      passwordErrors: '',
      orgNameErrors: '',
      orgBillingEmailErrors: '',
      inviteCodeErrors: '',
    }
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value })
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    this.signupSubmit.value = t('Signing up...')
    this.signupSubmit.setAttribute('disabled', '')

    let hiddenFields = {
      timezone: moment.tz.guess(),
      offer_code: this.props.offerCode,
      anonymousId: this.anonymousId,
      role: this.props.role,
      orgId: this.props.orgId,
      orgCode: this.props.orgCode,
      nativeLanguageCode: this.props.nativeLanguageCode,
      languageCode: this.props.languageCode,
    }

    let formData = { ...hiddenFields, ...this.state }

    post(URL, formData, 'json').then(({ data, status }) => {
      if (status === 'success') {
        window.eventTracking.track(
          'signup success',
          'email',
          this.props.languageCode
        )
        redirectToHREF(data.data.redirect)
      } else {
        this.processError(data.errors, 'username')
        this.processError(data.errors, 'email')
        this.processError(data.errors, 'password')
        this.processError(data.errors, 'orgName')
        this.processError(data.errors, 'orgBillingEmail')
        this.processError(data.errors, 'inviteCode')
        this.signupSubmit.removeAttribute('disabled')
      }
    })

    event.preventDefault()
    return false
  }

  processError = (errors, name) => {
    let object = _.find(errors, ['property', name])
    let eName = name + 'Errors'
    if (object) {
      this.setState({ [eName]: object.message })
      return true
    } else {
      this.setState({ [eName]: '' })
      return false
    }
  }

  renderInviteCodeField = () => {
    const { inviteCode, inviteCodeErrors } = this.state

    if (inviteCodeErrors) {
      return (
        <FormGroup name={t('Invite Code')} errors={inviteCodeErrors}>
          <label htmlFor="inviteCode">{t('Invite Code')}</label>
          <input
            type="text"
            name="inviteCode"
            value={inviteCode}
            className="form-control"
            placeholder={t('Invite Code')}
            onChange={this.handleTextChange}
          />
        </FormGroup>
      )
    }
  }

  render() {
    const { buttonText } = this.props
    const {
      username,
      email,
      password,
      usernameErrors,
      emailErrors,
      passwordErrors,
    } = this.state

    return (
      <form method="post" action={URL} onSubmit={this.handleSubmit}>
        <div className="signup-form__form">
          <FormGroup name={t('Username')} errors={usernameErrors}>
            <label htmlFor="username">{t('Username')}</label>
            <input
              type="text"
              name="username"
              autoCapitalize="none"
              autoCorrect="none"
              value={username}
              className="form-control"
              placeholder={t('Pick a username')}
              onChange={this.handleUsernameChange}
            />
          </FormGroup>
          <FormGroup name={t('Email address')} errors={emailErrors}>
            <label htmlFor="email">{t('Email Address')}</label>
            <input
              type="email"
              inputMode="email"
              name="email"
              value={email}
              className="form-control"
              autoComplete="username"
              placeholder={t('Your email')}
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup name={t('Password')} errors={passwordErrors}>
            <label htmlFor="password">{t('Password')}</label>
            <input
              type="password"
              name="password"
              value={password}
              className="form-control"
              placeholder={t('Create a password')}
              autoComplete="new-password"
              onChange={this.handleTextChange}
            />
            <PasswordStrength password={password} />
          </FormGroup>

          {this.renderInviteCodeField()}

          <div className="button-bar text_center">
            <input
              type="submit"
              className="button button_cta button_large"
              value={buttonText}
              ref={(c) => {
                this.signupSubmit = c
              }}
            />
          </div>
        </div>
      </form>
    )
  }
}

LanguageSignupForm.defaultProps = {
  email: '',
  inviteCode: '',
  role: 'student',
}

LanguageSignupForm.propTypes = {
  email: PropTypes.string,
  inviteCode: PropTypes.string,
  role: PropTypes.string,
  orgId: PropTypes.string,
  orgCode: PropTypes.string,
  nativeLanguageCode: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  offerCode: PropTypes.string,
}

export default LanguageSignupForm
