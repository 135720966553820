import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ContentTitle = ({ title, actionsContent, subtitle, ...rest }) => (
  <SC.Container>
    <SC.Header>
      {title && title}
      {subtitle && <SC.Subtitle resetPadding={!title}>{subtitle}</SC.Subtitle>}
    </SC.Header>
    {actionsContent && <SC.Actions>{actionsContent()}</SC.Actions>}
  </SC.Container>
)

const SC = {}
SC.Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
SC.Header = styled.h3`
  flex: 1;
  flex-direction: row;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 20px;
  margin-top: 0;
`
SC.Subtitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: #b3b3b3;
  padding-left: ${(props) => (props.resetPadding ? 0 : '7.5px')};
`
SC.Actions = styled.div`
  flex: 1;
  text-align: right;
`

ContentTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.any,
  actionsContent: PropTypes.func,
}

export default ContentTitle
