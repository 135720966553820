import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import styled from 'styled-components'

import HourLabels from './subcomponents/HourLabels/HourLabels'
import DayView from './subcomponents/DayView/DayView'
import Item from './subcomponents/Item/Item'

const appointmentsByDay = (appointments) =>
  appointments.reduce((newAppointments, appointment) => {
    const date = DateTime.fromISO(appointment.start).toFormat('yyyy-MM-dd')
    return {
      ...newAppointments,
      [date]: [
        ...(newAppointments[date] ? newAppointments[date] : []),
        appointment,
      ],
    }
  }, {})

export const convertTimeToMinutes = (time) => {
  const { hour, minute } = DateTime.fromISO(time)
  return hour * 60 + minute
}

class AppointmentCalendar extends React.Component {
  state = {
    currentTime: 0,
    currentIndex: 0,
  }

  componentDidMount() {
    this.updateCurrentTime(() => this.scrollToTime())
    this.timerId = window.setInterval(this.updateCurrentTime, 1000 * 60)
  }

  componentDidUpdate() {
    try {
      $('[data-toggle="tooltip"]').tooltip()
    } catch (e) {
      // No jQuery in Storybook
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerId)
  }

  scrollToTime = () => {
    const el =
      this.containerEl &&
      this.props.scrollToTime &&
      this.containerEl.querySelector(
        `.appointment-list-hour-labels-${this.props.scrollToTime}`
      )
    if (el) {
      // the height of the header + padding is 45px. We offset it by one hour.
      this.containerEl.parentNode.scrollTop = el.offsetTop - 45 - 60
    }
  }

  updateCurrentTime = (callback) => {
    const { hour, minute } = DateTime.local()
    this.setState(
      {
        currentTime: hour * 60 + minute,
      },
      callback
    )
  }

  hasNextPage = () => {
    const { currentIndex } = this.state
    const { numOfDays, numOfDaysPerPage } = this.props
    return Math.ceil(numOfDays / numOfDaysPerPage) - 1 > currentIndex
  }

  nextPage = () => {
    const { currentIndex } = this.state
    this.hasNextPage() && this.setState({ currentIndex: currentIndex + 1 })
  }

  hasPrevPage = () => this.state.currentIndex > 0

  prevPage = () => {
    const { currentIndex } = this.state
    this.hasPrevPage() && this.setState({ currentIndex: currentIndex - 1 })
  }

  renderDayLabels = (days) =>
    days.map(({ day, dayNumber }) => (
      <SC.DayLabel
        dayNumber={dayNumber}
        key={`dayLabel-${dayNumber}`}
        today={day === DateTime.local().toFormat('yyyy-MM-dd')}
      >
        {DateTime.fromISO(day).toLocaleString({
          weekday: 'long',
          month: 'long',
          day: '2-digit',
        })}
      </SC.DayLabel>
    ))

  renderDays = (days) => {
    const { appointments, actions } = this.props
    const sortedAppointments = appointmentsByDay(appointments)

    return days.map(({ day, dayNumber }) => (
      <SC.Day dayNumber={dayNumber} key={`day-${dayNumber}`}>
        {day === DateTime.local().toFormat('yyyy-MM-dd') && (
          <SC.CurrentTime
            dayNumber={dayNumber}
            currentTime={this.state.currentTime}
            className="appointment-list-hour-labels-now"
          />
        )}
        <DayView
          appointments={sortedAppointments[day] || []}
          renderAppointment={({ finish, status, ...appointment }) => {
            // Determine if this is in the past based on the `finish` date
            const isPast =
              DateTime.local().toMillis() > DateTime.fromISO(finish).toMillis()
            const computedStatus = isPast ? 'past' : status
            return (
              <Item
                {...{
                  ...appointment,
                  status: computedStatus,
                  finish,
                }}
                actions={actions}
              />
            )
          }}
        />
      </SC.Day>
    ))
  }

  render() {
    const {
      startDate: startDateISO,
      numOfDays,
      numOfDaysPerPage,
      ...others
    } = this.props
    const { currentIndex } = this.state

    const startDate = DateTime.fromISO(startDateISO)
    const days = Array.from({ length: numOfDays })
      .map((_, index) => startDate.plus({ day: index }).toFormat('yyyy-MM-dd'))
      .slice(
        currentIndex * numOfDaysPerPage,
        currentIndex * numOfDaysPerPage + numOfDaysPerPage
      )
      .map((day, index) => ({ day, dayNumber: index + 1 }))

    return (
      <SC.Container numOfVisibleHours={12} {...others}>
        <SC.ScrollView numOfDays={days.length}>
          <SC.Calendar
            numOfDays={days.length}
            ref={(el) => (this.containerEl = el)}
          >
            <SC.HourLabels>
              <HourLabels />
            </SC.HourLabels>

            {this.renderDayLabels(days)}
            {this.renderDays(days)}
          </SC.Calendar>
        </SC.ScrollView>
        {this.hasPrevPage() && <SC.PagePrev onClick={this.prevPage} />}
        {this.hasNextPage() && <SC.PageNext onClick={this.nextPage} />}
      </SC.Container>
    )
  }
}

const SC = {}
SC.Container = styled.div`
  position: relative;
  height: ${({ numOfVisibleHours }) => numOfVisibleHours * 60}px;
`
SC.ScrollView = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 5px;
`
SC.Calendar = styled.div`
  grid-column-gap: 5px;
  display: grid;
  grid-template-rows:
    [headings] 30px
    [appointments] auto;
  grid-template-columns: [hours] auto repeat(
      ${({ numOfDays }) => numOfDays},
      1fr
    );
  position: relative;
`
SC.DayLabel = styled.div`
  grid-row: headings;
  grid-column: ${({ dayNumber }) => dayNumber + 1};
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  color: ${({ today }) => (today ? '#000' : '#a6c0c6')};
  font-size: 12px;
  background: #fff;
`
SC.HourLabels = styled.div`
  grid-row: appointments;
  grid-column: hours;
`
SC.Day = styled.div`
  grid-column: ${({ dayNumber }) => dayNumber + 1};
  grid-row: appointments;
  background: #f4f7f9;
  border-radius: 4px;
`
SC.CurrentTime = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  grid-row: appointments;
  border-bottom: 2px red solid;
  opacity: 0.3;
  z-index: 100;
  ${({ currentTime, dayNumber }) => `
    grid-column: ${dayNumber + 1} / span 1;
    top: ${currentTime}px;
  `};
`
SC.PageControl = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -22px;
  width: 44px;
  height: 44px;
  background: transparent url(${require('./paginationArrow.svg')}) no-repeat 50%;
`
SC.PagePrev = styled(SC.PageControl)`
  left: -44px;
`
SC.PageNext = styled(SC.PageControl)`
  right: -44px;
  transform: rotate(180deg);
`

AppointmentCalendar.defaultProps = {
  scrollToTime: '8am',
  showCurrentTime: false,
  numOfDaysPerPage: 2,
}

AppointmentCalendar.propTypes = {
  startDate: PropTypes.string.isRequired,
  numOfDays: PropTypes.number.isRequired,
  numOfDaysPerPage: PropTypes.number,
  scrollToTime: PropTypes.string,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start: PropTypes.string.isRequired,
      finish: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  actions: PropTypes.object.isRequired,
}

export default AppointmentCalendar
