import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chatterbug/aaron'

import { ThemeProvider } from 'src/config/Providers'
import LanguageCardWithAvatar from 'src/ui/LanguageCardWithAvatar/LanguageCardWithAvatar'
import LanguageButton from 'src/ui/LanguageButton/LanguageButton'
import { t } from 'src/lib/i18n-react'

class ChooseLanguagePage extends React.Component {
  render() {
    const { onLanguageSelect, targetLanguage, withOther } = this.props

    return (
      // to override the global old theme I am using a nested new theme
      <ThemeProvider version={2}>
        <LanguageCardWithAvatar
          languageCode={targetLanguage.code}
          title={t('Which language do you speak best?')}
          description={t(
            'You can learn <strong>%{lang}</strong> using one of the following languages',
            {
              lang: targetLanguage.localizedName,
            }
          )}
        >
          <Flex
            as="ul"
            pb="3x"
            sx={{
              listStyle: 'none',
              padding: 0,
            }}
            flexDirection="column"
            alignItems="center"
            data-testid="l1-selection-form"
          >
            {targetLanguage.pairedNativeLanguages.map((lang, index) => (
              <Box as="li" key={lang.code} mt={index > 0 ? '2x' : 0}>
                <LanguageButton code={lang.code} onClick={onLanguageSelect}>
                  {lang.localizedName}
                </LanguageButton>
              </Box>
            ))}
            {withOther && (
              <>
                <Box
                  my="3x"
                  sx={{
                    width: '36px',
                    height: '1px',
                    backgroundColor: 'gray88',
                  }}
                />
                <Box as="li">
                  <LanguageButton code="other" onClick={onLanguageSelect}>
                    {t('Other')}
                  </LanguageButton>
                </Box>
              </>
            )}
          </Flex>
        </LanguageCardWithAvatar>
      </ThemeProvider>
    )
  }
}

ChooseLanguagePage.propTypes = {
  withOther: PropTypes.bool,
  onLanguageSelect: PropTypes.func.isRequired,
  targetLanguage: PropTypes.object.isRequired,
}

export default ChooseLanguagePage
