import React from 'react'
import PropTypes from 'prop-types'

import { Text, Card, Image } from '@chatterbug/aaron'

const PrivacyBee = ({ selected, imgSrc, title, description, ...rest }) => {
  return (
    <Card
      borderRadius="lg"
      fontSize="sm"
      boxShadow="sm"
      p="md"
      pb={['xxl', null, 0]}
      bg="white"
      border={selected ? '2px solid #00e6c2' : '2px solid transparent'}
      css={`
        cursor: pointer;
        &:hover {
          img {
            opacity: 1;
            bottom: 20%;
            transition: all 0.3s ease;
          }
        }
      `}
      {...rest}
    >
      {/* to take up some space in the card, so that the bees are halfway in the card */}
      <Card
        height="60px"
        mb="md"
        css={`
          position: relative;
        `}
      >
        <Image
          src={imgSrc}
          width="200px"
          opacity={selected ? 1 : 0.6}
          css={`
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -100px;
          `}
        />
      </Card>
      <Text mb="md" fontSize="md" fontWeight="bold">
        {title}
      </Text>
      <Text>{description}</Text>
    </Card>
  )
}

PrivacyBee.propTypes = {
  selected: PropTypes.bool.isRequired,
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default PrivacyBee
