import { Controller } from 'stimulus'
import Hls from 'hls.js'

export default class StreamsVideoController extends Controller {
  static values = {
    sendViewingDataUrl: String,
    interactionTry: String,
    sourceUrl: String,
    completedUrl: String,
    videoId: Number
  }

  connect() {
    console.log('StreamsVideoController connected')

    this.video = document.getElementById("stream-video")
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(this.sourceUrlValue);
      hls.attachMedia(this.video);
    } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      this.video.src = this.sourceUrlValue;
    }

    this.interval = setInterval(this.updateInteraction.bind(this), 1000)
    this.interactionTime = 0

    let url = new URL(window.location.href)
    console.log(url.hash)
    if(url.hash) {
      this.video.currentTime = parseInt(url.hash.replace('#',''))
    }
  }

  disconnect() {
    clearInterval(this.interval)
  }

  setSpeed(event) {
    this.video.playbackRate = event.target.dataset.speed
  }

  seekTo(event) {
    this.video.currentTime = event.target.dataset.time
  }

  // find video showing in the time range, replace the div if not matching
  updateInteraction() {
    let currentTime = this.video.currentTime
    let lengthOfVideo = this.video.duration
    if (!this.video.paused) {
      this.interactionTime += 1

      // if we have a final url to redirect to and the video is over, redirect
      if((currentTime > lengthOfVideo - 5) && (this.completedUrlValue)) {
        window.location.href = this.completedUrlValue
      }

      if (this.interactionTime % 5 == 0) { // only send every 5 seconds
        this.sendViewingData(currentTime, this.interactionTime)
      }
    }
    this.changeSlide(currentTime)
  }

  sendViewingData(currentTime, interactionTime) {
    if (this.sendViewingDataUrlValue.length < 6) { return false } // probably 'false'
    let formData = new FormData();
    formData.append('video_id', this.videoIdValue)
    formData.append('current_time', currentTime)
    formData.append('interaction_time', interactionTime)
    fetch(this.sendViewingDataUrlValue, {
      method: 'POST',
      body: formData
    })
  }

  changeSlide(currentTime) {
    let currentShowing = document.querySelector('#current-showing')
    let showings = document.querySelectorAll('#video-showings > .showing')
    showings.forEach(element => {
      let startVal = element.attributes['data-start'].value
      let endVal = element.attributes['data-end'].value
      if ( (startVal <= currentTime) && ((endVal >= currentTime) || (endVal == 0)) ) {
        let newId = element.attributes['id'].value
        if (this.currentId != newId) {
          currentShowing.innerHTML = element.innerHTML
          this.currentId = newId
        }
      }
    })
  }

  interactionTry() {
    this.showWarning()
  }

  showWarning() {
    var modal = document.getElementById(this.interactionTryValue)
    modal.style.display = "block"
  }

  hideWarning() {
    var modal = document.getElementById(this.interactionTryValue)
    modal.style.display = "none"
  }

}