import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import { classString } from 'lib/util'

export default class ColorPill extends React.Component {
  UNSAFE_componentWillMount() {
    const id = _.uniqueId('prefix-')
    this.setState({ tooltipId: id })
  }

  render() {
    const tooltipId = this.state.tooltipId
    const { content, tooltip, pillClass } = this.props
    const classes = classString([
      'color-pill',
      `color-pill--${pillClass || content}`,
    ])
    return (
      <span className={classes} data-tip={tooltip} data-for={tooltipId}>
        {content}
        {tooltip && <ReactTooltip id={tooltipId} className="react-tooltip" />}
      </span>
    )
  }
}

ColorPill.propTypes = {
  content: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  pillClass: PropTypes.string,
}
