/* eslint-disable react/jsx-no-bind */
/* global paypal */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { redirectToHREF, post } from 'lib/util'

export default class BundleButton extends React.Component {
  constructor(props) {
    super(props)
    this.Button = paypal.Button.driver('react', { React, ReactDOM })
  }

  static propTypes = {
    env: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    clientID: PropTypes.string.isRequired,
    successPath: PropTypes.string.isRequired,
    paymentPath: PropTypes.string.isRequired,
    custom: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }

  handlePayment(data, actions) {
    return actions.payment.create({
      transactions: [
        {
          custom: this.props.custom,
          amount: { total: this.props.total, currency: 'EUR' },
        },
      ],
    })
  }

  handleAuthorize(data, actions) {
    const params = {
      bundle: { payment_id: data.paymentID, payer_id: data.payerID },
    }
    post(this.props.paymentPath, params).then(({ data, status }) => {
      if (status === 'success') {
        redirectToHREF(this.props.successPath)
      } else {
        console.log('Error with payment', status, data)
      }
    })
  }

  handleCancel(data) {
    console.log('The payment was cancelled: ' + data.paymentID)
  }

  render() {
    let client = {}
    client[this.props.env] = this.props.clientID

    const style = {
      label: 'pay',
      size: 'responsive',
      shape: 'pill',
      color: 'gold',
    }

    return (
      <this.Button
        commit
        env={this.props.env}
        locale={this.props.locale}
        client={client}
        style={style}
        payment={this.handlePayment.bind(this)}
        onAuthorize={this.handleAuthorize.bind(this)}
        onCancel={this.handleCancel}
      />
    )
  }
}
