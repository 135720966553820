import React from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'

import deCharacterJSON from './animations/de.json'
import enCharacterJSON from './animations/en.json'
import esCharacterJSON from './animations/es.json'
import frCharacterJSON from './animations/fr.json'
import otherJSON from './animations/other.json'
import deBubbleJSON from './animations/de-bubble.json'
import enBubbleJSON from './animations/en-bubble.json'
import esBubbleJSON from './animations/es-bubble.json'
import frBubbleJSON from './animations/fr-bubble.json'

const languageCodeCharacters = {
  de: deCharacterJSON,
  en: enCharacterJSON,
  es: esCharacterJSON,
  fr: frCharacterJSON,
  other: otherJSON,
}

const languageCodeBubbles = {
  de: deBubbleJSON,
  en: enBubbleJSON,
  es: esBubbleJSON,
  fr: frBubbleJSON,
}

class LanguageCharacter extends React.Component {
  componentDidMount() {
    if (!languageCodeCharacters[this.props.languageCode]) {
      return
    }

    const characterData = { ...languageCodeCharacters[this.props.languageCode] }
    this.characterAnimation = lottie.loadAnimation({
      container: this.characterRef,
      animationData: characterData,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    })

    if (this.props.languageCode !== 'other') {
      const bubbleData = { ...languageCodeBubbles[this.props.languageCode] }
      this.bubbleAnimation = lottie.loadAnimation({
        container: this.bubbleRef,
        animationData: bubbleData,
        renderer: 'svg',
        loop: false,
        autoplay: false,
      })
    }

    // Start the animation immediately if it's created already playing
    this.props.isPlaying && this.componentDidUpdate()
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPlaying === prevProps.isPlaying) {
      return
    }
    if (this.props.isPlaying && this.characterAnimation) {
      this.playAnimation()
    } else {
      this.stopAnimation()
    }
  }

  playAnimation = () => {
    this.characterAnimation && this.characterAnimation.play()

    if (this.bubbleAnimation) {
      this.bubbleAnimation.setDirection(1)
      this.bubbleAnimation.goToAndPlay(8, true)
    }
  }

  stopAnimation = () => {
    this.characterAnimation && this.characterAnimation.stop()

    if (this.bubbleAnimation) {
      this.bubbleAnimation.setDirection(-1)
      this.bubbleAnimation.goToAndPlay(16, true)
    }
  }

  render() {
    const { languageCode } = this.props

    return (
      <>
        <div
          ref={(ref) => {
            this.characterRef = ref
          }}
          className="language-character"
        ></div>
        <div
          ref={(ref) => {
            this.bubbleRef = ref
          }}
          className={`language-character__bubble language-character__bubble-${languageCode}`}
        />
      </>
    )
  }
}

LanguageCharacter.propTypes = {
  languageCode: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool.isRequired,
}

export default LanguageCharacter
