import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DateTime } from 'luxon'

import Avatar from 'src/components/Avatar/Avatar'
import Flag from 'src/components/Flag/Flag'
import { t } from 'src/lib/i18n-react'

import Action from './subcomponents/Action/Action'

const Item = (props) => {
  const {
    status,
    avatarURL,
    languageCode,
    login,
    start,
    finish,
    actions,
    inline,
    isPreviewable,
    isMine,
    ...others
  } = props

  const themes = (status) => {
    return {
      past: {
        Container: SC.ContainerPast,
        actionNames: ['view'],
      },
      pending_me: {
        Container: SC.ContainerPending,
        actionNames: ['confirm', 'reject'],
      },
      pending_partner: {
        Container: SC.ContainerPending,
        actionNames: ['reject'],
      },
      confirmed: {
        Container: SC.ContainerConfirmed,
        actionNames: isPreviewable ? ['preview', 'cancel'] : ['cancel'],
      },
      bookable: {
        Container: SC.ContainerPending,
        actionNames: ['book'],
      },
      other: {
        Container: SC.ContainerConfirmed,
        actionNames: [],
      },
    }[status]
  }

  let theme
  if (isMine) {
    theme = {
      Container: SC.ContainerMine,
      actionNames: [],
    }
  } else {
    theme = themes(status)
  }
  const { Container, actionNames } = theme

  const renderLogin = () => {
    if (isMine) {
      return t('You')
    } else {
      return status === 'pending_partner' ? t('Pending partner...') : login
    }
  }

  return (
    <Container {...others}>
      <SC.Left>
        {inline ? (
          <Flag size="s" countryCode={languageCode} />
        ) : (
          <Avatar url={avatarURL} size="m" languageCode={languageCode} />
        )}
      </SC.Left>
      <SC.Middle>
        {renderLogin()}
        {inline ? ' – ' : <br />}
        <span>
          {status === 'past'
            ? 'Done'
            : `${DateTime.fromISO(start).toLocaleString(DateTime.TIME_SIMPLE)}
               —
              ${DateTime.fromISO(finish).toLocaleString(DateTime.TIME_SIMPLE)}`}
        </span>
      </SC.Middle>
      <SC.Right>
        {actions &&
          actionNames.map((actionName) => (
            <Action
              key={`action-${actionName}`}
              type={actionName}
              onClick={() => {
                if (!actions[actionName]) {
                  console.warn(
                    `You have not defined an 'actions' function for action ${actionName}`
                  )
                }
                actions[actionName](props)
              }}
            />
          ))}
      </SC.Right>
    </Container>
  )
}

const SC = {}
SC.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-left: 6px;
  line-height: 1em;
  height: 100%;
  text-align: left;
  overflow: hidden;
  border-bottom: 1px #f4f7f9 solid;
`
SC.ContainerPast = styled(SC.Container)`
  background: #cfd8de;
  color: #fff;
`
SC.ContainerConfirmed = styled(SC.Container)`
  background: #18d9f3;
  color: #303030;
`
SC.ContainerPending = styled(SC.Container)`
  background: #fff;
  color: #303030;
  border: 1px solid ${(props) => props.theme.colors.blue};
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.07);
`
SC.ContainerMine = styled(SC.Container)`
  background: ${(props) => props.theme.colors.purple[0]};
  color: #303030;
`
SC.Left = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 28px;
`
SC.Middle = styled.div`
  flex: 1;
  margin: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: semibold;
  span {
    font-weight: normal;
    font-size: 10px;
  }
`
SC.Right = styled.div`
  display: flex;
  flex-direction: row;
`

Item.defaultProps = {
  inline: false,
  isMine: false,
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  start: PropTypes.string.isRequired,
  finish: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    'past',
    'pending_me',
    'pending_partner',
    'confirmed',
    'canceled',
    'bookable',
    'other',
  ]).isRequired,
  avatarURL: PropTypes.string,
  languageCode: PropTypes.string,
  login: PropTypes.string,
  actions: PropTypes.shape({
    view: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    preview: PropTypes.func.isRequired,
    book: PropTypes.func.isRequired,
  }).isRequired,
  inline: PropTypes.bool,
  isPreviewable: PropTypes.bool,
  isMine: PropTypes.bool,
}

export default Item
