import { Controller } from 'stimulus'
import moment from 'moment'
export default class VideoSearchController extends Controller {
  static values = {
    searchUrl: String,
  }
  static targets = ["results", "sidebar", "info", "term", "facets"]

  connect() {
    this.lastSearch = 0
    this.facets = {}
    this.term = ""
  }

  // user submits a search term
  search(e) {
    e.preventDefault()
    let term = this.termTarget.value
    let ts = Math.round(Date.now() / 1000) // seconds
    if ((ts - this.lastSearch) < 1) { return false } // dont search more than once per second
    this.lastSearch = ts
    this.term = term
    this.searchForTerm()
  }

  // run search for the term and facets
  searchForTerm() {
    console.log(this.facets)
    this.infoTarget.innerHTML = "Searching..."
    let formData = new FormData();
    formData.append('term', this.term)
    formData.append('facets', JSON.stringify(this.facets))
    fetch(this.searchUrlValue, {
      method: 'POST',
      body: formData
    }).then(response => response.json())
      .then(data => {
        this.renderResults(data)
      })
  }

  toggleFacet(e) {
    e.preventDefault()
    let element = e.target
    let aggType = element.dataset.aggType // eg: "language_code"
    let value = element.innerHTML // eg: "en"
    value = value.replace(/ /g, "_")
    let facet = `${aggType}:${value}`
    if (this.facets[facet]) {
      this.facets[facet] = false
    } else {
      this.facets[facet] = true
    }

    // facets HTML
    let facetsDiv = document.createElement("div")
    facetsDiv.classList.add("facets-applied")
    for (const facet in this.facets) {
      if (this.facets[facet]) {
        let parts = facet.split(":")
        facetsDiv.innerHTML += `
          <div class="facet-applied">
            ${this.aggTypeToName(parts[0])}:
            <a href="#" data-action="video-search#toggleFacet">
              <span data-agg-type="${parts[0]}" class="agg-key">${parts[1].replace(/_/g, ' ')}</span>
            </a>
          </div>`

      }
    }
    this.facetsTarget.innerHTML = ""
    this.facetsTarget.appendChild(facetsDiv)
    this.searchForTerm(this.term)
  }

  renderResults(data) {
    this.infoTarget.innerHTML = `${data.hits} results <em>(in ${data.timeTaken}ms)</em>`
    this.sidebarTarget.innerHTML = ""
    data.aggregations.forEach(result => {
      if(result.results.length > 0) {
        let aggDiv = document.createElement("div")
        aggDiv.classList.add("video-agg-result")
        aggDiv.innerHTML = `
          <h2>${this.aggTypeToName(result.aggregationType)}</h2>
          <ul>
            ${result.results.map(rez => {
              return `
              <li>
                <span class="agg-count">${rez.docCount}</span>
                <a href="#" data-action="video-search#toggleFacet">
                  <span data-agg-type="${result.aggregationType}" class="agg-key">${rez.key}</span>
                </a>
              </li>`
            }).join("\n")}
          </ul>
        `
        this.sidebarTarget.appendChild(aggDiv)
      }
    })

    this.resultsTarget.innerHTML = ""
    data.results.forEach(result => {
      let video = result.result
      let videoDiv = document.createElement("div")

      // build optional highlights div
      let highlights = document.createElement("div")
      if (result.highlights.length > 0) {
        let highlightsLi = result.highlights.slice(0, 3).map(highlight => {
          return `
          <li>
            <span class="video-highlight-field">${this.fieldToIcon(highlight.field)}</span>
            <span class="video-highlight">"${highlight.html}"</span>
          </li>`
        }).join("\n")
        highlights.innerHTML = `<div class="video-highlights"><ul>${highlightsLi}</ul></div>`
      }

      videoDiv.classList.add("video-search-result")
      videoDiv.innerHTML = `
        <div class="video-thumbnail">
          <img class="video-thumbnail-img" src="${video.thumbnailUrl}">
        </div>
        <div class="video-info">
          <div class="video-meta">
            <div class="video-level">
              ${video.streamsLevel}
            </div>
            <div class="video-streamer">
              ${video.streamer}
            </div>
            <div class="video-aired">
              ${moment(video.startedAt).fromNow()}
            </div>
            <div class="video-duration">
              ${Math.round(video.durationSeconds / 60)} min
            </div>
          </div>
          <div class="video-name">
            <a href="https://app.chatterbug.com/streams/video/${video.id}">${video.name || video.description}</a>
          </div>
          ${highlights.innerHTML}
        </div>
      `
      this.resultsTarget.appendChild(videoDiv)
    })
  }

  /*
  'name',
  'description',
  'learning_items.text',
  'learning_items.subtext',
  'transcripts.text'
  */
  fieldToIcon(field) {
    let mapping = {
      'name': 'fa-text-width',
      'description': 'fa-indent',
      'learning_items.text': 'fa-address-card-o',
      'learning_items.subtext': 'fa-address-card',
      'transcripts.text': 'fa-list',
    }
    if (field in mapping) {
      return `<i class="fa ${mapping[field]}" aria-hidden="true" title="${field}"></i>`
    }
    return field
  }

  aggTypeToName(field) {
    if (field == 'language_code') {
      return "language"
    }
    if (field == 'language_level') {
      return "level"
    }
    if (field == 'video_type') {
      return "type"
    }
    return field
  }
}
