import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { t } from 'src/lib/i18n-react'
import Pill from 'src/components/Pill/Pill'

import Badge from './Badge'

const pillColors = {
  // TODO: switch back to red when penalties kick in
  // warning: 'red-lighter',
  warning: 'orange',
  onboarding: 'green',
}

const LevelBadge = ({ stage, level, active, size, ...others }) => {
  const Container = size === 'medium' ? SC.Container : SC.ContainerLarge

  return (
    <Container active={active} {...others}>
      <span>{t('Level %{tutorLevel}', { tutorLevel: level })}</span>
      {stage && (
        <SC.PillContainer>
          <Pill color={pillColors[stage]}>{stage}</Pill>
        </SC.PillContainer>
      )}
      <Badge
        level={level}
        // TODO switch back to red when penalties kick in
        // fill={stage === 'warning' ? '#FF7070' : undefined}
        fill={stage === 'warning' ? 'orange' : undefined}
      />
    </Container>
  )
}

const SC = {}
SC.Container = styled.div`
  flex: 1;
  align-self: flex-end;
  max-width: 56px;
  opacity: ${({ active }) => (active ? 1 : 0.25)};
  transition: 0.5s ease;
  color: #303030;
  font-weight: semibold;
  text-align: center;
  white-space: nowrap;
  font-size: 15px;
  line-height: 1;

  span {
    display: block;
    margin: 5px 0;
  }
`
SC.ContainerLarge = styled(SC.Container)`
  max-width: 74px;
  font-size: 20px;
  span {
    margin-top: 12px;
  }
`
SC.PillContainer = styled.div`
  margin-bottom: 5px;
`

LevelBadge.defaultProps = {
  size: 'medium',
  active: true,
}

LevelBadge.propTypes = {
  size: PropTypes.oneOf(['medium', 'large']),
  level: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
  active: PropTypes.bool,
  stage: PropTypes.oneOf(['onboarding', 'warning']),
}

export default LevelBadge
