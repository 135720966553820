import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { themeGet } from '@chatterbug/aaron'

export const contrast = (rgb) => {
  // convert `rgb(0, 0, 0)` to an array
  const [r, g, b] = rgb
    .replace('rgb(', '')
    .replace(')', '')
    .split(', ')
  if (
    Math.round((r * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000) > 180
  ) {
    return '#000'
  }
  return '#fff'
}

const Pill = ({ children, theme, color, ...others }) => {
  const background = themeGet('colors.' + color, 'rgb(77, 77, 77)')({ theme })
  if (!themeGet(color, theme)) {
    console.warn(`The color "${color}" does not exist in "_variables.scss"`)
  }

  return (
    <SC.Container
      background={background}
      color={contrast('rgb(77, 77, 77)')}
      {...others}
    >
      {children}
    </SC.Container>
  )
}

const SC = {}
SC.Container = styled.div`
  display: inline-block;
  padding: 0 4px;
  ${({ background, color }) => `
    background: ${background};
    color: ${color};
    `};
  border-radius: 6px;
  text-align: center;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  text-transform: uppercase;
`

Pill.propTypes = {
  color: PropTypes.string.isRequired,
}

export default withTheme(Pill)
