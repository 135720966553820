import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'src/lib/i18n-react'
import Panel from 'components/Panel'

import LanguageCharacter from '../LanguageCharacter/LanguageCharacter'

import LanguageSignupForm from './subcomponents/LanguageSignupForm'
import OtherForm from './subcomponents/OtherForm'
import Terms from './subcomponents/Terms'

class SignupForm extends React.Component {
  renderSignupForm() {
    const {
      nativeLanguageCode,
      languageCode,
      localizedName,
      buttonText,
      offerCode,
      hideLogin,
      email,
      inviteCode,
      role,
      orgId,
      orgCode,
    } = this.props

    const title =
      role === 'tutor'
        ? t('Start Teaching %{localizedName}', { localizedName })
        : t('Start Learning %{localizedName}', { localizedName })
    return (
      <Panel key={languageCode} isActive={languageCode !== 'other'}>
        <div className="signup-form__character">
          <LanguageCharacter isPlaying={false} languageCode={languageCode} />
        </div>
        <div className="signup-form__title">{title}</div>
        <div className="language-signup-form">
          <LanguageSignupForm
            offerCode={offerCode}
            buttonText={buttonText}
            hideLogin={hideLogin}
            email={email}
            inviteCode={inviteCode}
            role={role}
            orgId={orgId}
            orgCode={orgCode}
            nativeLanguageCode={nativeLanguageCode}
            languageCode={languageCode}
          />
        </div>
      </Panel>
    )
  }

  renderOtherForm() {
    const { email, languageCode, languages } = this.props

    return (
      <Panel key="other" isActive={languageCode === 'other'}>
        <div className="signup-form__title">{t('Learn Another Language')}</div>
        <OtherForm email={email} availableLanguages={languages} />
      </Panel>
    )
  }

  renderLoginLink() {
    return (
      <div className="button-bar text_center padding_top">
        <a className="button button_link" href="/login">
          {t('login instead')}
        </a>
      </div>
    )
  }

  render() {
    const {
      languageCode,
      buttonText,
      hideTerms,
      hideTutorCTA,
      hideLogin,
      role,
    } = this.props
    return (
      <div className="signup-form">
        <div className="signup-form__container">
          {languageCode !== 'other' && this.renderSignupForm()}
          {languageCode === 'other' && this.renderOtherForm()}
          {!hideLogin && this.renderLoginLink()}
        </div>
        {!hideTerms && (
          <Terms
            buttonText={languageCode === 'other' ? t('Submit') : buttonText}
            hideTutorCTA={hideTutorCTA || role === 'tutor'}
          />
        )}
      </div>
    )
  }
}

SignupForm.defaultProps = {
  hideTutorCTA: false,
  hideLogin: false,
  hideTerms: false,
}

SignupForm.propTypes = {
  buttonText: PropTypes.string,
  offerCode: PropTypes.string,
  email: PropTypes.string,
  inviteCode: PropTypes.string,
  role: PropTypes.string,
  orgId: PropTypes.string,
  orgCode: PropTypes.string,
  nativeLanguageCode: PropTypes.string.isRequired,
  languageCode: PropTypes.string.isRequired,
  localizedName: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      localizedName: PropTypes.string.isRequired,
    })
  ).isRequired,
  hideTutorCTA: PropTypes.bool,
  hideLogin: PropTypes.bool,
  hideTerms: PropTypes.bool,
}

export default SignupForm
