import { debounce } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { createPopper } from '@popperjs/core'

import CharacterPicker from './CharacterPicker'
import {
  ACTION_SHOW,
  ACTION_SUBMIT,
  ACTION_HIDE,
  ACTION_MOVE_LEFT,
  ACTION_MOVE_RIGHT,
  ACTION_SELECT,
  ACTION_DELETE,
  ACTION_TOGGLE_CASE,
} from './constants'
import reducer from './reducer'

export const keyToActionName = {
  'meta-k': ACTION_SHOW,
  'meta-enter': ACTION_SUBMIT,
  backspace: ACTION_DELETE,
  arrowup: ACTION_SHOW,
  escape: ACTION_HIDE,
  arrowleft: ACTION_MOVE_LEFT,
  arrowright: ACTION_MOVE_RIGHT,
  enter: ACTION_SELECT,
  arrowdown: ACTION_SELECT,
  shift: ACTION_TOGGLE_CASE,
}

export const VISIBILITY_DELAY = 200

export const getCaretOffset = (element) => {
  var range = window.getSelection().getRangeAt(0)
  var cRange = range.cloneRange()
  cRange.selectNodeContents(element)
  cRange.setEnd(range.endContainer, range.endOffset)
  return cRange.toString().length - 1
}

export const getCharLeftOfCaret = (element) => {
  const caretOffset = getCaretOffset(element)
  return element.innerText[caretOffset]
}

// NOTE: `lastCharLength` is added here to deal with special characters that
// are more than a single character.
export const replaceCharLeftOfCaret = (
  element,
  caretOffset,
  newChar,
  lastCharLength = 0
) => {
  const v = element.innerText
  const newCharLength = newChar.length

  const lastCharOffset = lastCharLength > 1 ? lastCharLength - 1 : 0
  element.innerText =
    v.slice(0, caretOffset - lastCharOffset) +
    newChar +
    v.slice(caretOffset + 1)
  // restore the cursor
  setCaretOffset(element, caretOffset + newCharLength - lastCharOffset)
}

export const setCaretOffset = (element, caretOffset) => {
  const selection = window.getSelection()
  selection.collapse(element.firstChild, caretOffset)
}

// TODO: Make this component aware of the characters that we're
// inserting. Both special and normal so that we're able to deal with
// more complicated characters like the uppercase ß (SS):
//
// E.g.: [ { char: 'S' }, { char: 'P'}, { char: 'A' }, { char: 'SS', special: true }, ]
class SpecialCharacterChooser extends React.Component {
  state = {
    visible: false,
    baseChar: undefined,
    charPosition: 0,
  }

  componentDidMount() {
    this.props.element.addEventListener('keydown', this.onKeyDownEvent)
    this.props.element.addEventListener('keyup', this.onKeyUpEvent)
  }

  componentWillUnmount() {
    this.props.element.removeEventListener('keydown', this.onKeyDownEvent)
    this.props.element.removeEventListener('keyup', this.onKeyUpEvent)
  }

  componentDidUpdate() {
    if (this.picker) {
      const { element } = this.props
      // Duplicate the `element` and substr the text at the caret, so that we
      // can calculate the placement of the picker
      this.cloneElement = element.cloneNode()
      this.cloneElement.innerText = element.innerText.slice(
        0,
        getCaretOffset(element) + 1
      )
      this.cloneElement.style['z-index'] = -1
      this.cloneElement.style['visibility'] = 'none'
      this.cloneElement.style['position'] = 'absolute'
      this.cloneElement.style['padding-right'] = 0
      this.cloneElement.style['top'] = element.offsetTop + 'px'
      this.cloneElement.style['left'] = element.offsetLeft + 'px'
      element.parentNode.appendChild(this.cloneElement)

      this.popper = createPopper(this.cloneElement, this.picker, {
        placement: 'top-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.getCharacters().length * 40, 0],
            },
          },
        ],
      })
      document.addEventListener('click', this.hide, { once: true })
    } else if (this.popper) {
      this.popper.destroy()
      this.cloneElement &&
        this.cloneElement.parentNode &&
        this.cloneElement.parentNode.removeChild(this.cloneElement)
      document.removeEventListener('click', this.hide)
    }
  }

  getBaseChar = (char) => {
    if (this.props.charMap[char]) {
      return char
    }

    if (char !== "'") {
      return Object.keys(this.props.charMap).find(
        (baseChar) => this.props.charMap[baseChar].indexOf(char) !== -1
      )
    }
  }

  getCharPosition = ({ charLeftOfCaret, baseChar }) => {
    return charLeftOfCaret === baseChar
      ? 0
      : this.props.charMap[baseChar].split(' ').indexOf(charLeftOfCaret) + 1
  }

  handleIdleKeyEvent = (e, key) => {
    this.lastCharLength = 0
    this.showPickerDebounce && this.showPickerDebounce.cancel()
    const charLeftOfCaret = getCharLeftOfCaret(this.props.element)

    if (typeof charLeftOfCaret === 'undefined') {
      return
    }

    const baseChar = this.getBaseChar(charLeftOfCaret.toLowerCase())
    if (typeof baseChar === 'undefined') {
      return
    }

    const { ctrlKey, metaKey } = e
    const actionType = this.getActionType(key, ctrlKey || metaKey)
    if (
      typeof actionType !== 'undefined' &&
      ![ACTION_DELETE, ACTION_SHOW].includes(actionType)
    ) {
      return
    }

    const newState = {
      baseChar: baseChar,
      upperCase: charLeftOfCaret === charLeftOfCaret.toUpperCase(),
      visible: true,
      charPosition: this.getCharPosition({
        charLeftOfCaret: charLeftOfCaret.toLowerCase(),
        baseChar,
      }),
    }

    // Show immediately
    if (actionType === ACTION_SHOW) {
      e.stopPropagation()
      e.preventDefault()
      this.setState(newState)
      return
    }

    if (this.props.showAutomatically) {
      this.showPickerDebounce = debounce(
        () => this.setState(newState),
        VISIBILITY_DELAY
      )
      this.showPickerDebounce()
    }
  }

  handleActiveKeyEvent = (e, key) => {
    const actionType = this.getActionType(key)

    if (actionType === ACTION_DELETE) {
      this.hide()
      this.handleIdleKeyEvent(e, key)
      return
    }

    if (actionType === ACTION_SELECT) {
      const newChar = this.getCharacters()[this.getSelectedIndex()]
      e.preventDefault()
      e.stopPropagation()
      replaceCharLeftOfCaret(
        this.props.element,
        this.caretOffset,
        newChar,
        this.lastCharLength
      )
      this.lastCharLength = newChar.length
      this.hide()
      return
    }

    // deal with other visible actions
    e.preventDefault()
    e.stopPropagation()
    this.setState(reducer(this.state, { type: actionType }), () => {
      // live update the character if it is one of the following actions
      if (
        ![ACTION_MOVE_LEFT, ACTION_MOVE_RIGHT, ACTION_TOGGLE_CASE].includes(
          actionType
        )
      ) {
        return
      }

      // We do not have an exact history of what we're adding to the text input
      // so we wing it by remembering the last character we've added. I
      // mistakenly thought we would only add 1 character at a time, but the
      // german `ß` when uppercased is `SS`
      const newChar = this.getCharacters()[this.getSelectedIndex()]
      replaceCharLeftOfCaret(
        this.props.element,
        this.caretOffset,
        newChar,
        this.lastCharLength
      )
      this.lastCharLength = newChar.length
    })
  }

  onKeyDownEvent = (e) => {
    this.showPickerDebounce && this.showPickerDebounce.cancel()

    if (typeof e.key === 'undefined') {
      return
    }
    const key = e.key.toLowerCase()
    const { visible } = this.state

    const { ctrlKey, metaKey } = e
    const actionType = this.getActionType(key, ctrlKey || metaKey)

    // If the picker is visible and the user continues typing then we can hide the picker,
    // the selected character was already inserted.
    if (visible && typeof actionType === 'undefined') {
      this.hide()
    }

    if (
      (visible && actionType && actionType !== ACTION_DELETE) ||
      (!visible && actionType === ACTION_SHOW)
    ) {
      e.preventDefault()
      e.stopPropagation()
      // cmd + k does not cause the `onKeyUpEvent` to fire.
      if (metaKey && key === 'k') {
        this.onKeyUpEvent(e)
      }
    }
  }

  onKeyUpEvent = (e) => {
    if (typeof e.key === 'undefined') {
      return
    }

    const { visible } = this.state
    const { element } = this.props

    if (element.innerText.length === 0) {
      this.hide()
      return
    }

    this.caretOffset = getCaretOffset(element)
    const key = e.key.toLowerCase()

    if (!visible) {
      this.handleIdleKeyEvent(e, key)
    } else {
      this.handleActiveKeyEvent(e, key)
    }
  }

  getActionType = (key, metaKey) => {
    if (this.state.visible && key === 'tab') {
      return ACTION_MOVE_RIGHT
    }

    return keyToActionName[`${metaKey ? 'meta-' : ''}${key}`]
  }

  hide = () => this.setState(reducer(this.state, { type: ACTION_HIDE }))

  getCharacters = () => {
    const { baseChar, upperCase } = this.state
    let characters = `${baseChar} ${this.props.charMap[baseChar]}`
    characters = upperCase ? characters.toUpperCase() : characters.toLowerCase()
    return characters.split(' ')
  }

  getSelectedIndex = () => {
    const { charPosition } = this.state
    const characters = this.getCharacters()
    const charCount = characters.length
    return charPosition < 0
      ? (charCount - (Math.abs(charPosition) % charCount)) % charCount
      : charPosition % charCount
  }

  render() {
    if (!this.state.visible) {
      this.props.willHide && this.props.willHide()
      return null
    }
    this.props.willShow && this.props.willShow()
    const selectedIndex = this.getSelectedIndex()
    return (
      <CharacterPicker
        onRef={(ref) => (this.picker = ref)}
        onSelect={(e, char, charIndex) => {
          e.preventDefault()
          e.stopPropagation()

          if (charIndex !== selectedIndex) {
            replaceCharLeftOfCaret(
              this.props.element,
              this.caretOffset,
              char,
              this.lastCharLength
            )
            this.lastCharLength = char.length
          }
          this.hide()
          if (this.props.element) {
            this.props.element.focus()
          }
        }}
        characters={this.getCharacters()}
        selectedIndex={selectedIndex}
      />
    )
  }
}

SpecialCharacterChooser.propTypes = {
  showAutomatically: PropTypes.bool,
  charMap: PropTypes.object,
  element: PropTypes.object.isRequired,
  willShow: PropTypes.func,
  willHide: PropTypes.func,
}

export default SpecialCharacterChooser
