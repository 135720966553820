import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { t } from 'src/lib/i18n-react'

import GraphPaper from './subcomponents/GraphPaper/GraphPaper'
import LevelBadge from './subcomponents/LevelBadge/LevelBadge'
import Metrics from './subcomponents/Metrics/Metrics'

class TutorLevel extends React.Component {
  constructor(props) {
    super(props)
    const { currentLevel, initialSelectedLevel } = props
    this.state = {
      selectedLevel: initialSelectedLevel || currentLevel,
    }
  }

  handleLevelBadgeClick = (selectedLevel) => {
    this.setState({ selectedLevel })
  }

  render() {
    const { currentLevel, metrics, stage } = this.props
    const { selectedLevel } = this.state
    return (
      <SC.Container>
        <SC.GraphPaper>
          <GraphPaper
            numOfSegments={4}
            numOfNotches={2}
            selectedSegment={selectedLevel}
          />
        </SC.GraphPaper>
        {Array.from({ length: 4 })
          .map((_, index) => index + 1)
          .map((level) => ({
            level,
            size: level === currentLevel ? 'large' : 'medium',
            active: level === selectedLevel,
            stage: level === 1 && stage ? stage : undefined,
          }))
          .map(({ level, size, active, stage }) => (
            <SC.LevelBadge level={level} key={`level-badge-${level}`}>
              <LevelBadge
                stage={stage}
                level={level}
                active={active}
                size={size}
                onClick={() => this.handleLevelBadgeClick(level)}
              />
            </SC.LevelBadge>
          ))}
        <SC.CurrentHeading
          level={currentLevel}
          selected={currentLevel === selectedLevel}
        >
          <div>{t('Current')}</div>
        </SC.CurrentHeading>
        <SC.MetricsHeading>{t('Value')}</SC.MetricsHeading>
        <SC.Metrics>
          <Metrics metrics={metrics} selectedLevel={selectedLevel} />
        </SC.Metrics>
        <SC.ValueHeading>{t('Current/Goal')}</SC.ValueHeading>
      </SC.Container>
    )
  }
}

// the max width of the large badge.
const BADGE_MAX_WIDTH = 74

const SC = {}
SC.Container = styled.div`
  display: grid;
  grid-template-rows:
    [levels] 130px
    [headings] 60px
    [notches] 20px
    [metrics] auto;
  grid-template-columns:
    [metrics] 1.2fr
    [level1] 1fr
    [level2] 1fr
    [level3] 1fr
    [level4] 1fr
    [stats] 1.2fr;
`
SC.LevelBadge = styled.div`
  cursor: pointer;
  grid-row: levels;
  grid-column: ${({ level }) => `level${level}`};
  z-index: 2;
  justify-self: flex-end;
  width: ${BADGE_MAX_WIDTH}px;
  margin-right: -${BADGE_MAX_WIDTH / 2}px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;

  &:active {
    transform: scale(0.8);
  }
`
SC.CurrentHeading = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  grid-row: headings;
  grid-column: ${({ level }) => `level${level}`};
  z-index: 2;
  justify-self: flex-end;
  width: ${BADGE_MAX_WIDTH}px;
  margin-right: -${BADGE_MAX_WIDTH / 2}px;
  text-align: center;

  div {
    line-height: 35px;
    padding: 5px;
    font-size: 12px;
    background: #fafcfc;
    color: ${({ selected }) => (selected ? '#000' : '#A6C0C6')};
  }
`
SC.MetricsHeading = styled.div`
  grid-row: headings;
  grid-column: metrics;
  text-align: right;
  line-height: 60px;
  font-size: 12px;
  padding: 5px 20%;
`
SC.ValueHeading = styled.div`
  grid-row: headings;
  grid-column: stats;
  text-align: left;
  line-height: 60px;
  font-size: 12px;
  padding: 5px 20%;
`
SC.GraphPaper = styled.div`
  grid-column: level1 / stats;
  grid-row: headings / -1;
  z-index: 1;
`
SC.Metrics = styled.div`
  grid-column: 1 / -1;
  grid-row: metrics;
`

TutorLevel.propTypes = {
  currentLevel: PropTypes.number.isRequired,
  stage: PropTypes.oneOf(['warning', 'onboarding']),
  metrics: Metrics.propTypes.metrics,
  initialSelectedLevel: PropTypes.number, // defaults to currentLevel
}

export default TutorLevel
