import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import PageLayout from 'src/ui/PageLayout/PageLayout'
import { translate } from 'src/lib/translate'

const Page = lazy(() =>
  import(
    /* webpackChunkName: 'LiveLessonReplay' */ 'src/pages/LiveLesson/subpages/Replay/Replay'
  )
)
class LoadPageLiveLessonReplay extends React.Component {
  handleTextChatTranslateClick = (phrase) => {
    const { sessionId } = this.props
    return translate({ phrase, sessionId })
  }

  render() {
    return (
      <PageLayout>
        <Suspense fallback={null}>
          <Page
            {...this.props}
            onTextChatTranslateClick={this.handleTextChatTranslateClick}
          />
        </Suspense>
      </PageLayout>
    )
  }
}

LoadPageLiveLessonReplay.propTypes = {
  sessionId: PropTypes.string.isRequired,
}

export default LoadPageLiveLessonReplay
