import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Creatable } from 'react-select'
import jstz from 'jstz'

import { post } from 'lib/util'
import { t, tWithEscape } from 'src/lib/i18n-react'

import FormGroup from './FormGroup'

class OtherForm extends React.Component {
  constructor(props) {
    super()

    this.state = {
      languages: [],
      email: props.email,
      name: props.name,
      emailErrors: '',
      languagesErrors: '',
      status: 'pending',
    }
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (!this.validateForm()) {
      return
    }

    let formData = {
      name: this.state.name,
      email: this.state.email,
      native: navigator.language,
      foreign: this.state.languages.map((x) => x.label).join(','),
      l2: this.state.languages.map((x) => x.value).join(','),
      browser: navigator.userAgent,
      timezone: jstz.determine().name(),
    }

    const URL = '/register'
    post(URL, formData, 'json').then(({ data, status }) => {
      if (status === 'success') {
        this.setState({ status: data.result })
      } else {
        this.setState({ status: 'failure' })
      }
    })
  }

  languageOptions = () => {
    const languagesByCode = _.keyBy(this.props.availableLanguages, 'code')
    return _.filter(
      [
        { value: 'zh', label: t('Chinese') },
        { value: 'en', label: t('English') },
        { value: 'fr', label: t('French') },
        { value: 'it', label: t('Italian') },
        { value: 'ja', label: t('Japanese') },
        { value: 'ru', label: t('Russian') },
      ],
      (obj) => !languagesByCode[obj.value]
    )
  }

  logChange = (val) => {
    this.setState({ languages: val })
  }

  validateForm = () => {
    const { email, languages } = this.state

    let valid = true

    if (!email.match(/.+@.+\..+/)) {
      this.setState({
        emailErrors: t('must be in the format: you@example.com'),
      })
      valid = false
    }

    if (!languages.length > 0) {
      this.setState({ languagesErrors: t('must not be empty') })
      valid = false
    }

    return valid
  }

  renderForm = () => {
    const { availableLanguages } = this.props
    const { languages, name, email, languagesErrors, emailErrors } = this.state

    const availableStr = _.map(availableLanguages, (l, i) =>
      availableLanguages.length > 1 && i === availableLanguages.length - 1
        ? `${t('and')} ${l.localizedName}`
        : l.localizedName
    ).join(availableLanguages.length === 2 ? ' ' : ', ')

    return (
      <div>
        <form className="signup-form__form" onSubmit={this.handleSubmit}>
          <div
            className="signup-form__instructions"
            dangerouslySetInnerHTML={{
              __html: tWithEscape(
                `We currently only have <strong>%{availableStr}</strong> curriculum
            ready. Let us know which languages you want to learn most, and we'll
            email you when they’re available!`,
                { availableStr }
              ),
            }}
          />
          <FormGroup name={t('Languages')} errors={languagesErrors}>
            <label htmlFor="languages">
              {t('Which languages do you want to learn?')}
            </label>
            <Creatable
              multi
              joinValues
              name="languages"
              value={languages}
              placeholder={t('Select or type...')}
              options={this.languageOptions()}
              onChange={this.logChange}
            />
          </FormGroup>

          <FormGroup name={t('Name')}>
            <label htmlFor="email">{t('Name')}</label>
            <input
              type="text"
              name="name"
              value={name}
              className="form-control"
              placeholder={t('Your name')}
              onChange={this.handleTextChange}
            />
          </FormGroup>

          <FormGroup name={t('Email address')} errors={emailErrors}>
            <label htmlFor="email">{t('Email Address')}</label>
            <input
              type="email"
              name="email"
              value={email}
              className="form-control"
              placeholder={t('Your email')}
              onChange={this.handleTextChange}
            />
          </FormGroup>

          <div className="button-bar text_center">
            <input
              type="submit"
              id="signup-submit"
              className="button button_cta button_large"
              value={t('Submit')}
            />
          </div>
        </form>
      </div>
    )
  }

  renderStatus = (title, message) => {
    return (
      <div className="signup-form__form">
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    )
  }

  render() {
    const { status } = this.state

    if (status === 'pending') {
      return this.renderForm()
    } else if (status === 'success') {
      return this.renderStatus(
        t('Woohoo!'),
        t("We'll let you know when any of those languages become available.")
      )
    } else if (status === 'duplicate') {
      return this.renderStatus(
        t('Wow!'),
        t(
          "Looks like you've already signed up with that email. You must be really excited!"
        )
      )
    } else if (status === 'failure') {
      return this.renderStatus(
        t('Oh no!'),
        t("Something went wrong and we weren't able to collect your details.")
      )
    }
  }
}

OtherForm.defaultProps = {
  email: '',
  name: '',
}

OtherForm.propTypes = {
  email: PropTypes.string,
  name: PropTypes.string,
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      localizedName: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default OtherForm
