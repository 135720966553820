import React from 'react'
import PropTypes from 'prop-types'

import { t, tDangerouslyUnescapedValues } from 'src/lib/i18n-react'

class Terms extends React.Component {
  renderTutorLink = () => {
    if (!this.props.hideTutorCTA) {
      const applyNow = `<a href="https://chatterbug.breezy.hr/p/08bdff7bccd401-freelance-language-tutor" target="_blank" rel="noopener noreferrer">${t('Apply now!')}</a>`

      return (
        <div
          className="signup-form__terms"
          dangerouslySetInnerHTML={{
            __html: tDangerouslyUnescapedValues(
              'Want to become a tutor? %{applyNow}',
              { applyNow }
            ),
          }}
        />
      )
    }
  }

  render() {
    const { buttonText } = this.props
    const termsOfService = `<a href="https://chatterbug.com/en/legal/terms">${t(
      'Terms of Service'
    )}</a>`
    const privacyPolicy = `<a href="https://chatterbug.com/en/legal/privacy">${t(
      'Privacy Policy'
    )}</a>`

    return (
      <div>
        <div
          className="signup-form__terms"
          dangerouslySetInnerHTML={{
            __html: tDangerouslyUnescapedValues(
              'By clicking "%{buttonText}", you agree to our %{termsOfService} and %{privacyPolicy}.',
              { buttonText, termsOfService, privacyPolicy }
            ),
          }}
        />
        {this.renderTutorLink()}
      </div>
    )
  }
}

Terms.defaultProps = {
  buttonText: t('Sign Up'),
  hideTutorCTA: false,
}

Terms.propTypes = {
  buttonText: PropTypes.string,
  hideTutorCTA: PropTypes.bool,
}

export default Terms
