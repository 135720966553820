import React from 'react'
import ReactRailsUJS from 'react_ujs'
import { Turbo, cable } from '@hotwired/turbo-rails'

import 'src/lib/react-host'

import './helpers/application'
import { isAdminPage } from './helpers/admin'

import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import LoadMainMenu from 'src/routes/LoadMainMenu'
import LayoutBridge from 'src/ui/LayoutBridge'
import GiftVoucherModal from 'src/ui/Modals/GiftVoucherModal/GiftVoucherModal'
import BannerOfferCountdown from 'src/ui/Banner/BannerOfferCountdown/BannerOfferCountdown'
import ProgressWidget from 'src/components/ProgressWidget'
import LanguageSelector from 'src/ui/LanguageSelector/LanguageSelector'

import Providers from 'src/config/Providers.js'

global.LoadMainMenu = LoadMainMenu
global.GiftVoucherModal = GiftVoucherModal
global.BannerOfferCountdown = BannerOfferCountdown
global.LayoutBridge = LayoutBridge
global.ProgressWidget = ProgressWidget
global.LanguageSelector = LanguageSelector


// Connect non-admin stimulus controllers
import { Application } from 'stimulus'
import CountdownController from 'controllers/countdown_controller'
import DirectUploadsController from 'controllers/direct_uploads_controller'
import HlsVideoController from 'controllers/hls_video_controller.js'
import Select2Controller from 'controllers/select2_controller'
import ShortsViewController from 'controllers/shorts_view_controller'
import StreamsVideoController from 'controllers/streams_video_controller'
import StudyGroupController from 'controllers/study_group_controller'
import VideoMediaController from 'controllers/video_media_controller'
import VideoSearchController from 'controllers/video_search_controller'

const application = Application.start()
application.register('countdown', CountdownController)
application.register('direct-uploads', DirectUploadsController)
application.register('hls-video', HlsVideoController)
application.register('select2', Select2Controller)
application.register('shorts-view', ShortsViewController)
application.register('streams-video', StreamsVideoController)
application.register('study-group', StudyGroupController)
application.register('video-media', VideoMediaController)
application.register('video-search', VideoSearchController)

// Import everything under `components/` and make it findable to the
// `react_component` view helper.

if (!isAdminPage) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  ReactRailsUJS.useContext(require.context('src/components', true))

  // Modify the `react_component` component constructor to wrap our React components
  // in our providers.

  const originalConstructor = ReactRailsUJS.getConstructor

  ReactRailsUJS.getConstructor = (className) => (props) => {
    const componentsWithTheme2 = ['LoadMainMenu', 'GiftVoucherModal']
    return (
      <ErrorBoundary>
        <Providers version={componentsWithTheme2.includes(className) ? 2 : 1}>
          {React.createElement(originalConstructor(className), props)}
        </Providers>
      </ErrorBoundary>
    )
  }
}
