/* eslint-disable react/jsx-no-bind */
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'

import { bindMethods } from 'lib/util'
import { t } from 'src/lib/i18n-react'

// A collection of dropdowns showing a date that the user can edit.

export default class DateDropdown extends React.Component {
  static className = 'date-dropdown'
  static propTypes = {
    date: PropTypes.object.isRequired, // Moment object!
    onChange: PropTypes.func.isRequired,

    // Optional
    showWeek: PropTypes.bool,
  }

  constructor(element, options) {
    super()
    bindMethods(this)
    this.state = {
      day: null,
      week: null,
      month: null,
      year: null,
    }
  }

  // this.getSelectedDate('day', 12)
  getSelectedDate(dimension, value) {
    const { showWeek } = this.props
    const state = _.assign({}, this.state, { [dimension]: value })
    const { day, week, month, year } = state
    const compDay = showWeek ? week : day
    const compMonth = month + 1 // Moment months are 0 based; e.g. 0 === January
    const compYear = year

    // if they have the 30th selected and change the month to feb, clamp on the
    // last day of the month
    const date = moment(`${compYear}-${compMonth}`, 'YYYY-MM')
    date.date(Math.min(date.daysInMonth(), compDay))
    return date
  }

  updateDateState(props) {
    const { date } = props
    this.setState({
      day: date.date(),
      week: this.getWeekValue(date),
      month: date.month(),
      year: date.year(),
    })
  }

  // Model the values of all dropdowns so we dont need to use refs when one is
  // updated... This way we can compute the entire date from the state and not
  // ask the DOM anything.
  UNSAFE_componentWillMount() {
    this.updateDateState(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { date } = newProps
    if (!date.isSame(this.props.date)) this.updateDateState(newProps)
  }

  getWeekValue(date) {
    const dayOfMonth = date.date()
    if (dayOfMonth <= 7) return 1
    if (dayOfMonth > 7 && dayOfMonth <= 21) return 15
    return 28
  }

  handleChangeDate(dimension, e) {
    const { onChange } = this.props
    const value = parseInt(e.target.value)
    const date = this.getSelectedDate(dimension, value)
    onChange(date)
  }

  renderOptions(name, keyValuePairs) {
    return keyValuePairs.map(([value, text]) => (
      <option key={`${name}-${value}`} value={value}>
        {text}
      </option>
    ))
  }

  renderDay() {
    const { date } = this.props
    const { day } = this.state
    const daysInMonth = date.daysInMonth()
    const dayOptions = _.times(daysInMonth, (i) => [i + 1, i + 1])
    return (
      <select
        className="form-control date-dropdown__day"
        name="day"
        value={day}
        onChange={this.handleChangeDate.bind(this, 'day')}
      >
        {this.renderOptions('day', dayOptions)}
      </select>
    )
  }

  renderWeek() {
    const { week } = this.state
    return (
      <select
        className="form-control date-dropdown__week"
        name="week"
        value={week}
        onChange={this.handleChangeDate.bind(this, 'week')}
      >
        {this.renderOptions('week', WeeksOptions)}
      </select>
    )
  }

  renderMonth() {
    const { month } = this.state
    const monthOptions = Months.map((text, index) => [index, text])
    return (
      <select
        className="form-control date-dropdown__month"
        name="month"
        value={month}
        onChange={this.handleChangeDate.bind(this, 'month')}
      >
        {this.renderOptions('month', monthOptions)}
      </select>
    )
  }

  renderYear() {
    const { year } = this.state
    const yearOptions = FutureYears.map((year) => [year, year])
    return (
      <select
        className="form-control date-dropdown__year"
        name="year"
        value={year}
        onChange={this.handleChangeDate.bind(this, 'year')}
      >
        {this.renderOptions('year', yearOptions)}
      </select>
    )
  }

  render(state) {
    const { showWeek } = this.props

    return (
      <div className="date-dropdown">
        {showWeek ? this.renderWeek() : this.renderDay()} {this.renderMonth()}{' '}
        {this.renderYear()}
      </div>
    )
  }
}

const ThisYear = moment().year()
const FutureYears = _.times(10, (i) => ThisYear + i)
const Months = moment.months()
const WeeksOptions = [
  [1, t('Beginning')],
  [15, t('Middle')],
  [28, t('End')],
]
