import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { t } from 'src/lib/i18n-react'

const mineSymbol = (status) => {
  return {
    confirmed: <SC.SymbolConfirmedMine className="fa fa-check" />,
    pending_me: <SC.SymbolPendingMeMine className="fa fa-ellipsis-h" />,
    pending_partner: <SC.SymbolPendingMeMine className="fa fa-ellipsis-h" />,
    bookable: <SC.SymbolPendingMeMine className="fa fa-ellipsis-h" />,
    canceled: <SC.SymbolCanceledMine className="fa fa-times" />,
    past: <SC.Symbol className="fa fa-check" />,
  }[status]
}

const otherSymbol = (status) => {
  return {
    confirmed: <SC.SymbolConfirmedOther className="fa fa-check" />,
    pending_me: <SC.SymbolPendingMeOther className="fa fa-ellipsis-h" />,
    pending_partner: <SC.SymbolPendingMeOther className="fa fa-ellipsis-h" />,
    bookable: <SC.SymbolPendingMeOther className="fa fa-ellipsis-h" />,
    canceled: <SC.SymbolCanceledOther className="fa fa-times" />,
    past: <SC.Symbol className="fa fa-check" />,
  }[status]
}

const Icon = ({ status, isMine }) => {
  const tooltipMap = {
    confirmed: isMine ? t('Confirmed tutor') : t('Confirmed student'),
    pending_me: t('Pending your decision'),
    pending_partner: isMine
      ? t('Pending tutor confirmation')
      : t('Pending student confirmation'),
    bookable: t('Available to book'),
    canceled: t('Canceled'),
    past: t('Done'),
  }

  return (
    <SC.Icon data-toggle="tooltip" title={tooltipMap[status]}>
      {isMine ? mineSymbol(status) : otherSymbol(status)}
    </SC.Icon>
  )
}

const ICON_SIZE = '26px'

const SC = {}
SC.Icon = styled.span`
  position: relative;
`
SC.Symbol = styled.i`
  background: #d0d0d0;
  display: inline-block;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  font-size: 16px;
  text-align: center;
  color: white;
  border-radius: 100px;
  padding-top: 5px;
`
// Other
SC.SymbolConfirmedOther = styled(SC.Symbol)`
  background: ${(props) => props.theme.colors.blue};
`
SC.SymbolPendingMeOther = styled(SC.Symbol)`
  background: white;
  border: 2px solid ${(props) => props.theme.colors.blue};
  color: ${(props) => props.theme.colors.blue};
  padding-top: 4px;
`
SC.SymbolCanceledOther = styled(SC.Symbol)`
  background: ${(props) => props.theme.colors.red};
`
// Mine
SC.SymbolConfirmedMine = styled(SC.Symbol)`
  background: ${(props) => props.theme.colors.purples[0]};
`
SC.SymbolPendingMeMine = styled(SC.Symbol)`
  background: white;
  border: 2px solid ${(props) => props.theme.colors.purples[0]};
  color: ${(props) => props.theme.colors.purples[0]};
  padding-top: 4px;
`
SC.SymbolCanceledMine = styled(SC.Symbol)`
  background: ${(props) => props.theme.colors.purples[0]};
`

Icon.propTypes = {
  status: PropTypes.oneOf([
    'confirmed',
    'pending_me',
    'pending_partner',
    'canceled',
    'bookable',
    'past',
  ]).isRequired,
  isMine: PropTypes.bool.isRequired,
}

export default Icon
