import React from 'react'
import PropTypes from 'prop-types'

import { Text, Flex } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'

import PrivacyBee from './subcomponents/PrivacyBee.js'
import openBee from './privacy-bee-research-use@2x.png'
import semiPrivateBee from './privacy-bee-my-use@2x.png'
import privateBee from './privacy-bee-none@2x.png'

// for propTypes
const PRIVACY_VALUES = [
  'research_use_recording',
  'personal_use_recording',
  'no_recording',
]

const getPrivacyProps = (option) => {
  return {
    research_use_recording: {
      imgSrc: openBee,
      title: t('MY USE + RESEARCH'),
      description: t(`I give Chatterbug my consent to store my recorded video sessions for my use, for Chatterbug
          in cases of required support, and also for Chatterbug research purposes.`),
    },
    personal_use_recording: {
      imgSrc: semiPrivateBee,
      title: t('RECORD FOR MY USE'),
      description: t(`I give Chatterbug my consent to store my recorded video sessions
            for my use and for Chatterbug in cases of required support.`),
    },
    no_recording: {
      imgSrc: privateBee,
      title: t('DO NOT RECORD'),
      description: t('Chatterbug may not record my video at any time.'),
    },
  }[option]
}

const PrivacySelection = ({ onChange, value }) => {
  return (
    <Flex
      pt="md"
      px={['md', 'xxl']}
      pb="md"
      flexDirection="column"
      flex="0 0 auto"
    >
      <Text color="pink" fontSize="xxl" textAlign="center" mb={['lg', 'xl']}>
        {t('Privacy Settings')}
      </Text>

      <Text fontSize={['md', 'lg']} textAlign="center" mb="xxl">
        {t(`Chatterbug can record your video sessions and store them for you. We
        offer this so that you can see your progress as you improve and review
        past work for study purposes.`)}
      </Text>

      <Flex
        flexDirection="column"
        flex="0 0 auto"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          mt={[null, null, 'xxl']}
        >
          {PRIVACY_VALUES.map((option) => {
            return (
              <PrivacyBee
                aria-label={`privacy option: ${option}`}
                key={'privacy-bee-option' + option}
                m={['auto', null, 'md']}
                my="xxl"
                width={[0.8, 0.8, 1 / 3]}
                height={['auto', '320px']}
                selected={value === option}
                onClick={() => {
                  onChange(option)
                }}
                {...getPrivacyProps(option)}
              />
            )
          })}
        </Flex>
      </Flex>
      <Text color="cadetBlue" fontSize="sm" textAlign="center" mb="sm">
        {t('You can revert or change these privacy settings at any time.')}
      </Text>
    </Flex>
  )
}

PrivacySelection.propTypes = {
  value: PropTypes.oneOf(PRIVACY_VALUES).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PrivacySelection
