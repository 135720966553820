import { post } from 'src/lib/http'
import routes from 'src/lib/rails-routes/rails-routes'

export const translate = async ({ phrase, sessionId }) => {
  const { data } = await post(
    routes.chat_translate(),
    { phrase, id: sessionId },
    'json'
  )
  if (data && data.translation) {
    return data.translation
  }
}
