import React from 'react'

import { Button } from '@chatterbug/aaron'
import { t } from 'src/lib/i18n-react'
import routes from 'src/lib/rails-routes/rails-routes'
import ContentCard from 'src/components/ContentCard/ContentCard'
import ContentTitle from 'src/components/ContentTitle/ContentTitle'

const PaymentMethodWarning = () => {
  return (
    <ContentCard>
      <ContentTitle title="Permission Required" />
      <div>
        <p>
          {t(`Chatterbug does not have permission to record your sessions. At this
          time we can only schedule tutors we have permission to record.`)}
        </p>
        <Button
          fill="outline"
          onClick={() => {
            window.location = routes.account_privacy()
          }}
          disableWith="..."
        >
          {t('Privacy Settings')}
        </Button>
      </div>
    </ContentCard>
  )
}

export default PaymentMethodWarning
